import { Link, useOutletContext } from "react-router-dom";
import blog1 from "../../../Assets/blog1.png";
import blogimg1 from "../../../Assets/blogimg1.png";
import blogimg2 from "../../../Assets/blogimg2.png";
import blogimg3 from "../../../Assets/blogimg3.png";
import { useScrollAnimation } from "../../../utils/Utils";

const Blog = () => {
  const { linkClicked } = useOutletContext();
  useScrollAnimation();
  return (
    <div>
      <section className="py-10">
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-5 xl:mx-40 mx-3 md:mx-10">
          <div class="w-full max-w-sm dark:bg-gray-800 dark:border-gray-700 animate-up">
            <img class="p-3 rounded-t-lg" src={blogimg1} alt="blogimg" />
              <Link to="explore_blog" onClick={() => linkClicked()}>
              <h5 class="text-xl font-normal text-bluecolor tracking-tight ps-3 text-gray-900 dark:text-white hover:underline">
              Apple Watch Series 7 GPS, Aluminium Case, Starlight Sport
            </h5>
              </Link>
        
          </div>
          <div class="w-full max-w-sm dark:bg-gray-800 dark:border-gray-700 animate-up">
            <img class="p-3 rounded-t-lg" src={blogimg2} alt="blogimg" />

            <Link to="explore_blog" onClick={() => linkClicked()}>
              <h5 class="text-xl font-normal text-bluecolor tracking-tight ps-3 text-gray-900 dark:text-white hover:underline">
              Apple Watch Series 7 GPS, Aluminium Case, Starlight Sport
            </h5>
              </Link>
          </div>
          <div class="w-full max-w-sm dark:bg-gray-800 dark:border-gray-700 animate-up">
            <img class="p-3 rounded-t-lg" src={blogimg3} alt="blogimg" />

            <Link to="explore_blog" onClick={() => linkClicked()}>
              <h5 class="text-xl font-normal text-bluecolor tracking-tight ps-3 text-gray-900 dark:text-white hover:underline">
              Apple Watch Series 7 GPS, Aluminium Case, Starlight Sport
            </h5>
              </Link>
          </div>
          <div class="w-full max-w-sm dark:bg-gray-800 dark:border-gray-700 animate-up">
            <img class="p-3 rounded-t-lg" src={blogimg3} alt="blogimg" />

            <Link to="explore_blog" onClick={() => linkClicked()}>
              <h5 class="text-xl font-normal text-bluecolor tracking-tight ps-3 text-gray-900 dark:text-white hover:underline">
              Apple Watch Series 7 GPS, Aluminium Case, Starlight Sport
            </h5>
              </Link>
          </div>
          <div class="w-full max-w-sm dark:bg-gray-800 dark:border-gray-700 animate-up">
            <img class="p-3 rounded-t-lg" src={blogimg1} alt="blogimg" />

            <Link to="explore_blog" onClick={() => linkClicked()}>
              <h5 class="text-xl font-normal text-bluecolor tracking-tight ps-3 text-gray-900 dark:text-white hover:underline">
              Apple Watch Series 7 GPS, Aluminium Case, Starlight Sport
            </h5>
              </Link>
          </div>
          <div class="w-full max-w-sm dark:bg-gray-800 dark:border-gray-700 animate-up">
            <img class="p-3 rounded-t-lg" src={blogimg2} alt="blogimg" />

            <Link to="explore_blog" onClick={() => linkClicked()}>
              <h5 class="text-xl font-normal text-bluecolor tracking-tight ps-3 text-gray-900 dark:text-white hover:underline">
              Apple Watch Series 7 GPS, Aluminium Case, Starlight Sport
            </h5>
              </Link>
          </div>
        </div>
      </section>

      <section className="py-10 bg-[#efefef]">
        <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-5 xl:mx-40 mx-3 md:mx-10">
          <div class=" col-span-1 flex flex-col items-start md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 border-b border-b-[#d4d4d8] pb-7 animate-up">
            <img
              class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
              src={blog1}
              alt="Michel Petersen"
            />
            <div class="flex flex-col justify-between px-4 leading-normal">
              <h5 class="mb-2 text-base  tracking-tight text-gray-900 dark:text-white">
                Michael Petersen
              </h5>
              <h6 className="text-bluecolor text-md  mb-2">
                Adopting AI in insurance: A strategic framework
              </h6>
              <p class="mb-3 font-normal text-[16px] text-gray-700 dark:text-gray-400 leading-[150%] ">
                <span className="text-md"> July 2019 </span>
                <span className="blog_Content">
                  Organizations that take a wait-and-see approach risk losing
                  the first-mover advantage and seeing competitors reap the
                  benefits promised by the AI revolution. How can insurance
                  organizations balance the costs and benefits of AI adoption?
                </span>
              </p>
            </div>
          </div>

          <div class=" col-span-1 flex flex-col items-start    md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 border-b border-b-[#d4d4d8] pb-7 animate-up">
            <img
              class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
              src={blog1}
              alt="Christina Fung"
            />
            <div class="flex flex-col justify-between px-4 leading-normal">
              <h5 class="mb-2 text-base  tracking-tight text-gray-900 dark:text-white">
                Christina Fung
              </h5>
              <h6 className="text-bluecolor text-md  mb-2">
                Adopting AI in insurance: A strategic framework
              </h6>
              <p class="mb-3 font-normal text-[16px] text-gray-700 dark:text-gray-400 leading-[150%] ">
                <span className="text-md"> July 2019 </span>
                <span className="blog_Content">
                  We have real opportunities that can unite us toward a common
                  goal of making AI a positive force for people's lives and
                  work. AI is here to stay, and it is up to us to intentionally
                  envision and shape a...
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
