// import React from 'react';
// import Infor from '../../../Assets/InforPage/Infor.png'
// import upgrades from '../../../Assets/InforPage/process-improvement.png'
// import migration from '../../../Assets/InforPage/exchange.png'
// import coutomization from '../../../Assets/InforPage/customization.png'
// import services from '../../../Assets/InforPage/customer-service.png'
// import consulting from '../../../Assets/InforPage/consultant.png'
// import training from '../../../Assets/InforPage/analysis.png'

// const InforCards = () => {
//   const features = [
//     {
//       icon: upgrades,
//       title: "Upgrades",
//       description: "Continuous investment in business application is necessary to maintain best performance and a competitive edge. The lack of time, budget or resources to upgrade and maintain Infor systems can be an obstacle to the continuous growth of your business."
//     },
//     {
//       icon: migration,
//       title: "Migrations",
//       description: "Data migration is one of the crucial factors to make sure the success of upgrade or implementation. Our Infor experts will execute and guide in data migration to make sure long-term success."
//     },
//     {
//       icon: coutomization,
//       title: "Customizations",
//       description: "Customize the Infor system that suits the enterprise processes and delivers the critical data in the way which makes sense for businesses. Customizations need not be a painful process. We will do customizations as per the business objectives and train the candidate how to use the new functions within the system."
//     },
//     {
//       icon: consulting,
//       title: "Management Consulting",
//       description: "Our Infor team expertise in Infor strategy development and Business processes which allows Kapil Technologies to bring transparency and foresight to the business-technology relationship. Relevant Solution has expertise in Infor CloudSuite solutions with extensive and successful large global program delivery experience."
//     },
//     {
//       icon: services,
//       title: "Managed Services",
//       description: "Our Infor experts are capable of solving technical errors, delivering insights & answers to functional queries around the clock (24/7). Enterprise Applications cannot communicate with one another to share business rules or data."
//     },
//     {
//       icon: training,
//       title: "Training",
//       description: "For any successful Enterprise Application implementation, well-trained internal IT and end-users are extremely important. Kapil Technologies' training services are delivered by Infor Application Experts."
//     }
//   ];

//   return (
//     <>
//       <div className='m-auto text-center'>
//         <h2 style={{ color: "#141485" }} className="text-[linear-gradient(#5b8da5,#718eb9)] font-sans font-bold text-xl flex justify-center items-center">
//                  <span> <img src={Infor} alt="inforLogo" className='h-12 text-center m-auto'/></span>
//                   <span className='text-red text-lg p-2 font-bold'>Services</span>
//         </h2>
//         {/* <h1 className='text-black font-bold text-3xl m-2'>
//           Outperform with Finsta's banking platform
//         </h1> */}
//         <div style={{ background: "#FF0000" }} className="w-24 h-1 mx-auto rounded-full mb-6"></div>
//       </div>

//       <div className="max-w-6xl mx-auto px-4 py-12">
//         <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
//           {features.map((feature, index) => (
//             <div
//               key={index}
//               className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center hover:shadow-xl transition-shadow duration-300 transform hover:scale-105"
//             >
//               <div className="mb-6">
//                 <img src={feature.icon} alt="icon" className='h-20' />
//               </div>
//               <h3
//                 className={`text-2xl font-bold mb-4 text-gray-900 ${
//                   index % 2 === 0 ? "text-blue-500" : "text-green-500"
//                 }`}
//               >
//                 {feature.title}
//               </h3>
//               <p className="text-gray-600 text-lg text-justify">
//                 {feature.description}
//               </p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// };

// export default InforCards;

import React from 'react';
import Infor from '../../../Assets/InforPage/Infor.png';
import upgrades from '../../../Assets/InforPage/process-improvement.png';
import migration from '../../../Assets/InforPage/exchange.png';
import coutomization from '../../../Assets/InforPage/customization.png';
import services from '../../../Assets/InforPage/customer-service.png';
import consulting from '../../../Assets/InforPage/consultant.png';
import training from '../../../Assets/InforPage/analysis.png';

const InforCards = () => {
  const features = [
    {
      icon: upgrades,
      title: "Upgrades",
      description: "Continuous investment in business applications is necessary to maintain best performance and a competitive edge. The lack of time, budget, or resources to upgrade and maintain Infor systems can be an obstacle to continuous business growth."
    },
    {
      icon: migration,
      title: "Migrations",
      description: "Data migration is crucial for the success of any upgrade or implementation. Our Infor experts will guide you through the process to ensure long-term success."
    },
    {
      icon: coutomization,
      title: "Customizations",
      description: "Customize the Infor system to suit your enterprise processes and deliver critical data in a way that makes sense for your business. Customizations are not a painful process when done right."
    },
    {
      icon: consulting,
      title: "Management Consulting",
      description: "Our team’s expertise in Infor strategy and business processes allows us to bring transparency and foresight to your business-technology relationship, ensuring a successful long-term strategy."
    },
    {
      icon: services,
      title: "Managed Services",
      description: "Our Infor experts are available 24/7 to solve technical issues and provide functional insights. We ensure seamless integration and optimization across all your enterprise applications."
    },
    {
      icon: training,
      title: "Training",
      description: "We offer comprehensive training for your internal IT teams and end-users to ensure smooth adoption and successful implementation of your enterprise applications."
    }
  ];

  return (
    <>
      {/* Header Section */}
      <div className='m-auto text-center'>
        <h2 className="text-[linear-gradient(#5b8da5,#718eb9)] font-sans font-bold text-xl flex justify-center items-center">
          <img src={Infor} alt="Infor Logo" className='h-12' />
          <span className="text-red-500 text-lg font-bold ml-2">Services</span>
        </h2>
        <div style={{ background: "#FF0000" }} className="w-24 h-1 mx-auto rounded-full mb-6"></div>
      </div>

      {/* Cards Section */}
      <div className="max-w-6xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center hover:shadow-2xl transition-all duration-300 transform hover:scale-105"
            >
              <div className="mb-6">
                <img src={feature.icon} alt={feature.title} className='h-20 mx-auto' />
              </div>
              <h3
                className={`text-2xl font-bold mb-4 ${
                  index % 2 === 0 ? "text-blue-600" : "text-green-600"
                }`}
              >
                {feature.title}
              </h3>
              <p className="text-gray-700 text-lg text-justify leading-relaxed">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default InforCards;
