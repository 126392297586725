import React from 'react'
import HeaderSectionReusableComponent from '../../component/ReusablaeComponent/HeaderSectionReusableComponent'
import Image_ContentReusableComponent from '../../component/ReusablaeComponent/Image_ContentReusableComponent'
import PointsWithHeading from '../../component/ReusablaeComponent/PointsWithHeading';
import IT_Transformations from '../../../Assets/IT_Transformation.png';
import IT_approch from '../../../Assets/IT_approch.png';
import Why_IT from '../../../Assets/Why_IT.png';
import Cost_optimization from '../../../Assets/Cost_optimization.png';
import Benefits_IT from '../../../Assets/Benifits_IT.png'

const ITTransformation = () => {
    const IT_Transformation = [
        {
          strongText: "Industry Expertise:",
          text: "Our team has extensive experience across various industries, allowing us to deliver tailor-made IT solutions that fit your unique needs.",
        },
        {
          strongText: "End-to-End Solutions:",
          text: "From consultation and planning to implementation and support, we manage the entire IT transformation process.",
        },
        {
          strongText: "Innovation-Driven: ",
          text: "We incorporate the latest technologies like AI, machine learning, and IoT to future-proof your IT infrastructure.",
        },
      ];
      const Benefits_IT_Transformation = [
        {
          strongText: "Increased Agility:",
          text: "Quickly adapt to market changes with flexible and scalable IT solutions.",
        },
        {
          strongText: "Enhanced Security:",
          text: "Strengthen your organization’s cybersecurity posture to protect against evolving threats.",
        },
        {
          strongText: "Cost Optimization: ",
          text: "Reduce operational costs through process automation and cloud services.",
        },
        {
            strongText: "Improved User Experience: ",
            text: " Empower your teams with modern tools that enhance collaboration and productivity.",
          },
      ];
      const Process = [
        {
          strongText: "Assessment:",
          text: " In-depth analysis of your current IT landscape.",
        },
        {
          strongText: "Strategic Planning:",
          text: "Tailored roadmap to meet business goals.",
        },
        {
          strongText: "Implementation:",
          text: "Seamless integration of new technologies.",
        },
        {
            strongText: "Ongoing Support:",
            text: "Continuous monitoring and optimization.",
          },
      ];
  return (
    <div>
            <HeaderSectionReusableComponent
        mainHeading={"Introduction to IT Transformation"}
        paragragh={
          <>
            Supply Chain Management (SCM) integrates the flow of goods,
            information, and finances as they move from supplier to
            manufacturer, distributor, retailer, and end consumer. Efficient SCM
            reduces costs, enhances speed, and improves product quality.
          </>
        }
        imgScr={IT_Transformations}
        altText='IT_Transformation'
      />
        <Image_ContentReusableComponent
        bgColor="#fff"
        heading={"Our Approach to IT Transformation"}
        paragraph={
          <>
         Our IT transformation services focus on aligning technology with your business goals. We 
assess your current IT landscape, identify areas of improvement, and implement strategies 
that support scalability, security, and efficiency. From cloud migration to modern software 
solutions, we ensure that your IT infrastructure is future-ready.

          </>
        }
        imgsrc={IT_approch}
        altText='IT_approch'
      />
      <PointsWithHeading
        title="Why Choose Us for IT Transformation?"
        features={IT_Transformation}
        reverse={false}
        imageSrc={Why_IT}
        altText="Why_IT"
      />
        <PointsWithHeading
        title="Benefits of IT Transformation"
        bgColor='#fff'
        
        features={Benefits_IT_Transformation}
        imageSrc={Cost_optimization}
        altText="Cost_optimization"
      />
       <PointsWithHeading
        title="Why Choose Us for IT Transformation?"
        features={Process}
        reverse={false}
        imageSrc={Benefits_IT}
        altText="Benefits_IT"
      />
    </div>
  )
}

export default ITTransformation