import React, { useEffect, useState } from "react";
import careercarousel3 from "../../../Assets/careerscarousels3.png";
import careercarousel2 from "../../../Assets/careercarousel2.png";
import careercarousel1 from "../../../Assets/careercarousel1.png";
import diversity from "../../../Assets/diversity.png";
import careerContact from '../../../Assets/careerContact.jpg'
import careerContactSmall from '../../../Assets/careerBannerSmall.jpg'
import { useScrollAnimation } from "../../../utils/Utils";
import { Link } from "react-router-dom";
const Career = () => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const totalSlides = 3; // Total number of slides in the carousel

  // Function to handle changing slides
  const handleSlideChange = (slideNumber) => {
    setCurrentSlide(slideNumber);
  };

  // Auto-scroll functionality using useEffect
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === totalSlides ? 1 : prevSlide + 1
      );
    }, 5000); // Change slides every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [totalSlides]);

  useScrollAnimation();

  const careerlist = [
    {
      position: "Software Developer",

      description:
        "Install, configursdgfdge, and upgrade database server software. Implement and maintain database design and security. Perform tuning and performancedgs monitoring. Conduct data profiling and data mapping. Write database queries and design reports.",
      requirements:
        " Install, configure, andsdgsdgdsgsd upgrade database server software. Implement and maintain database design and security. Perform tuning and performancsdgfsgfe monitoring. Conduct data profiling and data mapping. Write database queries and design reports.",
    },
    {
      position: "Software Developer",

      description:
        "Install, configursdgfdge, and upgrade database server software. Implement and maintain database design and security. Perform tuning and performancedgs monitoring. Conduct data profiling and data mapping. Write database queries and design reports.",
      requirements:
        " Install, configure, andsdgsdgdsgsd upgrade database server software. Implement and maintain database design and security. Perform tuning and performancsdgfsgfe monitoring. Conduct data profiling and data mapping. Write database queries and design reports.",
    },
    {
      position: "Software Developer",

      description:
        "Install, configursdgfdge, and upgrade database server software. Implement and maintain database design and security. Perform tuning and performancedgs monitoring. Conduct data profiling and data mapping. Write database queries and design reports.",
      requirements:
        " Install, configure, andsdgsdgdsgsd upgrade database server software. Implement and maintain database design and security. Perform tuning and performancsdgfsgfe monitoring. Conduct data profiling and data mapping. Write database queries and design reports.",
    },
    {
      position: "Software Developer",

      description:
        "Install, configursdgfdge, and upgrade database server software. Implement and maintain database design and security. Perform tuning and performancedgs monitoring. Conduct data profiling and data mapping. Write database queries and design reports.",
      requirements:
        " Install, configure, andsdgsdgdsgsd upgrade database server software. Implement and maintain database design and security. Perform tuning and performancsdgfsgfe monitoring. Conduct data profiling and data mapping. Write database queries and design reports.",
    },
    {
      position: "Software Developer",

      description:
        "Install, configursdgfdge, and upgrade database server software. Implement and maintain database design and security. Perform tuning and performancedgs monitoring. Conduct data profiling and data mapping. Write database queries and design reports.",
      requirements:
        " Install, configure, andsdgsdgdsgsd upgrade database server software. Implement and maintain database design and security. Perform tuning and performancsdgfsgfe monitoring. Conduct data profiling and data mapping. Write database queries and design reports.",
    },
  ];

  return (
    <div className="bg-[#fff]">
      <section>
      {/* <div className="banner-area slider-height">
      <img className="w-100 slider-desk" src={careercarousel3} alt="Kapil Technologies is ranked among the Top 100 Great Places to Work!"/>
		<img className="w-100 slider-mob" src="https://www.Kapil Technologies.com/sites/default/files/gptw-rank-mobile-banner.jpg" alt="Kapil Technologies is ranked among the Top 100 Great Places to Work!"/>
		
		<div className="para">
		<h1>Kapil Technologies is ranked among the<br/><span class="red-light-text locator-font-bold">Top 100 Great Places to Work!</span></h1>
		</div>
    </div> */}



   
<div className="career_main relative">
  {/* Desktop Image */}
  <img
    className="w-full hidden lg:block"
    src={careerContact}
    alt="Kapil Technologies is ranked among the Top 100 Great Places to Work!"
  />
  
  {/* Mobile Image */}
  <img
    className="w-full block lg:hidden"
    src={careerContactSmall}
    alt="Kapil Technologies is ranked among the Top 100 Great Places to Work!"
  />
  
  {/* Content */}
  <h1 className="lg:ps-12 career_banner ">
    Kapil Technologies is in the <br/> Top 100 Best Workplaces!
  </h1>
</div>


      </section>
      <section className="py-10">
        <h2 className="text-center pb-10 animate-up">Meet the Challengers</h2>
        <div
          id="default-carousel"
          className="relative flex justify-center"
          data-carousel="static"
        >
          {/* Slide 1 */}
          <div
            className={`duration-700 ease-in-out inset-0 transition-transform transform xl:mx-36 mx-3 md:mx-10 ${
              currentSlide === 1 ? "translate-x-0" : "translate-x-full"
            }`}
            style={{ display: currentSlide === 1 ? "block" : "none" }}
          >
            <img
              src={careercarousel3}
              alt="Slide 1"
              className="w-full h-auto object-cover"
            />
          </div>

          {/* Slide 2 */}
          <div
            className={`duration-700 ease-in-out inset-0 transition-transform transform xl:mx-36 mx-3 md:mx-10 ${
              currentSlide === 2 ? "translate-x-0" : "translate-x-full"
            }`}
            style={{ display: currentSlide === 2 ? "block" : "none" }}
          >
            <img
              src={careercarousel1}
              alt="Slide 2"
              className="w-full h-auto object-cover"
            />
          </div>

          {/* Slide 3 */}
          <div
            className={`duration-700 ease-in-out inset-0 transition-transform transform xl:mx-36 mx-3 md:mx-10 ${
              currentSlide === 3 ? "translate-x-0" : "translate-x-full"
            }`}
            style={{ display: currentSlide === 3 ? "block" : "none" }}
          >
            <img
              src={careercarousel2}
              alt="Slide 3"
              className="w-full h-auto object-cover"
            />
          </div>

          {/* Navigation Buttons */}
          <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
            {[1, 2, 3].map((slide) => (
              <button
                key={slide}
                type="button"
                className={`w-3 h-3 rounded-full ${
                  currentSlide === slide ? "bg-white" : "bg-black"
                }`}
                aria-current={currentSlide === slide}
                aria-label={`Slide ${slide}`}
                onClick={() => handleSlideChange(slide)}
              ></button>
            ))}
          </div>
        </div>
      </section>

      <section className="py-10 bg-[#f5f5f5]">
  <h2 className="text-center text-3xl pb-6 animate-up">
    Kapil Technologies, Inc. (Edison, NJ & client sites throughout the US).
  </h2>
  <div className="xl:mx-40 mx-3 md:mx-10 animate-up">
    <h3 className="text-2xl pb-2 font-semibold">Multiple positions</h3>
    <hr className="border-[#cbd5e1] pb-6" />
  </div>
  {careerlist.map((items) => (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 xl:mx-40 mx-3 md:mx-10">
      <div className="col-span-1 animate-up mb-6 flex flex-col justify-between">
        <div>
          <h4 className="font-normal text-xl pb-1">{items.position}</h4>
          <p>
            <span className="text-bluecolor text-lg">Job Duties: </span>
            {items.description}
          </p>
        </div>
        <hr className="border-[#cbd5e1] " />
      </div>
      <div className="col-span-1 animate-up mb-6 flex flex-col justify-between">
        <div>
          <p>
            <span className="text-bluecolor text-lg">Requirements: </span>
            {items.requirements}
          </p>
        </div>
        <div>
          <Link to="position_description">
            <button className="border border-[#fff] rounded-md px-3 py-2 mb-4 text-[#fff] bg-bluecolor hover:bg-darkblue">
              I'm Interested
            </button>
          </Link>
        </div>
        <hr className="border-[#cbd5e1] " />
      </div>
    </div>
  ))}
</section>

      <section className=" py-10">
        <div className="grid grid-col-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10 xl:mx-40 mx-3 md:mx-10">
          <div>
            <h3 className="text-black pb-7 animate-up">
              Harmonizing Power of AI & Human Centricity
            </h3>
            <div class="video-container">
              <iframe
                width="520"
                height="315"
                src="https://www.youtube.com/embed/UMPXiH-eBXc?si=zvkkks-DHldNKpkB"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div>
            <h3 className="text-black pb-7 animate-up">
              Career Transformation – Neha Aggarwal
            </h3>
            <div class="video-container">
              <iframe
                width="520"
                height="315"
                src="https://www.youtube.com/embed/XZyEjhlJZgo?si=IEZpCZrN8gzXMENl"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-darkblue py-10">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-7 xl:mx-40 mx-3 md:mx-10">
          {/* Card 1 */}
          <div className="flex flex-col justify-between max-w-sm bg-[#092f4f] rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className="px-5 pt-5 flex-grow">
              <h5 className="mb-2 text-xl font-bold tracking-tight text-[#ffd626] dark:text-white animate-up">
                Diversity, Equity and Inclusion Initiative - Verve
              </h5>
              <p className="mb-3 font-normal text-white dark:text-gray-400 animate-up">
                In an endeavor to become a workplace of choice among people, we
                are striving to create an atmosphere where multiple voices are
                heard, and their opinions are valued and considered..
              </p>
            </div>
          
              <img
                className="rounded-b-lg w-full h-72 object-cover"
                src={diversity}
                alt="diversity"
              />
           
          </div>

          {/* Card 2 */}
          <div className="flex flex-col justify-between max-w-sm bg-[#092f4f] rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className="px-5 pt-5 flex-grow">
              <h5 className="mb-2 text-xl font-bold tracking-tight text-[#ffd626] dark:text-white animate-up">
                Balancing the Gender Mix
              </h5>
              <p className="mb-3 font-normal text-white dark:text-gray-400 animate-up">
                Referral Recruitment Drive – Exclusively for women to upskill or
                restart their career!
              </p>
            </div>
     
              <img
                className="rounded-b-lg w-full h-72 object-cover"
                src={diversity}
                alt="diversity"
              />
        
          </div>

          {/* Card 3 */}
          <div className="flex flex-col justify-between max-w-sm bg-[#092f4f] rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className="px-5 pt-5 flex-grow">
              <h5 className="mb-2 text-xl font-bold tracking-tight text-[#ffd626] dark:text-white animate-up">
                Kapil Technologies Recognized as India’s Most Admired & Valuable Power
                Brand Company 2020
              </h5>
              <p className="mb-3 font-normal text-white dark:text-gray-400 animate-up">
                The company was awarded at the India Leadership Conclave 2020
                for having shown remarkable resilience and achievements in a
                very tough and hostile environment.
              </p>
            </div>
           
              <img
                className="rounded-b-lg w-full h-72 object-cover"
                src={diversity}
                alt="diversity"
              />
            
          </div>
        </div>
      </section>
      <section className="py-10 bg-[#f5f5f5]">
        <div className="grid grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-7 xl:mx-40 mx-3 md:mx-10 ">
          <div className="col-span-2 px-4 animate-up">
            <h2 className="pb-5">Equal Opportunity Employer</h2>
            <p>
              As an Equal Opportunity Employer, Kapil Technologies intends to have a
              workforce reflective of the community (gender, cultural
              backgrounds, PwD, veterans) While we consciously strive for a
              gender-balanced hiring from campuses, through our capABLE program
              we also invite persons with disability to connect with us for
              career opportunities. Our Campus is designed to provide facilities
              which are disability friendly and a career canvas which aims to
              provide equal opportunities to everyone.
            </p>
            <p>
              Through our exCITE program, we engage with business schools to
              hire veterans who bring in domain experiences to enrich
              Kapil Technologies’s capabilities.
            </p>
            <p>Why hire for Diversity?</p>
            <ul>
              <li>1. Bigger talent pool to draw from</li>
              <li>2. Increased work ethic/dedication</li>
              <li>
                3. Positively impacts the retention of existing employees and
                boosts employee morale
              </li>
            </ul>
            <button className="border border-[#fff] rounded-md px-3 py-2 mt-5 text-[#fff] bg-bluecolor hover:bg-darkblue">
              Read our policy documents
            </button>
          </div>
          <div className="col-span-1 animate-up">
            <h2 className="pb-5">Great People To Work With</h2>
            <p>
              Our people are our biggest assets and we believe in leaving no
              stone unturned in assuring their wellness at office, as well as at
              home. Our meritocratic culture and processes of regular and timely
              feedback is designed to ensure a warm and supportive environment
              for our people to work in. We encourage our people to volunteer
              actively for cultural events, celebrations, community service
              initiatives and fun at work. This allows them to become better
              employees, better team workers and even better human beings.
            </p>
          </div>
        </div>
      </section>

      <section className="py-10 xl:mx-60 mx-3 md:mx-10">
        <figure className="glassdoor_career flex items-center justify-center lg:justify-start p-4 lg:p-10 bg-cover bg-center bg-no-repeat rounded-lg shadow-md">
          <div className="text-center lg:text-left max-w-md mx-auto lg:mx-0">
            <p className="text-lg text-[#4d4d4f] mb-4 animate-up">
              Making Societies More Productive By Helping Our Customers Run
              Their Businesses.
            </p>
            <h2 className="pb-4 text-black text-2xl animate-up">
              We are on Glassdoor
            </h2>
            <button className="rounded-md px-4 py-2 text-white bg-bluecolor hover:bg-darkblue animate-up">
              Know more
            </button>
          </div>
        </figure>
      </section>

     
    </div>
  );
};

export default Career;
