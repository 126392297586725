

// import React from "react";
// import aws from "../../../Assets/aws.svg";
// import actifio from "../../../Assets/actifio.svg";
// import InforAlliance from "../../../Assets/infor-alliance-partner-black.png";
// import { Link } from "react-router-dom";
// import { useScrollAnimation } from "../../../utils/Utils";
// import Finsta from "../../../Assets/Partner_Finsta_Logo.png";
// import Hexabuild from "../../../Assets/Partner_hexa-build.png";
// import IFCA from "../../../Assets/Partner_IFCA.png";
// import QRA from "../../../Assets/Patners_QRA.jpeg";
// import Mpdv from "../../../Assets/Partners_Mpdv.jpeg";

// const Partner = () => {
//   useScrollAnimation();
//   return (
//     <div>
//       <section className="py-10">
//         <div className="w-full max-w-4xl mx-auto border">
//           <img src={InforAlliance} alt="Infor Alliance" className="w-full h-auto" />
//         </div>
//         <p className="xl:mx-60 lg:mx-28 mx-3 md:mx-16 py-8 animate-up text-center">
//           As an organization, we regularly invest in global partnerships that support the co-development of solutions that enhance our services. Our Advise-to-Execute philosophy is the bedrock of our innovative capabilities and through collaborative efforts with our partners, we help customers accelerate their digital and cloud transformation journeys.
//         </p>
//       </section>

//       <section className="py-10 bg-[#f5f5f5]">
//         <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-7 xl:mx-52 mx-3 md:mx-10">
//           <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md flex items-center justify-center p-4">
//             <img src={Finsta} alt="Finsta" className="h-[5rem] w-[10rem]" />
//           </div>
//           <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md flex items-center justify-center p-4">
//             <img src={Hexabuild} alt="Hexabuild" className="h-full w-auto" />
//           </div>
//           <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md flex items-center justify-center p-4">
//             <img src={IFCA} alt="IFCA" className="h-full w-auto" />
//           </div>
//           <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md flex items-center justify-center p-4">
//             <img src={QRA} alt="QRA" className="h-full w-auto" />
//           </div>
//           <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md flex items-center justify-center p-4">
//             <img src={Mpdv} alt="Mpdv" className="h-full w-auto" />
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Partner;


import React from "react";
import InforAlliance from "../../../Assets/infor-alliance-partner-black.png";
import Finsta from "../../../Assets/Partner_Finsta_Logo.png";
import Hexabuild from "../../../Assets/Partner_hexa-build.png";
import IFCA from "../../../Assets/Partner_IFCA.png";
import QRA from "../../../Assets/Qrapng.png";
import Mpdv from "../../../Assets/Mpdvpng.png";

const Partner = () => {
  return (
    <div>
      <section className="py-10">
        <div className="w-full max-w-4xl mx-auto">
          <img src={InforAlliance} alt="Infor Alliance" className="w-96 h-52 m-auto  object-contain"  />
        </div>
        <p className="xl:mx-60 lg:mx-28 mx-3 md:mx-16 py-8 text-center">
         We consistently invest in international collaborations that foster the joint development of solutions aimed at enhancing our service offerings. Our Advise-to-Execute approach serves as the foundation of our innovative capabilities. Through these collaborative initiatives with our partners, we empower clients to expedite their journeys toward digital and cloud transformation.
        </p>
      </section>

      <section className="py-10 bg-[#f5f5f5]">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-7 xl:mx-52 mx-3 md:mx-10">
          <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md flex items-center justify-center p-4">
            <img src={Finsta} alt="Finsta" className="h-[50px] w-auto sm:h-[70px] lg:h-[100px]" />
          </div>
          <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md flex items-center justify-center p-4">
            <img src={Hexabuild} alt="Hexabuild" className="h-[50px] w-auto sm:h-[70px] lg:h-[100px]" />
          </div>
          <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md flex items-center justify-center p-4">
            <img src={IFCA} alt="IFCA" className="h-[50px] w-auto sm:h-[70px] lg:h-[100px]" />
          </div>
          <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md flex items-center justify-center p-4">
            <img src={QRA} alt="QRA" className="h-[50px] w-auto sm:h-[70px] lg:h-[100px]" />
          </div>
          <div className="col-span-1 bg-[#f9f9f9] shadow-sm rounded-md flex items-center justify-center p-4">
            <img src={Mpdv} alt="Mpdv" className="h-[50px] w-auto sm:h-[70px] lg:h-[100px]" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Partner;