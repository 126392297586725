import React from "react";
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import PointsComponent from "../../component/ReusablaeComponent/PointsComponent";
import PointsWithHeading from "../../component/ReusablaeComponent/PointsWithHeading";
import HeadingParagraphSection from "../../component/ReusablaeComponent/HeadingParagraphSection";
import supplychaimanagement from '../../../Assets/SuuplyChainManagement.png';
import SCMSolutions from '../../../Assets/SCMSolutions.png';
import SCMChoose from '../../../Assets/SCMChoose.png';
import BenifitsSCM from '../../../Assets/BenifitsSCM.png'

const SupplyChainManagement = () => {
    const features = [
        {
          strongText: "Cost Efficiency:",
          text: "Optimize resource use, reduce waste, and improve profitability.",
        },
        {
          strongText: "Improved Customer Satisfaction:",
          text: " Faster delivery times, real-time tracking, and high product availability.",
        },
        {
          strongText: "Risk Mitigation:",
          text: "Proactive risk management ensures smooth operations, even in unexpected scenarios.",
        },
        {
          strongText: "Greater Flexibility:",
          text: "Adapt quickly to changes in demand and market conditions.",
        },
       
      ];
  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading={"Overview of Supply Chain Management"}
        paragragh={
          <>
            Supply Chain Management (SCM) integrates the flow of goods,
            information, and finances as they move from supplier to
            manufacturer, distributor, retailer, and end consumer. Efficient SCM
            reduces costs, enhances speed, and improves product quality.
          </>
        }
        imgScr={supplychaimanagement}
        altText={supplychaimanagement}
      />
         <PointsComponent
        title="Our SCM Solutions"
          description="We offer comprehensive SCM solutions tailored to your business needs, including:"
        listItems={[
          "Demand forecasting and inventory management",
          "Supplier relationship management",
          "Transportation and logistics optimization",
          "Warehouse and order fulfillment management",
         
        ]}
        imageSrc={SCMSolutions}
        altText="SCMSolutions"
      />
       <PointsWithHeading
        title="Benefits of Implementing SCM"
        reverse={false}
        features={features}
        imageSrc={BenifitsSCM}
        altText="BenifitsSCM"
      />
      
      <HeadingParagraphSection
      bgColor='#fff'
        heading={
          "Sustainability in Supply Chain"
        }
        paragraph={
          <>
           We are committed to creating environmentally responsible supply chains by 
optimizing operations to reduce carbon emissions, minimizing waste, and 
promoting the use of sustainable materials. Our solutions enable businesses to 
implement eco-friendly practices across sourcing, manufacturing, and logistics, 
ensuring a greener and more efficient supply chain. We help clients meet their 
sustainability goals while maintaining operational efficiency and cost-effectiveness.
          </>
        }
      />
      <PointsComponent
        title="Why Choose Us?"
          bgColor="#efefef"
        listItems={[
          "Proven track record of delivering results",
          "Cutting-edge technology integration",
          "Experienced team of supply chain experts",
          "End-to-end support from planning to execution",
          
        ]}
        imageSrc={SCMChoose}
        altText="SCMChoose"
      />
    </div>
  );
};

export default SupplyChainManagement;
