import smaple2nd_img from "../../../../Assets/Sameple2nd_img.png";
import sample3rd_img from "../../../../Assets/Sample3rd_img.png";
import { useScrollAnimation } from "../../../../utils/Utils";
import ifca_digitalization from "../../../../Assets/IFCA_digitalization (2).png";
import ifca from "../../../../Assets/IFCA.png";
import ifca_einvoice from "../../../../Assets/ifca_einvoice.png";
import ifca_futurework from "../../../../Assets/ifca_futurework.png";
import Image_ContentReusableComponent from "../../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeaderSectionReusableComponent from "../../../component/ReusablaeComponent/HeaderSectionReusableComponent";

const IFCA = () => {
  useScrollAnimation();
  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading={
          "Your Digitalisation Partner in Navigating The Future of Work"
        }
        paragragh={
          <>
            Over the past 30 years, IFCA MSC Bhd has been at the forefront of
            digital transformation by developing business solutions for
            property, construction, human resources, and hospitality industries.
            Now, with a global pandemic and economic challenges at hand, our
            Business Process Automation (BPA) assists businesses in streamlining
            procedures like as employee on boarding, accounts payable, contract
            management, and many others. Our touchless, collaborative, and
            convenient solutions are more relevant than ever.
          </>
        }
        imgScr={ifca_digitalization}
      />

      <section className="py-10 bg-[#fff]">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10 xl:mx-40 mx-3 md:mx-6">
          <img src={ifca} alt="ifca" className="w-full h-full animate-up" />
          <div className="col-span-1 xl:my-auto animate-up">
            <h2>Why Choose IFCA?</h2>
            <h3>Proven</h3>
            <p className="text-paracolor ">
              Odoo For over 36 years, IFCA has been the trusted solution for
              leading businesses in property, construction, hospitality, and for
              15 years in the human resource sector.
            </p>
            <h3>Experienced</h3>
            <p className="text-paracolor ">
              With decades of experience, we have honed and tested what works
              best for our customers, offering refined and effective solutions.
            </p>
            <h3>Innovative</h3>
            <p className="text-paracolor ">
              Committed to R&D, IFCA is at the forefront of property technology,
              continually challenging norms to create groundbreaking solutions
              for our clients.
            </p>
          </div>
        </div>
      </section>

      <Image_ContentReusableComponent
        reverse={false}
        heading={"E-Invoicing"}
        paragraph={
          <>
            An e-Invoice includes the same important details as traditional
            invoices, such as information about the supplier and buyer, a
            description of the items, quantities, prices before taxes, the tax
            amount, and the total cost. This information is used to keep track
            of daily business transactions.
          </>
        }
        imgsrc={ifca_einvoice}
      />

      <section className="py-10 bg-[#fff]">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10 xl:mx-40 mx-3 md:mx-6">
          <div className="col-span-1 xl:my-auto animate-up order-1 lg:order-2">
            <h2>Embrace The Future of Work</h2>
            <h3>HRX</h3>
            <p className="text-paracolor ">
              A cloud-based HRMS that allows employees to manage HR functions
              seamlessly across all companies, even via mobile devices.
            </p>
            <h3>HotelX</h3>
            <p className="text-paracolor ">
              An award-winning mobile hotel management solution that streamlines
              operations and enhances the guest experience
            </p>
            <h3>PropertyX</h3>
            <p className="text-paracolor ">
              A cloud-based tool enabling developers to track project progress,
              automate bookings, and gain realtime insights for better
              decision-making.
            </p>
            <h3>ContractX</h3>
            <p className="text-paracolor ">
              A construction management solution that provides real-time data
              analysis, site condition monitoring, and efficient project
              management.
            </p>
          </div>
          <img
            src={ifca_futurework}
            alt="ifca_futurework"
            className="w-full h-full animate-up order-2 lg:order-1"
          />
        </div>
      </section>
    </div>
  );
};

export default IFCA;
