import { useState } from "react";
import Oracel_services from "../../../Assets/Oracel_service_header.png";

const Description = () => {
  const [careerApply, setCareerApply] = useState({
    fullname: "",
    email: "",
    countrycode: "",
    mobilenumber: "",
    applyto: "",
    experience: "",
    previoussalary: "",
    exceptedsalary: "",
    noticeperiod: "",
    location: "",
    willingToRelocate: "",
    referredBy: "",
    resume: null,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCareerApply({ ...careerApply, [name]: value });
    if (value && errors[name]) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };

  const validateForm = () => {
    let formErrors = {};

    // Validate Full Name
    if (!careerApply.fullname) {
      formErrors.fullname = "Full Name is required";
    }

    // Validate Email
    if (!careerApply.email) {
      formErrors.email = "Email is required";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(careerApply.email)) {
      formErrors.email = "Email is invalid";
    }

    // Validate Mobile Number
    if (!careerApply.mobilenumber) {
      formErrors.mobilenumber = "Mobile number is required";
    } else if (!/^\d+$/.test(careerApply.mobilenumber)) {
      formErrors.mobilenumber = "Mobile Number must contain only digits";
    } else if (careerApply.mobilenumber.length !== 10) {
      formErrors.mobilenumber = "Mobile Number must be exactly 10 digits";
    }

    // Validate Applied Position
    if (!careerApply.applyto) {
      formErrors.applyto = "Please select a position to apply";
    }

    // Validate Experience
    if (!careerApply.experience) {
      formErrors.experience = "Experience is required";
    }

    // Validate Current CTC
    if (!careerApply.previoussalary) {
      formErrors.previoussalary = "Current CTC is required";
    }

    // Validate Expected CTC
    if (!careerApply.exceptedsalary) {
      formErrors.exceptedsalary = "Expected CTC is required";
    }

    // Validate Notice Period
    if (!careerApply.noticeperiod) {
      formErrors.noticeperiod = "Notice period is required";
    }

    // Validate Location
    if (!careerApply.location) {
      formErrors.location = "Location is required";
    }

    // Validate Willing to Relocate
    if (!careerApply.willingToRelocate) {
      formErrors.willingToRelocate = "Please select your relocation preference";
    }

    // Validate Referral
    if (!careerApply.referredBy) {
      formErrors.referredBy = "Referral option is required";
    }

    // Validate Resume Upload
    if (!careerApply.resume) {
      formErrors.resume = "Resume is required";
    }

    setErrors(formErrors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Form submitted successfully!", careerApply);
    } else {
      console.log("Validation errors:", errors);
    }
  };

  return (
    <div>
      <section className="bg-[#efefef] ">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10  xl:mx-40 mx-3 md:mx-6 ">
          <div className="col-span-1 xl:my-auto  py-5 order-2 lg:order-1">
            <h1 className="">Career</h1>
            <h2>
              Together, as owners, let’s turn meaningful insights into action
            </h2>
            <p className="text-paracolor">
              Join a company rooted in ownership, teamwork, respect and
              belonging. Be part of our company of owners, where you’ll benefit
              from the rewards of our collective success, while working
              side-by-side with colleagues and clients to solve complex issues.
            </p>
          </div>
          <div className="col-span-1 flex items-center justify-center order-1 lg:order-2 image_design">
            <img
              src={Oracel_services}
              alt="Oracel_services"
              className="w-full h-full"
            />
          </div>
        </div>
      </section>
      <section className="py-10">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-10  xl:mx-40 mx-3 md:mx-6 ">
          <div className="col-span-2 bg-white shadow p-8 rounded-md">
            <h2 className="text-darkblue">
              Azure Cloud Engineer – Managed Services
            </h2>

            <div className="mb-1">
              <span className="font-semibold text-lg"> Category: </span>
              <span className="text-base text-[#333]">
                {" "}
                Infrastructure/Cloud
              </span>
            </div>

            <div className="mb-1">
              <span className="font-semibold text-lg"> Main location: </span>
              <span className="text-base text-[#333]">
                {" "}
                Netherlands, Zuid-Holland, Rotterdam
              </span>
            </div>
            <div className="mb-1">
              <span className="font-semibold text-lg"> Location: </span>
              <span className="text-base text-[#333]"> Hyderabad</span>
            </div>

            <div className="mb-1">
              <span className="font-semibold text-lg"> Position : </span>
              <span className="text-base text-[#333]"> Software Developer</span>
            </div>
            <div className="mb-1">
              <span className="font-semibold text-lg"> Employment Type: </span>
              <span className="text-base text-[#333]"> Full-time</span>
            </div>
            <hr className="text-[#cbd5e1]" />
            <p className="mt-5">
              <span className="font-semibold text-lg"> Description: </span>
              <span>
                {" "}
                Ben jij een Azure Cloud Engineer met brede kennis van Microsoft
                en toe aan een nieuwe uitdaging? Werk je graag met de nieuwste
                technologieën in Azure? Wil je werken in een dynamische
                omgeving, heb je een "get-things-done" houding en ben je bereid
                om snelle en efficiënte technische ondersteuning te bieden aan
                onze klanten? Dan zijn wij op zoek naar jou!
              </span>
            </p>
            <h5 className="text-lg text-bluecolor"> Jouw rol in ons team</h5>
            <p>
              Wij zijn op zoek naar een ervaren en gemotiveerde senior Entra ID
              engineer om het Kapil Technologies Identity & Access Management team te
              versterken. Het Kapil Technologies Identity Management team is verantwoordelijk
              voor het beheer van de Kapil Technologies Tenant en het On Premisse Domain. Als
              Lead engineer ondersteun je de Entra ID Architecten in hun
              werkzaamheden en ben je verantwoordelijk voor het implementeren
              van changes en het afhandelen van incidenten. Je begeleid en
              adviseert junior collega’s. Daarnaast adviseer stakeholders over
              de mogelijkheden en beperkingen van Entra ID en je ondersteunt en
              implementatie projecten. Je werkt in een team dat wordt
              aangestuurd vanuit Nederland met collega’s in Nederland, India,
              Canada and USA. Engels is de voertaal in het team.
            </p>

            <h5 className="text-lg text-bluecolor">
              Hoe jij ons team versterkt
            </h5>
            <p>
              Jouw passie voor alles wat met met Azure cloud te maken heeft en
              jouw ambitie om te blijven ontwikkelen en groeien, daar zijn wij
              naar op zoek. Je hebt uitstekende communicatieve en sociale
              vaardigheden, bent klantgericht en een teamplayer.
            </p>

            <ul>
              <li className="list-disc text-base leading-[1.25]  ms-5">
                <p>
                  De regie over je eigen carrière met daarbij de ruimte om
                  jezelf te blijven ontwikkelen.
                </p>
              </li>
              <li className="list-disc text-base leading-[1.25] ms-5">
                <p>Scalable Solutions</p>
              </li>
              <li className="list-disc text-base leading-[1.25]  ms-5">
                <p>
                  Een uitstekend salaris en aantrekkelijke secundaire
                  arbeidsvoorwaarden
                </p>
              </li>
              <li className="list-disc text-base leading-[1.25]  ms-5">
                <p> Data-driven Decision Making</p>
              </li>
            </ul>
          </div>
          <div className="col-span-1">
            <form
              className="bg-white shadow p-6 rounded-md"
              onSubmit={handleSubmit}
            >
              <h2>Apply Here</h2>

              {/* Full Name */}
              <div className="w-full mb-3">
                <input
                  type="text"
                  name="fullname"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  placeholder="Full Name"
                  value={careerApply.fullname}
                  onChange={handleChange}
                />
                <div className="h-3">
                  {errors.fullname && (
                    <span className="text-xs text-red ">{errors.fullname}</span>
                  )}
                </div>
              </div>

              {/* Email */}
              <div className="w-full mb-3">
                <input
                  type="email"
                  name="email"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  placeholder="Email"
                  value={careerApply.email}
                  onChange={handleChange}
                />

                <div className="h-3">
                  {errors.email && (
                    <span className="text-xs text-red ">{errors.email}</span>
                  )}
                </div>
              </div>

              {/* Mobile Number */}
              <div className="w-full mb-3">
                <input
                  type="number"
                  name="mobilenumber"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  placeholder="Mobile"
                  value={careerApply.mobilenumber}
                  onChange={handleChange}
                />

                <div className="h-3">
                  {errors.mobilenumber && (
                    <span className="text-xs text-red ">
                      {errors.mobilenumber}
                    </span>
                  )}
                </div>
              </div>

              {/* Applied To */}
              <div className="w-full mb-3">
                <select
                  name="applyto"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  value={careerApply.applyto}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Applied to
                  </option>
                  <option>Software Developer</option>
                  <option>Content Writer</option>
                  <option>Digital Marketing</option>
                </select>

                <div className="h-3">
                  {errors.applyto && (
                    <span className="text-xs text-red ">{errors.applyto}</span>
                  )}
                </div>
              </div>

              {/* Experience */}
              <div className="w-full mb-4">
                <select
                  name="experience"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  value={careerApply.experience}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Experience (Years)
                  </option>
                  <option>0-1</option>
                  <option>1-2</option>
                  <option>2-3</option>
                </select>
                <div className="h-3">
                  {errors.experience && (
                    <span className="text-xs text-red ">
                      {errors.experience}
                    </span>
                  )}
                </div>
              </div>

              {/* Current CTC */}
              <div className="w-full mb-4">
                <input
                  type="number"
                  name="previoussalary"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  placeholder="Current CTC"
                  value={careerApply.previoussalary}
                  onChange={handleChange}
                />

                <div className="h-3">
                  {errors.previoussalary && (
                    <span className="text-xs text-red ">
                      {errors.previoussalary}
                    </span>
                  )}
                </div>
              </div>

              {/* Expected CTC */}
              <div className="w-full mb-4">
                <input
                  type="number"
                  name="exceptedsalary"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  placeholder="Expected CTC"
                  value={careerApply.exceptedsalary}
                  onChange={handleChange}
                />
                <div className="h-3">
                  {errors.exceptedsalary && (
                    <span className="text-xs text-red ">
                      {errors.exceptedsalary}
                    </span>
                  )}
                </div>
              </div>

              {/* Notice Period */}
              <div className="w-full mb-4">
                <select
                  name="noticeperiod"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  value={careerApply.noticeperiod}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Notice Period
                  </option>
                  <option>Immediate Joiner</option>
                  <option>15 days</option>
                  <option>30 days</option>
                  <option>60 days</option>
                </select>

                <div className="h-3">
                  {errors.noticeperiod && (
                    <span className="text-xs text-red ">
                      {errors.noticeperiod}
                    </span>
                  )}
                </div>
              </div>

              {/* Location */}
              <div className="w-full mb-4">
                <input
                  type="text"
                  name="location"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  placeholder="Location"
                  value={careerApply.location}
                  onChange={handleChange}
                />

                <div className="h-3">
                  {errors.location && (
                    <span className="text-xs text-red ">{errors.location}</span>
                  )}
                </div>
              </div>

              {/* Willing to Relocate */}
              <div className="w-full mb-4">
                <select
                  name="willingToRelocate"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  value={careerApply.willingToRelocate}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Willing to relocate?
                  </option>
                  <option>Yes</option>
                  <option>No</option>
                </select>

                <div className="h-3">
                  {errors.willingToRelocate && (
                    <span className="text-xs text-red ">
                      {errors.willingToRelocate}
                    </span>
                  )}
                </div>
              </div>

              <label className="text-base mb-1">
                Referred by internal employee?
              </label>

              <div>
                <div className="w-full  flex">
                  <label>
                    <input
                      type="radio"
                      name="referredBy"
                      value="yes"
                      checked={careerApply.referredBy === "yes"}
                      onChange={handleChange}
                    />
                    Yes
                  </label>
                  <label className="ml-4">
                    <input
                      type="radio"
                      name="referredBy"
                      value="no"
                      checked={careerApply.referredBy === "no"}
                      onChange={handleChange}
                    />
                    No
                  </label>
                </div>
                <div className="h-3 mb-4">
                  {errors.referredBy && (
                    <span className="text-xs text-red ">
                      {errors.referredBy}
                    </span>
                  )}
                </div>
              </div>

              {/* Resume Upload */}
              <label className="text-base"> Resume</label>
              <div className="w-full mb-4">
                <input
                  type="file"
                  name="resume"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                />

                <div className="h-3 ">
                  {errors.resume && (
                    <span className="text-xs text-red ">{errors.resume}</span>
                  )}
                </div>
              </div>

              <button
                type="submit"
                className="border  bg-[#4e8ecb] rounded-md py-2 px-4 text-[#fff] mb-2 "
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Description;
