import React from "react";
import { Link } from "react-router-dom";

const Insights = ({ insightsLinks , setActive , setOpen ,  linkClicked,}) => {
  return (
    <div>
      <div className="absolute top-0  xl:top-10 xl:block hidden z-10 shadow-xl ">
        <div className="bg-[#fff] p-5">
          <div>
            {insightsLinks.insights_1?.links?.subLink1.map((item) => (
              <li className="no-underline list-none mb-2 capitalize">
                <Link
                  to={item.link}
                  className="text-sm text-gray-400  hover:text-[#4A3AFF]"
                  onClick={() => {setActive("")
                    linkClicked()}
                  }
                >
                  {item.name}
                </Link>
              </li>
            ))}{" "}
          </div>
        </div>
      </div>
      {/* /* mobile screen*/}

      <div className="xl:hidden block">
        <div>
          {insightsLinks.insights_1?.links?.subLink1.map((item) => (
            <li className="no-underline list-none mb-2">
              <Link
                to={item.link}
                className="text-sm text-gray-400 my-2.5 hover:text-[#4A3AFF] ml-5"
                onClick={() => setOpen("")}
              >
                {item.name}
              </Link>
            </li>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Insights;
