import Odoo_softwaretool from "../../../Assets/Oddo_ErpSystem.png";
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeadingParagraphSection from "../../component/ReusablaeComponent/HeadingParagraphSection";
import FutureEnergy from "../../../Assets/Future of Energy.png";
import Energy_solutions from "../../../Assets/Energy Solutions.png";
import Energy_infrastructure from "../../../Assets/Energy Infrastructure.png";
import Transformation_energy from "../../../Assets/Digital Transformation in Energy.png";
import Innovation from '../../../Assets/Product Innovation.png'

const Energy = () => {
  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading="Powering the Future of Energy"
        paragragh={
          <>
            We drive innovation in the energy sector by providing cutting-edge
            solutions that address the global demand for reliable, affordable,
            and clean energy. Our expertise spans across renewable energy, smart
            grids, and energy efficiency technologies.
          </>
        }
        imgScr={FutureEnergy}
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading="Renewable Energy Solutions"
        paragraph={
          <>
            Committed to sustainability, we offer end-to-end renewable energy
            solutions, including solar, wind, and hydroelectric systems. Our
            services help businesses transition to cleaner energy sources,
            reducing their carbon footprint and promoting environmental
            responsibility.
          </>
        }
        imgsrc={Energy_solutions}
      />
      <HeadingParagraphSection
        heading="Energy Efficiency and Optimization"
        paragraph={
          <>
            Improve energy usage with our advanced efficiency solutions. From
            energy audits to smart grid integration, we help businesses reduce
            energy consumption, lower operational costs, and meet energy
            compliance standards.
          </>
        }
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading="Sustainable Energy Infrastructure"
        paragraph={
          <>
            Building the future of energy requires robust infrastructure. Our
            sustainable energy infrastructure services include the design,
            implementation, and maintenance of advanced energy systems that
            ensure resilience and scalability for the future.
          </>
        }
        imgsrc={Energy_infrastructure}
      />
      <Image_ContentReusableComponent
        heading="Digital Transformation in Energy"
        paragraph={
          <>
            <p>
              The future of energy lies in digitalization. From predictive
              maintenance to IoT-driven energy management systems, we empower
              businesses to harness data and technology for optimized energy use
              and increased profitability.
            </p>
            This structure provides a clear view of your company's capabilities
            in the energy sector, aligned with modern energy challenges and
            solutions.
          </>
        }
        imgsrc={Transformation_energy}
      />
    </div>
  );
};

export default Energy;
