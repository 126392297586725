import Odoo_softwaretool from "../../../Assets/Oddo_ErpSystem.png";
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeadingParagraphSection from "../../component/ReusablaeComponent/HeadingParagraphSection";
import industrial_machinery from '../../../Assets/Industrial Machinery.png';
import Smart_factory from '../../../Assets/Smart Factory Solutions.png';
import Mechinery_design from '../../../Assets/Machinery Design.png';
import Safety_compailance from '../../../Assets/Safety and Compliance.png';
import Spare_Parts from '../../../Assets/Spare Parts.png'

const MachineryAndEquipment = () => {
  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading="Comprehensive Solutions for Industrial Machinery"
        paragragh={
          <>
            We offer a wide range of innovative solutions for industrial
            machinery and equipment, helping businesses achieve operational
            excellence. From design and installation to maintenance, we provide
            end-to-end support tailored to your specific needs.
          </>
        }
        imgScr={industrial_machinery}
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading="Automation and Smart Factory Solutions "
        paragraph={
          <>
            Transform your manufacturing processes with intelligent automation
            systems. Our smart factory solutions integrate robotics, IoT, and
            AI-driven technologies to optimize workflows, reduce downtime, and
            increase overall productivity.
          </>
        }
        imgsrc={Smart_factory}
      />
      <HeadingParagraphSection
        heading="Equipment Maintenance and Lifecycle Management"
        paragraph={
          <>
            Maximize the lifespan of your industrial equipment with our expert
            maintenance and lifecycle management services. We offer predictive
            maintenance, real-time monitoring, and regular servicing to prevent
            costly breakdowns and ensure continuous operations.
          </>
        }
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading="Custom Machinery Design and Engineering"
        paragraph={
          <>
            Our team of skilled engineers designs custom machinery tailored to
            your business's unique requirements. From concept to production, we
            create equipment that enhances performance, safety, and scalability
            across diverse industrial sectors.
          </>
        }
        imgsrc={Mechinery_design}
      />
      <Image_ContentReusableComponent
        heading="Industrial Safety and Compliance"
        paragraph={
          <>
            Ensure the safety and compliance of your machinery with our advanced
            safety features and compliance services. We provide comprehensive
            risk assessments, equipment inspections, and adherence to global
            industry standards to ensure your operations meet all safety
            requirements.
          </>
        }
        imgsrc={Safety_compailance}
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading="Aftermarket Services and Spare Parts"
        paragraph={
          <>
          <p>
            Keep your machinery running smoothly with our aftermarket services.
            We provide genuine spare parts, equipment upgrades, and support
            services to ensure maximum uptime and peak performance throughout
            the equipment's lifecycle.</p>
          
            This structure provides a professional and informative layout for
            your industrial machinery and equipment page, showcasing your
            company's capabilities.
          </>
        }
        imgsrc={Spare_Parts}
      />
    </div>
  );
};

export default MachineryAndEquipment;
