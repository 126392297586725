import { useScrollAnimation } from "../../../utils/Utils";
import Odoo_softwaretool from "../../../Assets/odoo_software_tools.png";
import Odoo_Erpsystem from "../../../Assets/Oddo_ErpSystem.png";
import Odoo_customizedservices from "../../../Assets/Oddo_Customization Svices.png";
import Business_process from "../../../Assets/Business_process.png";
import odoo_costeffective from "../../../Assets/Odoo_costeffective.png";
import odoo_productivity from "../../../Assets/odoo_Productivityy.png";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";

const Oddo = () => {
  useScrollAnimation();
  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading={"What is Odoo?"}
        paragragh={
          <>
            <span className="font-bold">Odoo</span> is a suite of Belgian origin
            business management software tools that include customer
            relationship management, e-commerce, billing, accounting,
            manufacturing, warehouse, project management, and inventory
            management. The job of the odoo consultant is mostly contributory to
            your ERP implementation. From choosing the ERP framework to its
            Execution, from choosing the correct fit modules to adjust your
            business procedure to those modules, They Do everything.
          </>
        }
        imgScr={Odoo_softwaretool}
      />

      {/*  Odoo ERP */}
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading={"Odoo ERP"}
        paragraph={
          <>
            Odoo{" "}
            <span className="font-bold">
              ERP system is enterprise resource planning software used
              company-wide for the management of business processes.
            </span>{" "}
            Odoo provides seamlessly integrated functional business apps called
            Odoo apps that form an ERP solution when combined. Open-source
            software, Odoo, is available with SaaS subscription pricing as the
            Enterprise edition or as the Odoo free Community version.
          </>
        }
        imgsrc={Odoo_Erpsystem}
      />

      <Image_ContentReusableComponent
        reverse={false}
        heading={"Odoo Customization Services"}
        paragraph={
          <>
            Odoo Customization Service and custom module development offered by
            Globaltech in order to ensure you can use ERP software as per your
            business needs and workflows of your organizations. There are many
            apps available through which you can add the functionality in
            existing Enterprise resource planning. But standalone apps don’t
            work as per the processes that is where you require modify app
            development or tailored services.
          </>
        }
        imgsrc={Odoo_customizedservices}
      />

      <section className="py-10 bg-[#fff]">
        <h2 className="xl:mx-36 mx-3 md:mx-10">Case Studies</h2>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-5 xl:mx-36 mx-3 md:mx-10">
          <div className="col-span-1 max-w-sm rounded overflow-hidden shadow-lg bg-[#fff] card-container animate-up flex flex-col">
            <div className="imagehover relative">
              <img
                src={Business_process}
                alt="Business_process"
                className="w-full"
              />
              <div className="overlay absolute inset-0 bg-[#fff] opacity-0 transition-opacity duration-300"></div>
            </div>
            <div className="px-6 py-4 flex-grow flex flex-col">
              <h3 className="pb-[12px] font-bold">
                Streamlined Business Processes
              </h3>

              <p className=" transition-colors duration-300 flex-grow">
                Odoo ERP acts as a unified platform, streamlining and automating
                diverse business processes. From order processing to inventory
                management, the system ensures efficiency and reduces the
                likelihood of errors.
              </p>
            </div>
          </div>

          {/* Repeat similar structure for other cards */}
          <div className="col-span-1 max-w-sm rounded overflow-hidden shadow-lg bg-[#fff] card-container animate-up flex flex-col">
            <div className="imagehover relative">
              <img
                src={odoo_costeffective}
                alt="odoo_costeffective"
                className="w-full"
              />
              <div className="overlay absolute inset-0 bg-[#000] opacity-0 transition-opacity duration-300"></div>
            </div>
            <div className="px-6 py-4 flex-grow flex flex-col">
              <h3 className="pb-[12px] font-bold">Cost-Effective Solution</h3>

              <p className=" transition-colors duration-300 flex-grow">
                The open-source nature of Odoo means no hefty licensing fees.
                For organizations mindful of their budget, Odoo ERP offers a
                costeffective alternative without compromising on functionality.
              </p>
            </div>
          </div>

          <div className="col-span-1 max-w-sm rounded overflow-hidden shadow-lg bg-[#fff] card-container animate-up flex flex-col">
            <div className="imagehover relative">
              <img
                src={odoo_productivity}
                alt="odoo_productivity"
                className="w-full"
              />
              <div className="overlay absolute inset-0 bg-[#000] opacity-0 transition-opacity duration-300"></div>
            </div>
            <div className="px-6 py-4 flex-grow flex flex-col">
              <h3 className="pb-[12px] font-bold">Enhanced Productivity</h3>

              <p className="transition-colors duration-300 flex-grow">
                The user-friendly interface makes Odoo ERP accessible to all
                team members. Quick adaptation leads to increased productivity,
                and the automation of workflows further enhances efficiency.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Oddo;
