import Image_ContentReusableComponent from "../../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeaderSectionReusableComponent from "../../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import customized_platform from '../../../../Assets/Customized_Platform (2).png'
import chitfund_innovation from "../../../../Assets/chitfund_innovation.png";
import EasyChitsServices from "../../../../Assets/EasyChitsServices.jpg";
import { useScrollAnimation } from "../../../../utils/Utils";
import why_easychits from "../../../../Assets/why_easychits.png";
import Content_GradientLine from "../../../component/ReusablaeComponent/Content_GradientLine";
import HeadingParagraphSection from "../../../component/ReusablaeComponent/HeadingParagraphSection";
import PointsWithHeading from "../../../component/ReusablaeComponent/PointsWithHeading";
import { useState } from "react";



const Easychits = () => {
  const listItems = [
    {
      title: "Digital Onboarding:",
      description:
        "Allows subscribers to enroll themselves and join chit fund schemes online, at their convenience.",
    },
    {
      title: "e-KYC:",
      description:
        "Facilitates your subscribers to perform and update online KYC at their convenience.",
    },
    {
      title: "Online Payments:",
      description: "Facilitates any transaction online with secure gateways.",
    },
    {
      title: "Subscriber App: ",
      description:
        "Allows subscribers to track their chit fund investments, dividends, make bid offers, and manage their contact info and other personal details.",
    },
    {
      title: "Collection App: ",
      description:
        "A special module that allows your chit fund company associates to collect monthly chit installments from subscribers in any mode - cash, online, cheque, etc.",
    },
    {
      title: " Online Enquiries and Customer Support:",

      description:
        " Enables subscribers to post their enquiries online and get customer support for any grievances related to their profile.",
    },
  ];


  const features = [
    { strongText: 'Reliable:', text: 'Delivers reliable and efficient performance.' },
    { strongText: 'Scalable:', text: 'Can be scaled according to your dynamic business needs.' },
    { strongText: 'Secure:', text: 'Offers robust data security and compliance with latest encryption standards.' },
    { strongText: 'Inexpensive:', text: 'Comes at a reasonable price and makes your operations cost-effective.' },
    { text: 'A Single Platform Catering to Multiple Operational Needs: Fulfills your chit fund companies’ multiple enterprise planning needs in one place.' },
    { text: 'Enables Faster Fund Clearances: Equipped with NACH facility for faster fund clearances.' },
    { text: 'Facilitates World-class Customer Support: Provides excellent customer support platform.' },
  ];


  const [formData, setFormData] = useState({
    fullname: "",
    company: "",
    workemail: "",
    country: "",
    countrycode: "",
    mobilenumber: "",
    nicheindustry: "",
    about: "",
    agreement: false,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    // Validate the input field while typing
    validateField(name, value, type === "checkbox" ? checked : value);
  };

  // Field validation for live error checking
  const validateField = (name, value, checked) => {
    let error = "";

    switch (name) {
      case "fullname":
        if (!value) error = "Full Name is required";
        break;

      case "company":
        if (!value) error = "Company Name is required";
        break;

      case "workemail":
        if (!value) {
          error = "Work Email is required";
        } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          error = "Email address is invalid";
        }
        break;

      case "country":
        if (!value) error = "Country is required";
        break;

      case "countrycode":
        if (!value) error = "Country Code is required";
        break;

      case "mobilenumber":
        if (!value) {
          error = "Mobile Number is required";
        } else if (!/^\d+$/.test(value)) {
          error = "Mobile Number must contain only digits";
        } else if (value.length !== 10) {
          error = "Mobile Number must be exactly 10 digits";
        }
        break;

      case "nicheindustry":
        if (!value) error = "Niche Industry is required";
        break;

      case "about":
        if (!value) error = "Please tell us how we can help you";
        break;

      case "agreement":
        if (!checked) error = "You must accept the agreement";
        break;

      default:
        break;
    }

    setErrors((prevErrors) => {
      if (error) {
        return { ...prevErrors, [name]: error }; // Set error if validation fails
      } else {
        const { [name]: removedError, ...rest } = prevErrors; // Remove error if validation passes
        return rest;
      }
    });
  };

  // Validation for all fields on submit
  const validate = () => {
    let formErrors = {};

    // Full Name Validation
    if (!formData.fullname) formErrors.fullname = "Full Name is required";

    // Company Validation
    if (!formData.company) formErrors.company = "Company Name is required";

    // Work Email Validation
    if (!formData.workemail) {
      formErrors.workemail = "Work Email is required";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.workemail)) {
      formErrors.workemail = "Email address is invalid";
    }

    // Country Validation
    if (!formData.country) formErrors.country = "Country is required";

    // Country Code Validation
    if (!formData.countrycode)
      formErrors.countrycode = "Country Code is required";

    // Mobile Number Validation
    if (!formData.mobilenumber) {
      formErrors.mobilenumber = "Mobile Number is required";
    } else if (!/^\d+$/.test(formData.mobilenumber)) {
      formErrors.mobilenumber = "Mobile Number must contain only digits";
    } else if (formData.mobilenumber.length !== 10) {
      formErrors.mobilenumber = "Mobile Number must be exactly 10 digits";
    }

    // Niche Industry Validation
    if (!formData.nicheindustry)
      formErrors.nicheindustry = "Niche Industry is required";

    // About Validation
    if (!formData.about)
      formErrors.about = "Please tell us how we can help you";

    // Agreement Validation
    if (!formData.agreement)
      formErrors.agreement = "You must accept the agreement";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // Form submission logic
      console.log("Form data submitted:", formData);
    } else {
      console.log("Validation errors:", errors);
    }
  };
  useScrollAnimation();
  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading={
          "Chit Fund Software That Modernizes Your Chit Fund Business With Tomorrow’s Technology, Today."
        }
        heading={
          "Put Your Chit Fund Business on Top With EasyCHIT - India’s Top Chit Fund Software"
        }
        paragragh={
          <>
            Make your chit fund operations future-ready with the power of
            EasyCHIT - India’s leading chit fund software, an innovative product
            from Kapil IT Solutions. When you simplify your business processes
            with EasyCHIT’s futuristic solutions, your company resources can
            dominantly focus on what is more important - productivity. Apart
            from being just a chit fund-centric platform, this software serves
            chit fund companies as an ERP suite that streamlines their planning
            and operations with end-to-end solutions.
          </>
        }
        imgScr={EasyChitsServices}
      />

      {/* A Chit Fund Software at the Forefront of Innovation With Its
              Advanced Features */}

      <Content_GradientLine
        title=" A Chit Fund Software at the Forefront of Innovation With Its Advanced Features"
        subHeading=" As a modern chit fund software that pioneers innovation, EasyCHIT was the first to introduce online auctions in the chit fund industry. That apart, we help chit fund companies to digitally onboard their customers/subscribers and facilitate a broad range of online services that enhance customer convenience. Whether its onboarding, payments collection, online auctions, subscriber bid offers, dividend distribution, customer support, and more, accomplish everything online with the top-notch capabilities of EasyCHIT."
        paragraph={<>Take a brief look at its innovative features:</>}
        listItems={listItems}
        imgSrc={chitfund_innovation}
      />

      {/*  Optimize Chit Fund Processes, Bring Efficiency and Transparency*/}

      <HeadingParagraphSection
        heading={
          "Optimize Chit Fund Processes, Bring Efficiency and Transparency"
        }
        paragraph={
          <>
            As a truly advanced software, EasyCHIT automates your company’s
            basic business processes that are repeatable on a regular basis.
            This minimizes scope for human errors and brings in efficiency,
            unlocking enhanced productivity in core operations for your
            workforce. It also brings you transparent mechanisms in terms of
            accounting, auditing, reporting, compliance, and information
            security.
          </>
        }
      />

      {/* Customizable Platform Flexible for Your Needs  */}

      <Image_ContentReusableComponent
        bgColor="#fff"
        heading={"Customizable Platform Flexible for Your Needs"}
        paragraph={
          <>
            We Tailor Every Workflow to Suit Your Business Needs So You Can
            Unleash Maximum Competitiveness.
            <span className="block mt-3">
              You can have EasyCHIT customized to cater to any of your chit fund
              business needs. The platform offers you high flexibility so that
              you can be at your innovative best while delivering quality chit
              fund services/schemes and utmost customer satisfaction to your
              subscribers.
            </span>
          </>
        }
        imgsrc={customized_platform}
      />

      {/*  Get Cost-effective, Overcome Challenges With Our Oracle Services*/}
      <PointsWithHeading
      title="Why Consider EasyCHIT?"
      reverse={false}
      description="As a leading-edge chit fund software, EasyCHIT brings you a host of advantages that keep you ahead of the competition. The software is:"
      features={features}
      imageSrc={why_easychits}
      altText="why_easychits"
    />

<section className="bg-white py-10 ">
        <div className="xl:mx-40 mx-3 md:mx-6">
          <h2 className="text-center">
          Grab the Power of EasyCHIT and Elevate Your Chit Fund
          Business Today.
          </h2>

          <form onSubmit={handleSubmit} className="">
            {/* Form Fields */}
            <div className="grid md:grid-cols-2 gap-6 mt-5">
              <div className="w-full">
                <input
                  type="text"
                  name="fullname"
                  id="name"
                  value={formData.fullname}
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  placeholder="Full Name"
                  onChange={handleChange}
                />

                <div className="h-3">
                  {errors.fullname && (
                    <span className="text-xs text-red ">{errors.fullname}</span>
                  )}
                </div>
              </div>
              <div className="w-full">
                <input
                  type="text"
                  name="company"
                  id="company"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  placeholder="Company Name"
                  value={formData.company}
                  onChange={handleChange}
                />

                <div className="h-3">
                  {errors.company && (
                    <span className="text-xs text-red ">{errors.company}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-6 mt-5">
              <div className="w-full">
                <input
                  type="email"
                  id="email"
                  name="workemail"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  value={formData.workemail}
                  placeholder="Work Email"
                  onChange={handleChange}
                />
                <div className="h-3">
                  {errors.workemail && (
                    <span className="text-xs text-red ">
                      {errors.workemail}
                    </span>
                  )}
                </div>
              </div>
              <div className="w-full">
                <select
                  id="country"
                  name="country"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  value={formData.country}
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    Country
                  </option>
                  <option>Canada</option>
                  <option>France</option>
                  <option>Germany</option>
                  {/* Add more countries as needed */}
                </select>
                <div className="h-3">
                  {errors.country && (
                    <span className="text-xs text-red ">{errors.country}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-6 mt-5">
              <div className="w-full">
                <select
                  id="countrycode"
                  name="countrycode"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  value={formData.countrycode}
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    Country Code
                  </option>
                  <option>435345</option>
                  <option>345345345</option>
                  <option>34534534543</option>
                  {/* Add more countries as needed */}
                </select>
                <div className="h-3">
                  {errors.countrycode && (
                    <span className="text-xs text-red ">
                      {errors.countrycode}
                    </span>
                  )}
                </div>
              </div>
              <div className="w-full">
                <input
                  type="number"
                  name="mobilenumber"
                  id="mobile-number"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  value={formData.mobilenumber}
                  placeholder="Mobile"
                  onChange={handleChange}
                />
                <div className="h-3">
                  {errors.mobilenumber && (
                    <span className="text-xs text-red ">
                      {errors.mobilenumber}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-6 mt-5 ">
              <div className="w-full mb-2">
                <input
                  type="text"
                  id="nicheindustry"
                  name="nicheindustry"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  placeholder="Niche Industry"
                  value={formData.nicheindustry}
                  onChange={handleChange}
                />
                <div className="">
                  {errors.nicheindustry && (
                    <span className="text-xs text-red ">
                      {errors.nicheindustry}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="border  bg-[#4e8ecb] rounded-md  px-4 text-[#fff] mb-2 "
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Easychits;
