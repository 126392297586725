import Google_colud_consultancy from "../../../Assets/GoogleColud_consultancy.png";
import Google_colud_services from "../../../Assets/Google_cloud_services.png";
import Google_cloud_capability from "../../../Assets/Google_cloud_capability.png";
import Google_colud_digital_transformation from "../../../Assets/Goggle_cloud_digital_transformation.png";
import Google_colud_centricapproch from "../../../Assets/Google_Colud_centericapproch.png";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import Content_GradientLine from "../../component/ReusablaeComponent/Content_GradientLine";
import ListOfLinkSection from "../../component/ReusablaeComponent/ListOfLinkSection";
import HeadingParagraphSection from "../../component/ReusablaeComponent/HeadingParagraphSection";
const Google_Colud_Services = () => {
  const listItems = [
    {
      title: "Speed Up Your Digital Transformation: ",
      description:
        "Leverage our consultants’ tech and business acumen to realize faster returns on your investment with their proficient skills on broad-ranging business accelerator tools that come from our partnership with various technology powerhouses.",
    },
    {
      title: "Use Powerful Insights That Enable Strategic Decisions: ",
      description:
        "Our consultants will help you deep-dive into actionable analytics that will help you make business-friendly decisions with a customer-centric approach on your mind. These analytics can span any aspect of Google Cloud’s technology framework.",
    },
    {
      title: "Streamline Infrastructure Costs: ",
      description:
        "Take advantage of our consultants’ knowhow on optimizing your Google Cloud infrastructure costs that lets you pay rationally only for the cloud services that you use.",
    },
  ];
  const ListofLinks = [
    "Google Cloud-based ERP",
    "Cloud-native Orchestration",
    "Data Center Migration",
    "Agile Hybrid Solutions",
    " AI Advisory",
  ];
  return (
    <div>
      {/* Header */}
      <HeaderSectionReusableComponent
        mainHeading={
          " Thrive With Peak Performances Using the Excellence of Google Cloud Services"
        }
        heading={
          " Experience Top-class Digital Transformation of Your Enterprise with Our Google Cloud Expertise"
        }
        paragragh={
          <>
            Cutting-edge cloud computing infrastructure and data services must
            be an indispensable part of all future-ready enterprises. Get your
            robust cloud infrastructure and data services in place with our
            unmatched Google Cloud services expertise that equips you with the
            platform’s excellent AI capacity and its powerful analytics that
            drive an outcome-oriented digital transformation.
          </>
        }
        imgScr={Google_colud_services}
      />
      {/*Reimagine a Truly Modern Enterprise With Futuristic Google Cloud Capabilities*/}
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading={
          "Reimagine a Truly Modern Enterprise With Futuristic Google Cloud Capabilities"
        }
        paragraph={
          <>
            Bank on game-changing Google Cloud solutions that enable your
            enterprise to climb up your specific industry’s value-chain with a
            full-spectrum modernization of your business processes. Be on top of
            your game with advanced cloud-based data services, analytics,
            artificial intelligence, and machine learning solutions. Enrich your
            journey in application upgradation through our far and wide-reaching
            expertise with DevOps, blockchain, Augmented Reality/Virtual
            Reality, and IoT technologies. Do more and deliver value to your
            customers with secure cloud technology infrastructure that drives
            uninterrupted data services and top productivity.
          </>
        }
        imgsrc={Google_cloud_capability}
      />
      {/*Realize a Fast-paced Digital Transformation for Your Enterprise with Our Holistic Consulting Services for Google Cloud Solutions*/}
      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading={
          "Realize a Fast-paced Digital Transformation for Your Enterprise with Our Holistic Consulting Services for Google Cloud Solutions"
        }
        paragraph={
          <>
            Empower your enterprise to achieve a fast-tracked transformation
            that’s futuristic yet sustainable for your business operations with
            our all-encompassing expertise of
            <span className="font-semibold"> Google Cloud solutions</span>. Our
            consultants have tremendous hold over industry trends and will
            tailor the most unparalleled and flexible cloud solutions for you
            after a deep dive into your business and its operational needs.
          </>
        }
        imgsrc={Google_colud_digital_transformation}
      />
      {/* Our Top Consulting Services for Google Cloud-based Solutions */}
      <ListOfLinkSection
        title=" Our Top Consulting Services for Google Cloud-based Solutions"
        ListofLinks={ListofLinks}
      />
      {/*   What Our Consultants Bring to the Table*/}
      <Content_GradientLine
        title="How Our Consultants Add Value to Your Business"
        subHeading="Expert Consulting That Elevates Modern-day Enterprises"
        paragraph={
          <>
            Our consultants are equipped with tremendous strategic intelligence
            that will make your investments in Google Cloud extremely viable
            both technology and business-wise. As enablers, they help you
            accomplish the following:
          </>
        }
        listItems={listItems}
        imgSrc={Google_colud_consultancy}
      />
      {/*  Precision-driven Analytics That Make Decision-making Easier */}

      <HeadingParagraphSection
        heading={
          "Break the Conventions and Inspire Industry Trends with Kapil Technologies’ Google Cloud Expertise"
        }
        paragraph={
          <>
            Kapil Technologies has partnered with Google to help new-age
            enterprises achieve cloud-based digital transformation. We
            facilitate the future-proof implementation of a broad-spectrum of
            Google Cloud capacities by enterprises so that they can set
            excellent benchmarks of doing business through the delivery of
            quality services. Our Google Cloud expertise thus helps enterprises
            to establish a robust business ecosystem with great emphasis on
            analytics, marketing, business prediction, service-delivery, and
            customer-friendly mechanisms.
          </>
        }
      />

      {/* Experience Consulting That Helps You Capture New Horizons */}
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading={"Experience Consulting That Helps You Capture New Horizons"}
        paragraph={
          <>
            With their time-tested and enduring experience, our consultants help
            you adopt next-generation technologies that enable you to conquer
            new horizons. Not only do our market insights serve as a catalyst
            for enterprises to bolster their presence in existing markets, they
            also encourage them to go beyond and explore the potential in new
            markets through meaningful research and forecasts. So strengthen
            your business and expand your presence by making forays into new
            territories with extraordinary market insights that our consulting
            services bring you with their powerful
            <span className="font-semibold"> business intelligence (BI).</span>
          </>
        }
        imgsrc={Google_cloud_capability}
      />
      {/*Create Customer Experiences that Redefine the Norm*/}
      <Image_ContentReusableComponent
        reverse={false}
        heading={"Create Customer Experiences that Redefine the Norm"}
        paragraph={
          <>
            The modern-day customer seeks out pleasant experiences when doing
            business with contemporary enterprises. Enable such experiences for
            your customers with Kapil Technologies’ cutting-edge capabilities on
            Google Cloud technology. They let you formulate top-notch strategies
            that make use of Google’s cloud platform to integrate a variety of
            enterprise services and enhance customer experiences. Deliver
            quality services seamlessly and exceed your customers’ expectations,
            every time. Establish customer relationship management norms that
            are sustainable and beat the industry norms with our consultants’
            professional guidance.
          </>
        }
        imgsrc={Google_colud_centricapproch}
      />
    </div>
  );
};

export default Google_Colud_Services;
