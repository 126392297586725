import Google_colud_services from "../../../../Assets/Google_cloud_services.png";
import smaple2nd_img from "../../../../Assets/Sameple2nd_img.png";

import { useScrollAnimation } from "../../../../utils/Utils";
import mpdv_aiintellegent from "../../../../Assets/mpdv_ai_intellegent.png";
import mpdv_customization from "../../../../Assets/mpdv_customization.png";
import mpdv_innovation from "../../../../Assets/mpdv_innovation.png";
import mpdv_producionandsolutions from "../../../../Assets/mpdv_productsand solutions.png";
import mpdv_history from "../../../../Assets/mpdv_history.png";
import Image_ContentReusableComponent from "../../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeaderSectionReusableComponent from "../../../component/ReusablaeComponent/HeaderSectionReusableComponent";

const MPDV = () => {
  useScrollAnimation();
  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading={"History"}
        paragragh={
          <>
            The Smart Factory Society is founded. It brings together the HYDRA
            Users Group and further user associations under one roof. MPDV hosts
            the Smart Factory Society Conference for the first time. The MPDV
            Group proclaims the first World Smart Factory Day. With the Action
            Day on September 15, MPDV continues to pave the way to the Smart
            Factory and digital transformation as a pioneer.
          </>
        }
        imgScr={mpdv_history}
      />

      <section className="py-10 bg-[#fff]">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10 xl:mx-40 mx-3 md:mx-6">
          <img
            src={mpdv_producionandsolutions}
            alt="mpdv_producionandsolutions"
            className="w-full h-full animate-up"
          />
          <div className="col-span-1 xl:my-auto animate-up">
            <h2>Products and Solutions</h2>
            <p>
              The Smart Factory needs suitable manufacturing IT that integrates
              a variety of functions and systems working together flexibly. To
              ensure that everything runs like clockwork, complete
              interoperability is essential.
            </p>

            <ul className="ps-4 pt-2">
              <li className="list-disc">
             <span className="font-bold underline">   The Manufacturing Execution System (MES) HYDRA X</span> makes a
                significant contribution to continuously monitoring,
                controlling, and optimizing production.
              </li>
              <li className=" list-disc">
                The     <span className="font-bold underline"> Advanced Planning and Scheduling System (APS) FEDRA</span> provides
                a simple, dynamic, realistic, and adaptive way of planning
                simple work processes
              </li>
              <li className="list-disc">
                The <span className="font-bold underline">Manufacturing Integration Platform (MIP)</span>, MPDV allows you to
                combine manufacturing applications from different providers for
                your specific requirements.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Image_ContentReusableComponent
        reverse={false}
        heading={"Innovation & Knowledge"}
        subheading={"Shaping the future of the Smart Factory"}
        paragraph={
          <>
            Innovation is a top priority at MPDV, because only those who are
            always a µ better than all the others can survive as market leaders
            in the long term. The fact that MPDV is one of the most innovative
            companies in Germany is proven, among other things, by the fact that
            we have received the{" "}
            <span className="font-bold">TOP 100 Award</span> several times. In
            2023, we even took second place in the category "Companies with more
            than 200 employees".
          </>
        }
        imgsrc={mpdv_innovation}
      />

      <section className="py-10 bg-[#fff]">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10 xl:mx-40 mx-3 md:mx-6">
          <img
            src={mpdv_customization}
            alt="mpdv_customization"
            className="w-full h-full animate-up"
          />
          <div className="col-span-1 xl:my-auto animate-up">
            <h2>Configuration and customization</h2>
            <p>
              The objective should always be to map individual requirements in
              the MES system in a way that the characteristics of a standard
              software program remain unchanged. MPDV’s MES systems offer a wide
              range of configuration and customization options to do so. The MES
              experts at MPDV offer services to perform the required tasks and
              to support any other issues:
            </p>

            <ul className="ps-4 pt-2">
              <li className="text-paracolor list-disc">
                Customized design of intuitive user interfaces
              </li>
              <li className="text-paracolor list-disc">
                Mapping relevant MES processes using a wide range of
                configuration options
              </li>
              <li className="text-paracolor list-disc">
                Mapping customized requirements using various development tools
              </li>
              <li className="text-paracolor list-disc">
                Support during upgrades or modification of HYDRA standard
                function
              </li>
            </ul>
          </div>
        </div>
      </section>

      <Image_ContentReusableComponent
        reverse={false}
        heading={"Artificial Intelligence (AI) in Manufacturing"}
        subheading={"AI fields of application today and in future"}
        paragraph={
          <>
            Predictive analytics, preventive maintenance, networked logistics
            are features characterizing digitalization of production. As an
            element of the fourth industrial revolution, digitalization has long
            started its triumphal march and in its wake also artificial
            intelligence (AI). In many sectors, AI helps develop new business
            models and optimize existing value chains. AI's impact on industrial
            production is considerable as it offers manifold fields of
            application.
          </>
        }
        imgsrc={mpdv_aiintellegent}
      />
    </div>
  );
};

export default MPDV;
