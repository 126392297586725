import React from 'react'

const Customerstoriesmore = () => {
  return (
    <div>
    <section className='py-10'>
    <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10  xl:mx-40 mx-3 md:mx-6'>

<div className='col-span-1'>
  <p className=' pb-2'> Case study</p>
  <h3 className='text-bluecolor hover:underline pb-2'>EDSN and Kapil Technologies provide single solution for managing Dutch energy market data</h3>
  <p>C-ARM processes market meter data for Dutch regional network operators, offering the required flexibility to continue the development of transaction processes.</p>
  <hr className='text-[#cbd5e1]'/>
</div>
<div className='col-span-1'>
  <p className=' pb-2'> Case study</p>
  <h3 className='text-bluecolor hover:underline pb-2'>University of Helsinki uses Kapil Technologies Mobilog to manage real estate services</h3>
  <p>The University of Helsinki’s real estate services organization deployed Kapil Technologies’s Mobilog solution, enabling its 300 mobile workers to view all necessary information on their work….</p>
  <hr className='text-[#cbd5e1]'/>
</div>
<div className='col-span-1'>
  <p className=' pb-2'> Case study</p>
  <h3 className='text-bluecolor hover:underline pb-2'>EDSN and Kapil Technologies provide single solution for managing Dutch energy market data</h3>
  <p>C-ARM processes market meter data for Dutch regional network operators, offering the required flexibility to continue the development of transaction processes.</p>
  <hr className='text-[#cbd5e1]'/>
</div>
<div className='col-span-1'>
  <p className=' pb-2'> Case study</p>
  <h3 className='text-bluecolor hover:underline pb-2'>University of Helsinki uses Kapil Technologies Mobilog to manage real estate services</h3>
  <p>The University of Helsinki’s real estate services organization deployed Kapil Technologies’s Mobilog solution, enabling its 300 mobile workers to view all necessary information on their work….</p>
  <hr className='text-[#cbd5e1]'/>
</div>
<div className='col-span-1'>
  <p className=' pb-2'> Case study</p>
  <h3 className='text-bluecolor hover:underline pb-2'>EDSN and Kapil Technologies provide single solution for managing Dutch energy market data</h3>
  <p>C-ARM processes market meter data for Dutch regional network operators, offering the required flexibility to continue the development of transaction processes.</p>
  <hr className='text-[#cbd5e1]'/>
</div>
<div className='col-span-1'>
  <p className=' pb-2'> Case study</p>
  <h3 className='text-bluecolor hover:underline pb-2'>University of Helsinki uses Kapil Technologies Mobilog to manage real estate services</h3>
  <p>The University of Helsinki’s real estate services organization deployed Kapil Technologies’s Mobilog solution, enabling its 300 mobile workers to view all necessary information on their work….</p>
  <hr className='text-[#cbd5e1]'/>
</div>
<div className='col-span-1'>
  <p className=' pb-2'> Case study</p>
  <h3 className='text-bluecolor hover:underline pb-2'>EDSN and Kapil Technologies provide single solution for managing Dutch energy market data</h3>
  <p>C-ARM processes market meter data for Dutch regional network operators, offering the required flexibility to continue the development of transaction processes.</p>
  <hr className='text-[#cbd5e1]'/>
</div>
<div className='col-span-1'>
  <p className=' pb-2'> Case study</p>
  <h3 className='text-bluecolor hover:underline pb-2'>University of Helsinki uses Kapil Technologies Mobilog to manage real estate services</h3>
  <p>The University of Helsinki’s real estate services organization deployed Kapil Technologies’s Mobilog solution, enabling its 300 mobile workers to view all necessary information on their work….</p>
  <hr className='text-[#cbd5e1]'/>
</div>
<div className='col-span-1'>
  <p className=' pb-2'> Case study</p>
  <h3 className='text-bluecolor hover:underline pb-2'>EDSN and Kapil Technologies provide single solution for managing Dutch energy market data</h3>
  <p>C-ARM processes market meter data for Dutch regional network operators, offering the required flexibility to continue the development of transaction processes.</p>
  <hr className='text-[#cbd5e1]'/>
</div>
<div className='col-span-1'>
  <p className=' pb-2'> Case study</p>
  <h3 className='text-bluecolor hover:underline pb-2'>University of Helsinki uses Kapil Technologies Mobilog to manage real estate services</h3>
  <p>The University of Helsinki’s real estate services organization deployed Kapil Technologies’s Mobilog solution, enabling its 300 mobile workers to view all necessary information on their work….</p>
  <hr className='text-[#cbd5e1]'/>
</div>


</div>
    </section>
    </div>
  )
}

export default Customerstoriesmore