import { useScrollAnimation } from "../../../utils/Utils";

const PointsWithHeading = ({ title, description, features, imageSrc, altText,  reverse=true,  bgColor = '#efefef' }) => {
    useScrollAnimation();
  return (
    <section className="py-10 " style={{ backgroundColor: bgColor }}>
    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10 xl:mx-40 mx-3 md:mx-6">
      <div className={`col-span-1 order-2 ${reverse ? 'lg:order-2' : 'lg:order-1'} animate-up my-auto`}>
        <h2>{title}</h2>
        <p className="text-paracolor">{description}</p>
        <ul className="ps-4">
          {features.map((feature, index) => (
            <li key={index} className="list-disc text-base leading-[1.25]">
              {feature.strongText ? (
                <>
                  <strong className="font-bold text-md">
                    {feature.strongText}
                  </strong>
                  &nbsp;
                </>
              ) : null}
              <span>{feature.text}</span>
            </li>
          ))}
        </ul>
      </div>
      <img
        src={imageSrc}
        alt={altText}
        className={`w-full h-full order-1 ${reverse ? 'lg:order-1' : 'lg:order-2'} animate-up`}
      />
    </div>
  </section>
  )
}

export default PointsWithHeading