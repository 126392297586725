import React from 'react'
import Home_digital_Explain from "../../../Assets/home_digitalexplain.png";
import Data_migration from "../../../Assets/Data_migration.png";
import WareHouse from "../../../Assets/WareHouse (2).png";
import Incident_mangemenet from "../../../Assets/Incident_mangement.png";
import ERP_tool from "../../../Assets/ERP_tool.png";

import { Link, useOutletContext } from 'react-router-dom';
import { useScrollAnimation } from '../../../utils/Utils';

const CustomerStories = () => {
  const { linkClicked } = useOutletContext();
  useScrollAnimation();
  return (
    <div>  
         <section className="bg-[#efefef] ">
    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-7  xl:mx-40 mx-3 md:mx-6 ">
      <div className="col-span-1 xl:my-auto my-5 mx-9 order-2 md:order-1">
        <h3 className="text-[20px] underline bottom-b-2  mb-7 text-darkblue hover:text-[#000]">
          End-to-end services
        </h3>
        <h2 className="text-[38px]  text-bluecolor mb-9 leading-[0.9]">
          {" "}
          Business consulting
        </h2>
        <h3 className="text-[28px]  text-bluecolor mb-5 leading-[0.9]">
          {" "}
          Think boldly and act pragmatically
        </h3>
        <p className="text-[18px] mb-7 text-[#525252] leading-[150%]">
          {" "}
          By connecting strategy to execution and bridging competing
          imperatives, we help clients think boldly and act pragmatically
          through business and strategic IT consulting.
        </p>

        <p className="my-3 group hover:text-darkblue">
          <span className="transition-colors duration-300 group-hover:text-blue-500 text-[18px] font-semibold  leading-[1.5]">
            Forbes names Kapil Technologies as one of 'World Best Management Consulting
            Firms' for 2024
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-7 transition-transform duration-300 group-hover:translate-x-1 inline-block ms-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
            />
          </svg>
        </p>
      </div>
      <div className="col-span-1 flex items-center justify-center order-1 md:order-2 image_design ">
        <img
          src={Home_digital_Explain}
          alt="Business_Consultant"
          className="w-full h-full"
        />
      </div>
    </div>
  </section>

  <section className="py-10 bg-bluecolor">
        <h1 className="ps-10 xl:ps-36 text-white">Latest Client stories</h1>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-5 xl:mx-36 mx-3 md:mx-10">
          <div className="col-span-1 max-w-sm rounded overflow-hidden shadow-lg bg-[#fff] card-container animate-up flex flex-col">
            <div className="imagehover relative">
              <img
                src={Incident_mangemenet}
                alt="Incident_mangemenet"
                className="w-full"
              />
              <div className="overlay absolute inset-0 bg-[#fff] opacity-0 transition-opacity duration-300"></div>
            </div>
            <div className="px-6 py-4 flex-grow flex flex-col">
              <h3 className="pb-[12px] font-bold">
                Managed services in Infor enterprise solutions
              </h3>
              <Link
                className="hover-link transition-colors duration-300 flex-grow"
                to="casestudies"
                onClick={() => linkClicked()}
              >
                <p>
                  An industrial equipment manufacturer in GCC region has put
                  full trust on Kapil Tech for Managed services in Infor
                  enterprise solutions consecutive third year. Even complex
                  Incidents are resolved in record 2 hours timeline.
                </p>
              </Link>
              <p className="mt-5 mb-2 flex items-center gap-1 group hover:text-darkblue">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-7 transition-transform duration-300 group-hover:translate-x-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  />
                </svg>
              </p>
            </div>
          </div>

          {/* Repeat similar structure for other cards */}
          <div className="col-span-1 max-w-sm rounded overflow-hidden shadow-lg bg-[#fff] card-container animate-up flex flex-col">
            <div className="imagehover relative">
              <img src={ERP_tool} alt="ERP_tool" className="w-full" />
              <div className="overlay absolute inset-0 bg-[#000] opacity-0 transition-opacity duration-300"></div>
            </div>
            <div className="px-6 py-4 flex-grow flex flex-col">
              <h3 className="pb-[12px] font-bold">
                Process integration with both Infor and third-party solutions
              </h3>
              <Link
                className="hover-link transition-colors duration-300 flex-grow"
                to="casestudies"
                onClick={() => linkClicked()}
              >
                <p>
                  Extensions are built in the standard ERP feature pack for
                  process integration with both Infor and third-party solutions.
                  Same extensions and integration components worked even after
                  solution upgrades.
                </p>
              </Link>
              <p className="mt-5 mb-2 flex items-center gap-1 group hover:text-darkblue">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-7 transition-transform duration-300 group-hover:translate-x-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  />
                </svg>
              </p>
            </div>
          </div>

          <div className="col-span-1 max-w-sm rounded overflow-hidden shadow-lg bg-[#fff] card-container animate-up flex flex-col">
            <div className="imagehover relative">
              <img
                src={Data_migration}
                alt="Data_migration"
                className="w-full"
              />
              <div className="overlay absolute inset-0 bg-[#000] opacity-0 transition-opacity duration-300"></div>
            </div>
            <div className="px-6 py-4 flex-grow flex flex-col">
              <h3 className="pb-[12px] font-bold">
                Migration and retrofitting around 10 extended functionalities
              </h3>
              <Link
                className="hover-link transition-colors duration-300 flex-grow"
                to="casestudies"
                onClick={() => linkClicked()}
              >
                <p>
                  Successful migration and retrofitting around 10 extended
                  functionalities from older version to a later version of Infor
                  ERP for an Indian enterprise of exclusive business scenarios.
                </p>
              </Link>
              <p className="mt-5 mb-2 flex items-center gap-1 group hover:text-darkblue">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-7 transition-transform duration-300 group-hover:translate-x-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  />
                </svg>
              </p>
            </div>
          </div>

          <div className="col-span-1 max-w-sm rounded overflow-hidden shadow-lg bg-[#fff] card-container animate-up flex flex-col">
            <div className="imagehover relative">
              <img src={WareHouse} alt="WareHouse" className="w-full" />
              <div className="overlay absolute inset-0 bg-[#000] opacity-0 transition-opacity duration-300"></div>
            </div>
            <div className="px-6 py-4 flex-grow flex flex-col">
              <h3 className="pb-[12px] font-bold">
                Warehouse management and Supplier collaboration
              </h3>
              <Link
                className="hover-link transition-colors duration-300 flex-grow"
                to="casestudies"
                onClick={() => linkClicked()}
              >
                <p>
                  Edge solutions for Warehouse management and Supplier
                  collaboration are deployed with custom BOD integrations.
                  Fulfilling Customer objective did lead to longer association
                  for support services.
                </p>
              </Link>
              <p className="mt-5 mb-2 flex items-center gap-1 group hover:text-darkblue">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-7 transition-transform duration-300 group-hover:translate-x-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
      </section>





 


  
  

  <section className="bg-[#fff] py-7">
  <h2 className="pb-5 xl:mx-40 mx-3 md:mx-10 animate-up">Client Stories</h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:mx-40 mx-3 md:mx-10 gap-6">
         
          <div className="flex">
            <img
              src={Home_digital_Explain}
              alt="Podcast cover"
              className="w-full h-full object-cover animate-up"
            />
          </div>
          <div className="flex flex-col justify-between animate-up">
            <div className=" lg:ps-10 my-auto">
              <h2 className="text-[25px] leading-[2rem] text-darkblue mb-[30px]">
                Powering Your Business Transformation With Tomorrow’s Digital
                Solutions
              </h2>
              <p className="text-[16px] text-[#525252] leading-6">
                In the age of fast-paced evolution of technology, businesses
                need to be at the top of their game to stay ahead of the curve.
                As a specialized technology and consulting services provider,
                Kapil Technologies guides enterprises and IT service providers
                to achieve transformational business outcomes through
                exceptional-quality products and services for their customers.
                Our consulting services give enterprises the operational edge by
                empowering them with easy and effective decision-making
                abilities.
              </p>
            </div>
      
          </div>
        </div>
      </section>



  </div>
  )
}

export default CustomerStories