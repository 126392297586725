// import React from "react";
// import Kapil_Technologies_logo from "../../../Assets/Kapil Technlogies Logo.png";
// import { useScrollAnimation } from "../../../utils/Utils";
// import { Link } from "react-router-dom";

// const Footer = ({linkClicked}) => {
  
//   useScrollAnimation();
//   return (
//     <div>
//       {/* Help Section */}
//       <section className="bg-black p-8 text-center">
//         <h1 className="text-[#f5f5f5] text-3xl font-bold animate-up text-center">
//           How can we Help?
//         </h1>
//         <p className="text-[#f5f5f5] my-4 animate-up text-center">
//           If you have any questions or need assistance, please reach out to us.
//         </p>
//         <Link to="/contact"  onClick={()=>{linkClicked()}} className="">
//           <button className="border border-[#fff] rounded-full px-5 py-2 text-white animate-up hover:bg-white hover:text-black transition-colors duration-300">
//             Click Here
//           </button>
//         </Link>
//       </section>

//       {/* Footer Section */}
//       <footer className="bg-[#efefef] h-[300px] ">
//         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 xl:mx-16 mx-3 md:mx-10">
//           {/* Company Info */}
//           {/* <div className="bg-white p-4 w-[250px] ">
//             <img src={Kapil_Technologies_logo} alt="Kapil Technologies Logo" className="w-48 h-auto" />
//             <p className="text-xs leading-relaxed text-[#525252] pt-5">
//               Kapil Technologies India is a forward-thinking technology firm that helps businesses revolutionize the way they operate and engage with customers.
//             </p>
//             <Link to="/insights/aboutus" className="my-2 flex items-center gap-1 group hover:text-darkblue"
//            >
//               <span className="transition-colors duration-300 group-hover:text-blue-500 lg:text-md" onClick={()=>{linkClicked()}}>
//                 Learn About Kapil Technologies
//               </span>
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 strokeWidth="1.5"
//                 stroke="currentColor"
//                 className="w-5 h-5 transition-transform duration-300 group-hover:translate-x-1"
//               >
//                 <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
//               </svg>
//             </Link>
//           </div> */}

//           {/* Company Links */}
//           <div className="p-1">
//             <h2 className="text-lg font-semibold mb-4">Company</h2>
//             <ul>
//               <li className="py-1">
//                 <Link to="/insights/aboutus" className="text-sm hover:text-bluecolor"  onClick={()=>{linkClicked()}}>About Us</Link>
//               </li>
//               <li className="text-sm py-1">Country Sites</li>
//               <li className="text-sm py-1">ESG</li>
//               <li className="text-sm py-1">Investors</li>
//               <li className="text-sm py-1">Newsroom</li>
//               <li className="text-sm py-1">Partner Ecosystem</li>
//             </ul>
//           </div>

//           {/* Articles Section */}
//           <div className="p-1">
//             <h2 className="text-lg font-semibold mb-4">Articles</h2>
//             <ul>
//               <li className="text-sm py-1">Articles</li>
//               <li className="py-1">
//                 <Link to="/insights/blog" className="text-sm hover:text-bluecolor" onClick={()=>{linkClicked()}}>Blog</Link>
//               </li>
//               <li className="py-1">
//                 <Link to="/casestudies" className="text-sm hover:text-bluecolor" onClick={()=>{linkClicked()}}>Case Studies</Link>
//               </li>
//               <li className="text-sm py-1 ">
//                 <Link to="/insights/webinar" className="text-sm hover:text-bluecolor" onClick={()=>{linkClicked()}}>Events</Link>
//               </li>
//               <li className="text-sm py-1">Podcasts</li>
//               <li className="text-sm py-1">White Papers</li>
//             </ul>
//           </div>

//           {/* Support Section */}
//           <div className="p-1">
//             <h2 className="text-lg font-semibold mb-4">Support</h2>
//             <ul>
//               <li className="text-sm py-1">Accessibility</li>
//               <li className="text-sm py-1">Cookie Management</li>
//               <li className="text-sm py-1">Privacy</li>
//               <li className="text-sm py-1">Terms of Use</li>
//               {/* <li className="py-1">
//                 <Link to="/career" className="text-sm hover:text-bluecolor">Careers</Link>
//               </li> */}
//             </ul>
//           </div>

//           {/*Social media*/}
//           <div>
//               <img src={Kapil_Technologies_logo} alt="Kapil Technologies Logo" className="w-48 h-auto" />
//           </div>
//         </div>
//       </footer>
//     </div>
//   );
// };

// export default Footer;


import React from 'react';
import { Link } from 'react-router-dom';
import Kapil_Technologies_logo from "../../../Assets/Kapil Technlogies Logo.png";
import { RiFacebookCircleFill } from "react-icons/ri";
import { RiInstagramFill } from "react-icons/ri";
import { IoLogoLinkedin } from "react-icons/io5";
import { FaSquareXTwitter } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io5";
const Footer = () => {


return (
    <div>
      {/* Help Section */}
      <section className="bg-black p-8 text-center">
        <h1 className="text-white text-3xl font-bold">
          How can we Help?
        </h1>
        <p className="text-white my-4 text-center">
          If you have any questions or need assistance, please reach out to us.
        </p>
        <Link to="/contact">
          <button className="border border-white rounded-full px-5 py-2 text-white hover:bg-white hover:text-black transition-colors duration-300">
            Click Here
          </button>
        </Link>
      </section>

      {/* Footer Section */}
      <footer className="bg-gray-100 py-12 lg:ml-10">
        <div className="container mx-auto px-4 h-[200px]">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-10 lg:gap-x-10">
            {/* Company Links */}
            <div>
              <h2 className="text-lg font-semibold mb-2 text-gray-800">Company</h2>
              <ul className="space-y=2">
                <li className="text-sm text-gray-600 hover:text-blue-500 transition-colors">About Us</li>
                <li className="text-sm text-gray-600">Country Sites</li>
                <li className="text-sm text-gray-600">ESG</li>
              {/* <li className="text-sm text-gray-600">Investors</li> */}
               <li className="text-sm text-gray-600"><Link to="https://kapilgroup.com/" target="_blank" >Investors</Link></li>
                <li className="text-sm text-gray-600">Newsroom</li>
                <li className="text-sm text-gray-600">Partner Ecosystem</li>
              </ul>
            </div>

            {/* Articles Section */}
            <div>
              <h2 className="text-lg font-semibold mb-2 text-gray-800">Articles</h2>
              <ul className="space-y=2">
                <li className="text-sm text-gray-600">Articles</li>
                <li className="text-sm text-gray-600">Blog</li>
                <li className="text-sm text-gray-600">Case Studies</li>
                <li className="text-sm text-gray-600">Events</li>
                <li className="text-sm text-gray-600">Podcasts</li>
                {/* <li className="text-sm text-gray=600">White Papers</li> */}
              </ul>
            </div>

            {/* Support Section */}
            <div>
              <h2 className="text-lg font-semibold mb-2 text-gray-800">Support</h2>
              <ul className="space-y=2">
                <li className="text-sm text-gray=600">Accessibility</li>
                <li className="text-sm text-gray=600">Cookie Management</li>
                <li className="text-sm text-gray=600">Privacy</li>
                <li className="text-sm text-gray=600">Terms of Use</li>
              </ul>
            </div>

            {/* Social Media Section */}
            <div className="flex flex-col items-center justify-center">
              {/* Placeholder for Logo */}
              <div className="w-full h-auto mb-[20px] flex items-center justify-center bg-gray-[300] rounded-md p-[10px]">
              <img src={Kapil_Technologies_logo} alt="logo" className='h-16' />
              </div>

              {/* Social Media Icons */}
              {/* <div className="flex space-x-[20px]">
              <Link ><RiFacebookCircleFill  /></Link>
              <Link><RiInstagramFill /></Link>
              <Link><IoLogoLinkedin /></Link>
              <Link><FaSquareXTwitter /></Link>
              </div> */}
            <div className="flex space-x-5">
  <Link to="https://www.facebook.com/KCS-Technologies-107314782011056/?ref=pages_you_manage" target='_blank' >
    <RiFacebookCircleFill className="text-xl hover:text-blue-500 transition-all duration-300" />
  </Link>
  <Link to="https://www.instagram.com/kapil_technologies_india/" target='_blank'>
    <RiInstagramFill className="text-xl hover:text-pink-500 transition-all duration-300" />
  </Link>
  <Link to="https://in.linkedin.com/company/kapil-technologies-india" target='_blank'>
    <IoLogoLinkedin className="text-xl hover:text-blue-400 transition-all duration-300" />
  </Link>
  <Link to="https://twitter.com/KCSIndiasap" target='_blank'>
    <FaSquareXTwitter className="text-xl hover:text-blue-300 transition-all duration-300" />
   </Link>
    <Link to="http://www.youtube.com/@kapiltechnologies7392" target='_blank'>
    <IoLogoYoutube className="text-xl hover:text-blue-500 transition-all duration-300" />
  </Link>
              
</div>

            </div>

          </div>
        </div>
      </footer>
    </div>
);
};

export default Footer;