import sap_ERP from "../../../Assets/SAP-3_ERP.png";
import sap_quo from "../../../Assets/sap_Quo.png";
import SAP_digital from "../../../Assets/Sap_digital_transfor.png";
import SAP_enterprising from "../../../Assets/Sap_enterprise (2).png";
import Sap_enterprises from "../../../Assets/SAP_Enterprises.png";
import SAP_business_services from "../../../Assets/SAP_Business_services.png";
import { useScrollAnimation } from "../../../utils/Utils";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import Content_GradientLine from "../../component/ReusablaeComponent/Content_GradientLine";
import ListOfLinkSection from "../../component/ReusablaeComponent/ListOfLinkSection";
import HeadingParagraphSection from "../../component/ReusablaeComponent/HeadingParagraphSection";
import sapbanner from '../../../Assets/SAP-page/SAPV.jpg'
import SAPCards from './SAPCards'
import sap1 from '../../../Assets/SAP-page/sap1.jpg'
import sap2 from '../../../Assets/SAP-page/sap2.jpg'
import saplogo from '../../../Assets/SAP-page/sap.png'

const SAP = () => {
  useScrollAnimation();
  const listItems = [
    {
      title: "SAP S/4 HANA ERP Solutions:",
      description:
        "Our deep-running capabilities with SAP’s S/4 HANA ERP solutions platform revitalize your ERP experience. Infrastructure assessment, pilot rollouts, implementation strategies, enhancement of existing solutions, and implementation of brownfield, greenfield, and bluefield (hybrid) SAP S/4 HANA ERP solutions are our forte.",
    },
    {
      title: "SAP Customer Experience Management:",
      description:
        "Our specialized and custom SAP Customer Experience Management Solutions bring multiple business modules viz. Marketing, Distribution, Supply Chain, Sales, CRM, and more on a single platform, allowing enterprises to create seamless and delightful customer experiences.",
    },
    {
      title: "SAP Big Data & Analytics:",
      description:
        "Our proficiency in SAP-driven Big Data and Analytics equips enterprises with accurate insights and statistics that help them shape their business outcomes. Make use of our rich, hands-on experience of working with industry and large object (LoB)-oriented platforms that deliver precise, actionable insights for strategic decision-making.",
    },
    {
      title: "SAP Integrated Business Planning:",
      description:
        "A new age cloud-based enterprise planning tool that lets businesses enhance their outlook with effective management of inventories, supply chains, demand and distribution, and sales and operations.",
    },
    {
      title: "SAP SuccessFactors:",
      description:
        "Support your workforce and address all their challenges and issues with this cloud-based platform. Our expertise with this tool for human capital management lets your enterprise streamline all operations related to human resources.",
    },
  ];
  const ListofLinks = [
    "Digital Business Ideation and Modelling Service",
    "Digital Architecture and Roadmap Design",
    "Landscape Strategy and Architecture",
    "Organizational Change Management",
    "Value Design and Assessment",
  ];
  return (
    <div>
      {/* Header */}
        
     <div className="m-auto">
  <img src={sapbanner} alt="" className="w-full h-auto max-w-full mb-2" />
</div>

     
      <HeaderSectionReusableComponent
        mainHeading={
          "Tailored SAP Consulting Services for Improved Efficiency"
        }
        heading={
          "Optimize Your SAP System for Maximum Efficiency and Seamless Operations"
        }
        paragragh={
          <>
            At Kapil Tech, we offer tailored SAP consulting services to improve efficiency and streamline operations in your manufacturing business. Our experts will work closely with you to optimize your SAP system, ensuring maximum efficiency and meeting your specific needs.
          </>
        }
        imgScr={sap1}
      />
      {/*  Break Free from the Status Quo and Embrace  */}
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading={
          "Unlock Your Business Potential with Expert SAP Solutions"
        }
        paragraph={
          <>
            <div>
               <span className="text-red text-lg font-semibold ">Tailored Support</span>  <br />
Our SAP consulting services offer tailored support to ensure your system operates at its best.
         </div>

            <div>
               <span  className="text-red text-lg font-semibold ">Improved ROI</span> <br />
By leveraging our SAP consulting services, you can achieve improved ROI and business outcomes.
     </div>
          </>
        }
        imgsrc={sap2}
      />
        
      {/* Top-notch SAP Advisory Services That Differentiate Your Enterprise from the Rest*/}

        {/* <div className="flex justify-center items-center mb-4 mt-4"><span className="font-bold text-2xl text-red"> SAP Offerings</span></div>
        <div style={{ background: "#FF0000" }} className="w-24 h-1 mx-auto rounded-full mb-6"></div> */}
         <div className="flex justify-center items-center">
        <img src={saplogo} alt="sapLogo" className="h-20"/>
        <span className="text-xl font-semibold text-bluecolor">Offerings</span>
        
      </div>
      <div style={{ background: "#006BB8" }} className="w-24 h-1 mx-auto rounded-full mb-6"></div>
      
      <SAPCards/>
      
{/*       
      <Image_ContentReusableComponent
        reverse={false}
        bgColor="#fff"
        heading={
          " Top-notch SAP Advisory Services That Differentiate Your Enterprise from the Rest"
        }
        paragraph={
          <>
            Since your enterprise and its business are unique, the software
            solutions it deploys to plan and manage its resources and operations
            must serve as a differentiating factor to keep you ahead of the
            competition. Our top-of-the-line SAP services have you covered with
            advanced technologies and out-of-the-box consulting that work as a
            differentiator to keep you on top of market trends.
          </>
        }
        imgsrc={sap_ERP}
      /> */}

      {/* Our SAP Advisory Services That Always Keep You a Step Ahead of the Market Trends */}

      {/* <ListOfLinkSection
        title="Our SAP Advisory Services That Always Keep You a Step Ahead of the Market Trends"
        ListofLinks={ListofLinks}
      /> */}

      {/* Tailored SAP Solutions That Foster Core Enterprise Transformations*/}

      {/* <Content_GradientLine
        title="Tailored SAP Solutions That Foster Core Enterprise Transformations"
        subHeading=" Stand Out with Result-driven SAP Solutions That Accelerate Your
              Value realization"
        paragraph={
          <>
            Our powerful SAP solutions and services are flexibly customized to
            your business objectives and they help you execute core enterprise
            transformations seamlessly. Leverage them to speed up your journey
            of realizing business goals.
          </>
        }
        listItems={listItems}
        imgSrc={Sap_enterprises}
      /> */}

      {/*    Tap Into Our SAP Knowledge Base That Bring You a Broad Spectrum of Services */}
      {/* <HeadingParagraphSection
        heading={
          "Tap Into Our SAP Knowledge Base That Bring You a Broad Spectrum of Services"
        }
        paragraph={
          <>
            Implementation of top-end SAP solutions and support services is our
            forte. Whether it is working with core ERP applications or the most
            advanced SAP technology platforms, we drive you towards spectacular
            returns on investments (RoIs) when you collaborate with us for
            SAP-related services.
          </>
        }
      /> */}

      {/*Create Value for Your Enterprise with Our Broad Spectrum of Other Services Under SAP Offerings */}
      {/* <Image_ContentReusableComponent
        reverse={false}
        bgColor="#fff"
        heading={
          "Create Value for Your Enterprise with Our Broad Spectrum of Other Services Under SAP Offerings"
        }
        paragraph={
          <>
            Kapil Technologies’ enriching experience with SAP products and
            services acts as a catalyst for faster realization of business goals
            for your enterprise. Our expertise also covers services on
            implementation, upgrade, and migration of platforms across cloud
            technologies. Apart from that, our expertise extends to a wide array
            of SAP service offerings, be it SAP Product Implementation, Product
            Support, Design Thinking, Application Management Services, Digital
            Transformation, or Value Harvesting.
          </>
        }
        imgsrc={SAP_enterprising}
      /> */}

      {/*Achieve Business Excellence with Services That Deliver Unparalleled Value*/}

      {/* <Image_ContentReusableComponent
        heading={
          "Achieve Business Excellence with Services That Deliver Unparalleled Value"
        }
        paragraph={
          <>
            Our consultants realize that every business is unique with unique
            needs. Therefore, they carry out thorough assessments of what your
            business needs to do for faster accomplishment of results. With
            their vast and proven experience, they tailor effective solutions
            that will accelerate your business transformation. You can also bank
            on us for smooth and uninterrupted training and support services
            post implementation of various solutions.
          </>
        }
        imgsrc={SAP_business_services}
      /> */}
    </div>
  );
};

export default SAP;
