import Oracel_consultancy1 from "../../../Assets/Oracel_consultancy (2).png";
import Oracel_services from "../../../Assets/Oracel_service_header.png";
import Oracel_business_compitative from "../../../Assets/Oracle_2_business_compitative.png";
import Oracel_costeffective from "../../../Assets/Cost_Effective.png";
import Oracel_digital_transformation_consultancy from "../../../Assets/Oracel_digital_transformation_consultancy.jpg";
import Oracel_customer_centeric from "../../../Assets/Oracel_customer_centeric.jpg";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import Content_GradientLine from "../../component/ReusablaeComponent/Content_GradientLine";
import ListOfLinkSection from "../../component/ReusablaeComponent/ListOfLinkSection";
import HeadingParagraphSection from "../../component/ReusablaeComponent/HeadingParagraphSection";
const Oracle_ID_Edwards = () => {
  const listItems = [
    {
      title: "Accurate Analytics and Insights:",
      description:
        "Kapil Technologies’ expert consultants help you with precise insights that enhance your business outcomes and the Return-on-Investment (RoI).",
    },
    {
      title: "Enhanced User Experiences:",
      description:
        "Deliver great value to your customers with enhanced user experiences.",
    },
    {
      title: "Smooth System Integrations:",
      description:
        "Accomplish flawless data integration between legacy and Oracle systems.",
    },
    {
      title: "Secure Systems:",
      description:
        "Get robust security for your applications and achieve compliance with data security.",
    },
    {
      title: "Efficient Project Management:",
      description:
        " Achieve top-notch project management by conforming to sound documentation procedures, SLAs, and SLOs.",
    },
    {
      title: "Excellent Customer Experience (CX) Management:",
      description:
        "With our consultants, you will get to deep-dive into strategic analytics that enable you to achieve superior customer experience management.",
    },
  ];

  const ListofLinks = [
    "Oracle Cloud Infrastructure",
    "Oracle NetSuite",
    "Product Lifecycle Management",
    "Oracle DBA",
    "Master Data Management",
    "Oracle ID Edwards",
    "Logistics and Compliance",
    "Fusion Middleware",
    "XaaS (Anything as a Service)",
    "Business Intelligence",
    "E-Business Suite",
    "User Productivity Kit",
    "Supply Chain Optimization",
  ];
  return (
    <div>
      {/* Header */}
      <HeaderSectionReusableComponent
        mainHeading={
          "Transform Your Enterprise With the Precision of Oracle Services"
        }
        heading={"Our Oracle Expertise, Your Success"}
        paragragh={
          <>
            Kapil Technologies’ Oracle expertise gives modern enterprises an
            operational edge with innovative tech solutions that drive holistic
            digital transformations of their businesses. Invest in our know-how
            of Oracle’s latest technology platforms and gain the competitive
            edge with data, analytics, cloud, and more.
          </>
        }
        imgScr={Oracel_services}
      />
      {/*   Your Business Competitiveness Goes Several */}
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading={
          " Your Business Competitiveness Goes Several Notches Higher When You Collaborate With the Right Oracle Services Provider"
        }
        paragraph={
          <>
            Give your enterprise a fillip with cutting-edge tech infrastructure
            and software solutions that give you a peek into precision-backed
            insights and simplify your business processes. With Kapil
            Technologies as your{" "}
            <span className="font-semibold"> Oracle services provider</span>,
            you will create an ecosystem that works as an enabler to help you
            unleash your best business potential.
          </>
        }
        imgsrc={Oracel_business_compitative}
      />
      {/*  Get Cost-effective, Overcome Challenges With Our Oracle Services*/}
      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading={
          "Get Cost-effective, Overcome Challenges With Our Oracle Services Expertise from the Rest"
        }
        paragraph={
          <>
            Enterprises choose us as their Oracle services provider because of
            the tremendous value we create for them by introducing
            cost-effectiveness in their operations and helping them overcome
            challenges with our Oracle expertise. The invaluable and proven
            experience of our seasoned Oracle professionals empowers us to work
            with our clients throughout the lifecycle of their Oracle
            deployments - from implementation to post-sales support.
          </>
        }
        imgsrc={Oracel_costeffective}
      />
      {/* Our Broad Spectrum of Oracle Service Offerings */}
      <ListOfLinkSection
        title="Our Broad Spectrum of Oracle Service Offerings"
        ListofLinks={ListofLinks}
      />

      {/*Our Oracle Consultants Speed Up Your Journey*/}
      <Content_GradientLine
        title="Our Oracle Consultants Speed Up Your Journey of Realizing the Business Objectives"
        paragraph={
          <>
            Our proficiently skilled Oracle consultants keep you on top of
            industry trends with the most impactful insights that let you be
            innovative and maximize your productivity and operational
            efficiency. They augment your growth journey by helping you achieve
            the following:"
          </>
        }
        listItems={listItems}
        imgSrc={Oracel_consultancy1}
      />
      {/*Power Your Competitiveness Across Industries with Industry-focused
            Oracle Solutions*/}
      <HeadingParagraphSection
        heading={
          "Power Your Competitiveness Across Industries with Industry-focused Oracle Solutions"
        }
        paragraph={
          <>
            Kapil Technologies’ expertise with Oracle solutions is
            multi-faceted. Our industry-oriented Oracle solutions cover a wide
            array of industries from manufacturing to consumer goods, retail,
            sales and distribution, financial services, technology, and
            engineering. As a certified Oracle partner, we help enterprises to
            deploy innovative Oracle solutions that speed up your project
            execution apart from modernizing and optimizing all your business
            processes.
          </>
        }
      />
  
      {/*Ride the Wave of Complete Digital Transformation with Leading-edge Oracle Consulting Services*/}
      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading={
          "Ride the Wave of Complete Digital Transformation with Leading-edge Oracle Consulting Services"
        }
        paragraph={
          <>
            Our far-reaching capabilities and proven experiences with Oracle
            services allow us to help enterprises expedite their business
            transformations. With a brilliant combined acumen in business and
            technology, our ace consultants are the enablers of your end-to-end
            digital transformation. They bring you their hands-on expertise with
            Industry-centric Oracle solutions and industry best practices that
            help you stay ahead of the curve.
          </>
        }
        imgsrc={Oracel_digital_transformation_consultancy}
      />

      {/* Operate with a Customer-Centric Approach That is Unwavering */}
      <Image_ContentReusableComponent
        bgColor="#efefef"
        heading={"Operate with a Customer-Centric Approach That is Unwavering"}
        paragraph={
          <>
            Our services are designed to operate with agile methodologies that
            are adaptable to our clients’ dynamic requirements. The scalable
            solutions tailored by our professional consultants give you an edge
            over your industry peers as the cutting-edge offerings allow you to
            operate with superb quality, flexibility, and efficiency. Over time,
            this translates to a tremendously improved way of catering to your
            customers’ specific needs and goals, facilitating enhanced customer
            experiences and returns on investments (RoIs).
          </>
        }
        imgsrc={Oracel_customer_centeric}
      />
    </div>
  );
};

export default Oracle_ID_Edwards;
