import infor_services from "../../../Assets/infor_services.png";
import infor_ERP from "../../../Assets/infor_ERP.png";
import infor_ERP_outcomes from "../../../Assets/ERP_outcomes.png";
import infor_consultancy from "../../../Assets/infor_consultancy.png";
import infor_barries from "../../../Assets/infor_barries.png";
import infor_colud_enterprise from "../../../Assets/Infor_enterprise.png";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import Content_GradientLine from "../../component/ReusablaeComponent/Content_GradientLine";
import ListOfLinkSection from "../../component/ReusablaeComponent/ListOfLinkSection";
import HeadingParagraphSection from "../../component/ReusablaeComponent/HeadingParagraphSection";
import PointsComponent from "../../component/ReusablaeComponent/PointsComponent";
import InforCards from './InforCards'
import INFORLOGO from '../../../Assets/InforPage/Infor.png'
import arrow from '../../../Assets/InforPage/arrow.png'
import migration from '../../../Assets/InforPage/Migration.jpg'
import coustomization from '../../../Assets/InforPage/coustom.jpg'
import Training from '../../../Assets/InforPage/Training.jpg'
import inforbanner from '../../../Assets/InforPage/BInfor2.jpg'
const Infor = () => {
  const listItems = [
    {
      title: "Our Customization Methodology",
      description:
        "Based on the client requirements, we develop customize processes using a systematic software methodology that ensures every project is delivered at highest possible standard.  ",
    },
    {
      title: "What does Customization Invovles ?",
      description:
        "Developing a new module or customizing existing functionality goes beyond creating code. It needs a proper planing to ensure that the designed code meets the customer's requirements.",
    },
    {
      title: "Benefits",
      description:
        `
        Reduce overall cost,Decrease project risk,Ensure Quality delivery,Infor Development Standards compliant,
Ensure on-time project delivery`,
    },
    // {
    //   title: "Infor LN Cloudsuite (Formerly Baan):",
    //   description:
    //     "Our rich Infor LN skillets enable large manufacturers to streamline their production management, finances, logistics, and supply chain management. This platform is precision-driven and comes with high-level integration to give enterprises the capability and flexibility to manage operations at multiple manufacturing sites.",
    // },
  ];

    const listItemsInfor2 = [
    {
      title: "Logicstics & Distribution",
      description:
        "The major challenges of Logistics & Distribution are an evolving global network and changing customer demands. Our experienced professionals provide quick results that optimize service through rapid cost reduction,transformation, operational excellence and network strategy design.",
    },
    {
      title: "Services Delivery Management",
      description:
        "From omnichannel integration to delivery remedy, clients touch points are changing. We work to increase order visibility, returns, manage customer delivery and improve the customer experience.",
    },
    {
      title: "Manufacturing & Operations",
      description:
        `
        From job site to shop floor, we take a lean-focused approach in improving quality, productivity, and efficiency in the field. The ability to effectively and quickly respond to client’s requirements by eliminating waste is utmost to our techniques and tactics.`,
    },
    // {
    //   title: "Infor LN Cloudsuite (Formerly Baan):",
    //   description:
    //     "Our rich Infor LN skillets enable large manufacturers to streamline their production management, finances, logistics, and supply chain management. This platform is precision-driven and comes with high-level integration to give enterprises the capability and flexibility to manage operations at multiple manufacturing sites.",
    // },
  ];
  const ListofLinks = [
    "Baan /Infor ERP LN",
    "Infor CSI (Syteline)",
    "Infor OS",
    "Infor SCM",
    "Infor M3",
    "Infor Cloud Industries",
    "Birst",
    "Infor Supplier Exchange"
  ];
  return (
    <div>
      {/* Header */}
      <div className="m-auto mb-2">
        <img src={inforbanner} alt="" className="w-full h-auto max-w-full" />
          </div>
      <InforCards />
      <div>
        <div className="flex justify-center items-center mb-4"><img src={INFORLOGO} alt="logo" className="h-12 mr-1" /> <span className="font-bold text-lg text-red">Upgrades</span></div>
         <div style={{ background: "#FF0000" }} className="w-24 h-1 mx-auto rounded-full mb-6"></div>
  <HeaderSectionReusableComponent 
        mainHeading={""}
        heading={
          "Kapil Technologies Infor upgrade services aids business to gain the efficiency and flexibility by keeping employees focused on customers and core business."
        }
        paragragh={
          <>
            We help in mitigate risks and save on costs correlated with the upgrade.

We can help in upgrading Infor applications, freeing up valuable IT resources in process so that employees can focus on organization’s core business.
          </>
        }
        imgScr={infor_services}
      />
      </div>
    
      {/*Your Business Competitiveness Goes Several Notches Higher When You Collaborate With the Right Oracle Services Provider*/}
      <div>  
         <Image_ContentReusableComponent
        bgColor="#fff"
        heading={
          "Upgrade Types and services"
        }
        paragraph={
          <>
            <ul>
              <li className="flex  justify-center"> <img src={arrow} alt="arrowLogo" className="h-6"/> Upgrade Infor Applications to make sure that they do not go out of Infor support.</li>
              <li className="flex  justify-center"> <img src={arrow} alt="arrowLogo" className="h-6"/>Take benefit of new modules and functionality available in the updated releases.</li>
              <li className="flex  "> <img src={arrow} alt="arrowLogo" className="h-6"/>Take benefit of reduced IT costs by moving to the cloud.</li>
    </ul>


Kapil Technologies offers an upgrade assessment service as a stand-alone option to ensure a smooth and timely upgrade. <br />
<span className="font-bold">
      Project Management, Technology Consulting, Data Migration / Conversion, Customizations and Integrations, Testing / QA,Data Purging and Archiving</span>, 

          </>
        }
        imgsrc={infor_ERP}
      />
      
      
      </div>
   

      {/* Be driven with outcomes */}
      <div>
        <div className="flex justify-center items-center mb-4 mt-4"><img src={INFORLOGO} alt="logo" className="h-12 mr-1" /> <span className="font-bold text-lg text-red">Migrations</span></div>
        <div style={{ background: "#FF0000" }} className="w-24 h-1 mx-auto rounded-full mb-6"></div>

        <PointsComponent
          title="Minimizing Data Migration Risks & Long Term Success"
      reverse={false}
      description="For successful data migration accuracy is vital, whether it is moving to the cloud or an on-premises solution. Most of the companies won't give much preference to the data migration."
      listItems={[
        'Reduce overall cost',
        'Decrease project risk',
        'Improve data qualitys',
        'Seamless Integration Between Systems and Applications',
        'Ensure on-time project delivery',
      ]}
          imageSrc={migration}
      
      altText="infor_ERP_outcomes"
    />
      
      </div>
    
      {/* KT wide-ranging infor */}
     

      {/*  S Make The Best of Our Infor Cloud Expertise and Reinvent Your
              Enterprise*/}
      
      <div>
         <div className="flex justify-center items-center mb-4 mt-4"><img src={INFORLOGO} alt="logo" className="h-12 mr-1" /> <span className="font-bold text-lg text-red">Customizations</span></div>
        <div style={{ background: "#FF0000" }} className="w-24 h-1 mx-auto rounded-full mb-6"></div>
      </div>
      <Content_GradientLine
        title="How can Kapil Technologies Help ?"
        subHeading=""
        paragraph={
          <>
           At Kapil Technologies we have a large pool of Infor Technical and Functional consultants who have vast in working with Infor Products. <br />
          By Taking advantages of Our Expertise having with Infor Development methodology as well as the internal workings of the many products with in the Infor Suite.
          </>
        }
        listItems={listItems}
        imgSrc={coustomization}
      />

      {/* Streamline Resources, Bootst productivity */}

      
      <div>
         <div className="flex justify-center items-center mb-4 mt-4"><img src={INFORLOGO} alt="logo" className="h-12 mr-1" /> <span className="font-bold text-lg text-red">Management Consulting</span></div>
        <div style={{ background: "#FF0000" }} className="w-24 h-1 mx-auto rounded-full mb-6"></div>
      </div>
 <Content_GradientLine
        title="Integrated Demand & Supply Planning "
        subHeading=""
        paragraph={
          <>
          An integrated demand and supply planning practice is only meaningful when inputs and outputs leads to improve decision-making. Our pragmatic approach directs on supply optimization and demand-driven plans in enhance margins,agility, forecast accuracy, lower inventory and business visibility.
          </>
        }
        listItems={listItemsInfor2}
        imgSrc={infor_colud_enterprise}
      />



      { /* infor */}
     
      {/* <HeadingParagraphSection
        heading={
          "Streamline Resources, Boost Productivity, and Hold the Competitive Edge"
        }
        paragraph={
          <>
            In the age of automation, it is vital to channel your resources to
            more productive tasks. Kapil Technologies empowers you to accomplish
            that by helping you streamline your resources for more productive
            tasks while automating the mundane and repetitive ones. This
            enhances your productivity and helps you hold a competitive edge in
            the industry with efficient business operations.
          </>
        }
      /> */}

      {/* Experience infor consulting */}
  <div>
         <div className="flex justify-center items-center mb-4 mt-4"><img src={INFORLOGO} alt="logo" className="h-12 mr-1" /> <span className="font-bold text-lg text-red"> Training</span></div>
        <div style={{ background: "#FF0000" }} className="w-24 h-1 mx-auto rounded-full mb-6"></div>
      </div>
      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading={
          "Kapil Technologies provide Learning Solutions for each stage of product lifecycle"
        }
        paragraph={
          <>
            Training is important component required to utilize the full value of investment and attain unparalleled advantages for business. Kapil Technologies Training is a critical component needed to utilize the full value of your investment and achieve unparalleled benefits for your business.
          </>
        }
        imgsrc={Training}
      />
     
        <ListOfLinkSection
        title="Learning Solutions for each stage of product lifecycle including:"
        ListofLinks={ListofLinks}
      />
      {/* Break the barriers and explore limitless opportunities with KT */}
      <div className="mt-3 ">
         <div className="flex justify-center items-center mb-4 mt-4"><span className="font-bold text-2xl text-red"> Why Us ?</span></div>
        <div style={{ background: "#FF0000" }} className="w-24 h-1 mx-auto rounded-full mb-6"></div>
      </div>
      {/* <div className="flex justify-between p-10 w-[70rem] m-auto">
        <div className="w-[30rem] border text-center border-gray-100 bg-gray-200">
          <h className="text-red text-lg font-bold text-center">INFOR EXPERTS</h>
          <p>Kapil Technologies is a consulting firm which is 100% focused on Infor. Our extensive expertise in this space, enables to find the best candidate within 10 business days.</p>
        </div>
        <div className="w-[30rem] border text-center" >
          <h2 className="text-red text-lg font-bold">WE SPEAK YOUR LANGUAGE</h2>
          <p>We are knowledgeable about the various versions and modules within the Infor Suite of Applications.</p>
        </div>
      </div> */}
<div className="flex flex-wrap justify-center p-6 space-x-6 space-y-6 w-full max-w-[70rem] mx-auto">
  {/* INFOR EXPERTS Card */}
  <div className="w-full sm:w-[30rem] border text-center border-white bg-gray-100 rounded-lg shadow-lg p-6 transform transition-all duration-300 hover:scale-105 hover:shadow-xl">
    <h3 className="text-red-600 text-xl font-bold mb-4">INFOR EXPERTS</h3>
    <p className="text-gray-700 text-lg leading-relaxed">
      Kapil Technologies is a consulting firm which is 100% focused on Infor. Our extensive expertise in this space enables us to find the best candidate within 10 business days.
    </p>
  </div>

  {/* WE SPEAK YOUR LANGUAGE Card */}
  <div className="w-full sm:w-[30rem] border text-center border-white bg-gray-100 rounded-lg shadow-lg p-6 transform transition-all duration-300 hover:scale-105 hover:shadow-xl">
    <h3 className="text-blue-600 text-xl font-bold mb-4">WE SPEAK YOUR LANGUAGE</h3>
    <p className="text-gray-700 text-lg leading-relaxed">
      We are knowledgeable about the various versions and modules within the Infor Suite of Applications.
    </p>
  </div>
</div>


      {/* <Image_ContentReusableComponent
        heading={
          "Break The Barriers and Explore Limitless Opportunities with Kapil Technologies’ Resourceful Technology and Business Acumen"
        }
        paragraph={
          <>
            No matter what the challenge, our ace consultants possess top-notch
            technology and business acumen to help your enterprise succeed with
            flawless execution of Infor technology deployments. Whether it is
            integration of systems and applications, cloud platform
            implementations and their upgrades and migrations, bespoke and agile
            solutions that cater to your organization, and training and support
            services that equip your workforce with the capability to leverage
            Infor technologies, our consultants will deliver on every front to
            help you realize your business goals faster.
          </>
        }
        imgsrc={infor_barries}
      /> */}
    </div>
  );
};

export default Infor;
