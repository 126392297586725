import { createBrowserRouter, redirect, Link } from "react-router-dom";

// import ErrorBoundary from "../componentLayer/pages/errorPages/ErrorBoundary";
import "../App.css";

import { homeRouter } from "./home/home.router";

import AppLayout from "../componentLayer/pages/layout/AppLayout";

import { serviceRouter } from "./services/service.router";
import { industriesRouter } from "./industries/industries.router";
import { contactRouter } from "./contact/contact.router";
import { CareerRouter } from "./career/career.router";
import { insightsRouter } from "./insights/insights.router";
import { partner_allianzesRouter } from "./partner_allianzes/partner_allianzes.router";

export const router = createBrowserRouter([
  {
    // path: "/",
    element: <AppLayout />,
    children: [
      ...homeRouter,
      {
        path: "services",
        children: [...serviceRouter],
        
      },
      {
        path: "industries",
        children: [...industriesRouter],
      },
      {
        path: "insights",
        children: [...insightsRouter],
      },
      {
        path: "partner_allianzes",
        children: [...partner_allianzesRouter],
      },
      {
        path: "career",
        children: [...CareerRouter],
      },
      {
        path: "contact",
        children: [...contactRouter],
      },
      {
        path: "home", // Add redirect here to handle /home
        loader: () => redirect("/"),
      },
    ],
  },
]);


// export const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <AppLayout   />,
//     // loader: homeLoader,
//     children: [
//       ...homeRouter,
//       {
//         path: "services",
//         children: [...serviceRouter],
//       },
//       {
//         path: "industries",
//         children: [...industriesRouter],
//       },
//       {
//         path: "insights",
//         children: [...insightsRouter],
//       },
//       {
//         path: "partner_allianzes",
//         children: [...partner_allianzesRouter],
//       },
//       {
//         path: "career",
//         children: [...CareerRouter],
//       },
//       {
//         path: "contact",
//         children: [...contactRouter],
//       },
//     ],
//     // ErrorBoundary: ErrorBoundary,
//   },
// ]);
