import infor_services from "../../../Assets/blogimg1.png";
import infor_ERP from "../../../Assets/infor_ERP.png";
import infor_ERP_outcomes from "../../../Assets/ERP_outcomes.png";
import infor_consultancy from "../../../Assets/infor_consultancy.png";
import infor_barries from "../../../Assets/infor_barries.png";
import infor_colud_enterprise from '../../../Assets/Infor_cloud_enterprise.png'
import { useScrollAnimation } from "../../../utils/Utils";

import { Link } from "react-router-dom";

const BusinessConsulting = () => {
  const listItems = [
    {
      title: "Infor Cloudsuite CSI:",
      description:
        "The need for expert integration services in today’s digital world is more urgent than ever. As organizations seek to deliver seamless services that extend from their core systems to modern customer-facing apps, they are bringing in data and functionality from more and more sources, both internal and external.",
    },
    {
      title: "Infor M3 Cloudsuite:",
      description:
        "Additionally, customers and citizens have much greater visibility into the end-to-end supply chain. As a result, organizations must ensure the quality integration of their mission-critical systems, from ensuring accuracy and integrity along the way, to providing robust security and data privacy.",
    },
    {
      title: "Infor XA (Formerly Mapics):",
      description:
        "Join a company rooted in ownership, teamwork, respect and belonging. Be part of our company of owners, where you’ll benefit from the rewards of our collective success, while working side-by-side with colleagues and clients to solve complex issues.",
    },
    {
      title: "Infor LN Cloudsuite (Formerly Baan):",
      description:
        "Our rich Infor LN skill sets enable large manufacturers to streamline production management, finances, logistics, and supply chain management. This platform is precision-driven and comes with high-level integration to give enterprises the capability and flexibility to manage operations at multiple manufacturing sites.",
    },
  ];

  useScrollAnimation();
  return (
    <div>
      {/* Header */}
     
      <section className="bg-[#efefef] ">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-7  xl:mx-40 mx-3 md:mx-6 ">
          <div className="col-span-1 xl:my-auto  py-5 order-2 lg:order-1">
            <h1>Leading-edge Digital Services</h1>
            <h2>
              Use Our Business Excellent Expertise to Your Advantage.  Streamline
              Business Processes Cost-effectively.
            </h2>
            <div className="text-paracolor ">
               Power your business
              transformation with new-age Infor technologies that automate and
              revolutionize the way enterprises operate, with expert consulting
              services by Kapil Technologies’ seasoned consultants.
            </div>
          </div>
          <div className="col-span-1 flex items-center justify-center order-1 lg:order-2 image_design">
            <img
              src={infor_services}
              alt="infor_services"
              className="w-full h-full"
            />
          </div>
        </div>
      </section>
      {/*  Wide-ranging Infor ERP Solutions  */}
      <section className="py-10 bg-[#fff] ">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-7  xl:mx-40 mx-3 md:mx-6">
          <img
            src={infor_consultancy}
            alt="infor_consultancy"
            className="w-full h-full  animate-up"
          />
          <div className="col-span-1 xl:my-auto  animate-up">
            <h2>
            Your Business Competitiveness Goes Several Notches Higher When You Collaborate With the Right Digital Management Provider.
            </h2>
            <p className="text-paracolor">
            While early outsourcing goals focused on lowering costs, executives today seek a trusted partner to also help increase business agility—a competitive differentiator in a digital world.
            </p>
          </div>
        </div>
      </section>
      {/* Be driven with outcomes */}
      <section className="py-10 bg-[#fff] ">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-7  xl:mx-40 mx-3 md:mx-6">
          <div className="col-span-1  order-2 lg:order-1 animate-up">
            <h2 className="">
            Entrusted to Deliver Outcomes:
            </h2>
            <p className=" mb-4  text-paracolor animate-up">
            Clients trust us as a capable and accountable partner. We assume service risks while adjusting to your evolving priorities and continually find ways to improve productivity. We adapt our best-in-class methodologies, tools and processes to your specific environment to meet performance commitments and cost controls.
              <ul className="list-none pt-4">
                <li className="before:content-['\2192'] before:mr-2">
                  Optimum Productivity
                </li>
                <li className="before:content-['\2192'] before:mr-2">
                  Data-driven Decision Making
                </li>
                <li className="before:content-['\2192'] before:mr-2">
                  Smooth Business Transitions
                </li>
                <li className="before:content-['\2192'] before:mr-2">
                  Seamless Integration Between Systems and Applications
                </li>
                <li className="before:content-['\2192'] before:mr-2">
                  Scalable Solutions
                </li>
              </ul>
            </p>
          </div>
          <img
            src={infor_ERP_outcomes}
            alt="infor_ERP_outcomes"
            className="w-full h-full order-1 lg:order-2 animate-up"
          />
        </div>
      </section>
      {/* KT wide-ranging infor */}
      <section className="bg-[#efefef] py-10  ">
        <div className=" xl:mx-40 mx-3 md:mx-10 animate-up">
          <h2 className="text-center pb-3">
            Kapil Technologies’ Wide-Ranging Infor Services Portfolio
          </h2>
          <div className="flex justify-center items-center text-center  ">
            <ul className="inline">
              {[
                "Consulting Services",
                "Cloud Implementation Services",
                "Cloud-based Upgrade and Migration Services",
                "Infrastructure Management Services",
              ].map((text, index) => (
                <li
                  key={index}
                  className="inline-block mx-3 w-auto text-center mb-3"
                >
                  <span className="text-bluecolor border-[#4e8ecb] bg-transparent font-normal inline-block w-auto h-auto py-[3.5px] px-5 align-middle leading-[30px] rounded-full border transition-all duration-200 ease-linear">
                    {text}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      {/*  Streamline Resources,*/}


      
      <section className="bg-[#fff] py-10">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 xl:mx-40 mx-3 md:mx-6 gap-5">
          <div className="col-span-1  animate-up">
            <h2 className="">
            Drive transformation and agility with a partnership approach
            </h2>
            <h3 className="mb-2">
            Our Google Cloud Offerings That Elevate Your Business.
            </h3>
            <p className="text-paracolor">
            Insights are incredibly powerful. They look to space to help sustain life on Earth. They transfer trillions of dollars through secure payments. They power homes with clean energy. They benefit the wellbeing of millions of citizens. They are all around us, but it’s unlocking them that matters.
            </p>

            <img src={infor_colud_enterprise} alt="infor_colud_enterprise" className="animate-up" />
          </div>
          <div className="col-span-1  animate-up  relative pl-14">
            <dl>
              <ul>
                {listItems.map((item, index) => (
                  <li
                    key={index}
                    className="list-disc text-base leading-[1.25] mb-3.5 text-justify"
                  >
                    <strong className="font-semibold text-md">
                      {item.title}
                    </strong>
                    &nbsp;
                    <span>{item.description}</span>
                  </li>
                ))}
              </ul>
            </dl>
          </div>
        </div>
      </section>
      {/* Streamline Resources, Bootst productivity */}
      <section className=" py-10 bg-[#efefef]">
        <div className="xl:mx-40 mx-3 md:mx-6">
          <h2 className=" text-center  animate-up">
            Streamline Resources, Boost Productivity, and Hold the Competitive
            Edge
          </h2>
          <div className="lg:px-36 text-center  animate-up">
            <p className=" pb-4  custom-center">
            Client-proximity model that organizes operations within metro markets giving us deep knowledge of our clients’ businesses and enabling the proactive delivery of innovative services and solutions
            </p>
            <p className=" pb-4 custom-center">
            Global network of onshore, nearshore and offshore delivery centers that support client-proximity teams and provide the best mix of services configured to clients’ needs and preferences
            </p>
          </div>
        </div>
      </section>
      {/* Experience infor consulting */}
      <section className="py-10 bg-[#fff]">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-7  xl:mx-40 mx-3 md:mx-6">
          <div className="col-span-1 order-2 lg:order-1 my-auto animate-up">
            <h2>
              Experience Infor Consulting That’s Impactful and Makes a
              Difference
            </h2>
            <p className=" mb-4">
             
            One of kapil core strengths is our ability to successfully merge cultures, consultants and processes into a single, unified organization through best practices that cover every aspect of the integration life cycle. More than half of our consultants have joined Kapil through mergers, coming together as one to build a global company that continues to grow and succeed.

To ensure the success of our merger strategy, kapil carefully evaluates prospective companies based on defined criteria that closely aligns with our strategic growth objectives.
              
            </p>
          </div>
          <img
            src={infor_consultancy}
            alt="infor_consultancy"
            className="w-full h-full order-1 lg:order-2  animate-up"
          />
        </div>
      </section>

      {/* Break the barriers and explore limitless opportunities with KT */}
      <section className="py-10 bg-[#efefef]">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-7  xl:mx-40 mx-3 md:mx-6">
          <img
            src={infor_barries}
            alt="infor_barries"
            className="w-full h-full  animate-up"
          />
          <div className="col-span-1 my-auto  animate-up">
            <h2 >
              Break The Barriers and Explore Limitless Opportunities with Kapil
              Technologies’ Resourceful Technology and Business Acumen
            </h2>
            <p className="mb-4">
           
                No matter what the challenge, our ace consultants possess
                top-notch technology and business acumen to help your enterprise
                succeed with flawless execution of Infor technology deployments.
                Whether it is integration of systems and applications, cloud
                platform implementations and their upgrades and migrations,
                bespoke and agile solutions that cater to your organization, and
                training and support services that equip your workforce with the
                capability to leverage Infor technologies, our consultants will
                deliver on every front to help you realize your business goals
                faster.
            
            </p>
          </div>
        </div>
    
      </section>
    </div>
  );
};

export default BusinessConsulting;
