import Partner from "../../componentLayer/pages/partner_allianzes/Partner";


export const partner_allianzesRouter = [
  {
    path: "partner",
    element: <Partner />,
  },
  
 
];
