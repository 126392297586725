import { useScrollAnimation } from "../../../utils/Utils";
const Image_ContentReusableComponent = ({ heading, subheading, paragraph, imgsrc, reverse=true, bgColor = '#efefef' }) => {
    useScrollAnimation();
    return (
      <section style={{ backgroundColor: bgColor }} className="py-5 lg:py-10">
        <div className={`grid grid-cols-1 lg:grid-cols-2 xl:mx-36 mx-3 md:mx-10 gap-10 `}>
          {/* Content */}
          <div className={`flex flex-col justify-between ${reverse ? 'lg:order-2' : 'lg:order-1'} order-2 animate-up`}>
            <div className=" my-auto">
              <h2>
                {heading}
              </h2>
              <p className="desc">{subheading}</p>
              <p>
                {paragraph}
              </p>
           
            </div>
          </div>
  
          {/* Image */}
          <div className={`flex ${reverse ? 'lg:order-1' : 'lg:order-2'} order-1 animate-up`}>
            <img
              src={imgsrc}
              alt="imgsrc"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </section>
    );
  };
  
  export default Image_ContentReusableComponent;
  