import Odoo_softwaretool from "../../../Assets/Oddo_ErpSystem.png";
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeadingParagraphSection from "../../component/ReusablaeComponent/HeadingParagraphSection";
import Equipment_Solutions from '../../../Assets/Equipment Solutions.png';
import Superior_Performance from '../../../Assets/Superior Performance.png';
import Equipments from '../../../Assets/Equipments Solutions.png';
import Support_Services from '../../../Assets/Support Services.png'

const Equipment = () => {
  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading="Advanced Equipment Solutions for Every 
Industry"
        paragragh={
          <>
            We offer a comprehensive range of cutting-edge equipment tailored to
            meet the specific needs of various industries. From construction to
            manufacturing, our solutions ensure efficiency, precision, and
            durability.
          </>
        }
        imgScr={Equipment_Solutions}
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading="Precision Tools for Superior Performance"
        paragraph={
          <>
            Our high-precision tools are engineered to meet the highest
            standards of accuracy and efficiency. Whether in automotive,
            electronics, or aerospace industries, our equipment delivers
            consistent results.
          </>
        }
        imgsrc={Superior_Performance}
      />
      <HeadingParagraphSection
        heading="Energy-Efficient Equipment for 
Sustainable Operations
"
        paragraph={
          <>
            With a focus on sustainability, we provide energy-efficient
            equipment that helps reduce operational costs and minimize
            environmental impact. Our innovative designs ensure long-term
            benefits for your business and the planet.
          </>
        }
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading="Customized Equipment Solutions"
        paragraph={
          <>
            We understand that every business has unique requirements. Our
            customized equipment solutions are tailored to meet your specific
            operational needs, ensuring optimized performance and maximum return
            on investment.
          </>
        }
        imgsrc={Equipments}
      />
      <Image_ContentReusableComponent
        heading="Maintenance and Support Services"
        paragraph={
          <>
            <p>
            Our commitment to your success goes beyond the sale. We offer
            comprehensive maintenance and support services to ensure that your
            equipment remains in top working condition, reducing the risk of
            unexpected downtime.
              </p>
           
            These sections can be expanded with specific details about the types
            of equipment you provide and the industries you serve.
          </>
        }
        imgsrc={Support_Services}
      />
    </div>
  );
};

export default Equipment;
