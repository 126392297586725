import casestudies from "../../../Assets/pngimg3.jpg";
import lethbridge from "../../../Assets/lethbridge_3_1.png";
import { useScrollAnimation } from "../../../utils/Utils";

const Blog_inner = () => {
  useScrollAnimation();
  return (
    <div>
      <section className="bg-[#efefef] ">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 xl:mx-40 mx-3 md:mx-6">
          <div className="col-span-1 xl:my-auto my-5 mx-9 order-2 md:order-1">
            <h1 className="">
              Partnering with Beneva to bring its bold plan to life as a digital
              organization, powered by business agility
            </h1>
            <p className=" mb-7 ">5 mins read</p>
          </div>
          <div className="col-span-1 flex items-center justify-center order-1 md:order-2 image_design ">
            <img
              src={casestudies}
              alt="Digital Organization"
              className="w-full h-full"
            />
          </div>
        </div>
      </section>
      <section className="xl:mx-60 mx-3 md:mx-6 py-10">
        <h3 className="pb-6 animate-up">
          Beneva Insurance is pursuing a bold and ambitious plan to make its
          mark as an industry digital leader in today’s dynamic,
          hypercompetitive environment. To do this, Beneva has identified
          business agility as a key strategic lever to efficiently and flexibly
          connect strategy to execution. They have trusted Kapil Technologies to help them
          orchestrate change through a three-year, disciplined agile
          transformation program.
        </h3>
        <p className="pb-6 animate-up">
          The insurance industry's consolidation trend continues with global
          mergers and acquisitions, building on last year’s momentum and rising
          by 23 percent during the first half of 2022 . Digital transformations,
          new forms of risk, and an evolving demand for modern customer services
          and products are redefining the insurance industry and prompting
          companies to meet today’s reality with new business and operating
          models. Speed, flexibility, and agility have become critical for
          insurers to bring products to market faster than their competitors.
        </p>
        <h2 className="animate-up">
          Context – Leverage business agility as a key strategic lever
        </h2>
        <p className="pb-6 animate-up">
          The insurance industry's consolidation trend continues with global
          mergers and acquisitions, building on last year’s momentum and rising
          by 23 percent during the first half of 2022 . Digital transformations,
          new forms of risk, and an evolving demand for modern customer services
          and products are redefining the insurance industry and prompting
          companies to meet today’s reality with new business and operating
          models. Speed, flexibility, and agility have become critical for
          insurers to bring products to market faster than their competitors.
        </p>
        <p className="pb-6 animate-up">
          But evolving to enhance business agility can be very disruptive and
          comes with risks if not carried out carefully. To mitigate these
          risks, Beneva pursued an agile transformation strategy in line with
          their organizational context, conducting changes with an iterative and
          incremental approach, and maximizing learning as they go. It’s a
          complex task, especially when implementing an agile delivery and
          governance model across all business lines at once. The challenge is
          even bigger when transformation occurs while executing a massive
          merger program, creating a new culture and maintaining operational
          excellence in today’s pandemic environment.
        </p>
        <p className="pb-6 animate-up">
          Beneva chose Kapil Technologies, a long-time partner of both SSQ and La Capitale, as
          its strategic partner to ensure a disciplined, well-structured journey
          toward business agility. Seamlessly and flexibly connecting strategy
          to execution, while capitalizing on the full potential of their
          employees, will ultimately position Beneva to deliver the best in
          insurance products and services in today’s fast-changing industry.
        </p>
        <p className="pb-6 animate-up">
          Kapil Technologies's proven experience in the insurance industry, mergers, and
          integrations contexts, agile-lean organizational transformation, and
          the SAFe framework proved to be an ideal fit for Beneva’s highly
          ambitious plan.
        </p>
      </section>

      <section className=" bg-[#efefef] py-10">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10 xl:mx-40 mx-3 md:mx-6  ">
          <div>
            <h2 className="animate-up">
              How we helped - Orchestrating change through a disciplined, agile
              transformation program
            </h2>
            <p className="animate-up">
              With the Beneva leadership team, our strategic advisors co-created
              an agile transformation program called Symphony to orchestrate
              Beneva’s agile journey, bringing together key leaders from across
              the organization (Agile Center of Excellence, Strategic Planning,
              Project Management Office, Enterprise and Solution Architecture
              group, the organizational development team leader, and HR). All
              key functions of the organization are part of Symphony.
            </p>
            <p className="animate-up">
              This program was designed as a three-phase approach over three
              years, beginning with a stabilization phase and followed by an
              acceleration and optimization phase. It includes implementing
              agile portfolio management as a key strategic pivot between the
              corporate strategic planning and the delivery teams, as well as
              the organization of these delivery teams around stable and
              high-performing delivery-value streams. The program also aims to
              enhance efficiency through continuous improvement, supported by
              performance metrics and agile leadership at all levels, using
              Beneva’s target culture as a lever.
            </p>
            <p className="animate-up">
              During the first three months, we helped Beneva develop the
              vision, roadmap, and governance structures needed for successful
              transformation. It was imperative to have senior management
              actively involved in order to ensure full engagement across all
              business lines and key corporate functions. We also collaborated
              closely with the client’s organizational excellence centres to
              make sure Beneva’s way of working was aligned with the same key
              agile principles and a shared vision.
            </p>
            <p className="animate-up">
              With Beneva’s vision, roadmap, and executive sponsorship in place,
              the next step was to stabilize the governance and delivery system
              with proper training, mentoring, and coaching, making sure we
              learned from our initial experiments, and creating a safe learning
              space for everyone. A strong change management and communication
              strategy was paramount to completing this phase and to keeping
              everyone engaged and aligned.
            </p>
          </div>
          <div className="my-auto">
            <img src={lethbridge} alt="lethbridge" className="animate-up" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog_inner;
