import React from 'react'
import HeaderSectionReusableComponent from '../../component/ReusablaeComponent/HeaderSectionReusableComponent' 
import PointsWithHeading from '../../component/ReusablaeComponent/PointsWithHeading';
import PointsComponent from '../../component/ReusablaeComponent/PointsComponent';
import Image_ContentReusableComponent from '../../component/ReusablaeComponent/Image_ContentReusableComponent';
import Testings from '../../../Assets/Testing.png'
import Application_testing from '../../../Assets/Application_testing.png';
import Methodologies from '../../../Assets/Methodologies.png';
import Tools_Technologies from '../../../Assets/Tools_Technologies.png'
import Testing_Processes from '../../../Assets/Testing_Process.png'
import Integrations from '../../../Assets/Integrations.png'

const Testing = () => {
    const Types_testing = [
        {
          strongText: "Unit Testing: ",
          text: "Verifying individual components for correctness.",
        },
        {
          strongText: "Integration Testing:",
          text: "Assessing the interaction between integrated components.",
        },
        {
          strongText: "System Testing:",
          text: "Evaluating the complete system's compliance with specified requirements.",
        },
        {
          strongText: "Acceptance Testing:",
          text: "Validating the system in real-world scenarios to ensure it meets user needs.",
        },
      ];
      const Testing_Methodologies = [
        {
          strongText: "Agile Testing:",
          text: "Continuous testing throughout the development process.",
        },
        {
          strongText: "Automation Testing:",
          text: "Using automated tools to increase efficiency and coverage.",
        },
        {
          strongText: "Performance Testing:",
          text: "Ensuring applications perform under expected workloads.",
        },
        {
          strongText: "Security Testing:",
          text: "Identifying vulnerabilities and ensuring data protection.",
        },
      ];
      const Testing_Process = [
        {
          strongText: "Test Planning:",
          text: "Defining the scope, objectives, and resources.",
        },
        {
          strongText: "Test Design:",
          text: "Creating test cases and scripts based on requirements.",
        },
        {
          strongText: "Test Execution:",
          text: "Running tests and logging results..",
        },
        {
          strongText: "Defect Management:",
          text: "Tracking and resolving identified issues.",
        },
        {
            strongText: "Test Closure:",
            text: "Evaluating testing outcomes and process improvements.",
          },
      ];
  return (
    <div>
             <HeaderSectionReusableComponent
        mainHeading={"Introduction to Testing"}
        paragragh={
          <>
           Testing is a crucial part of the software development lifecycle, ensuring that applications 
function as intended and meet quality standards. This section provides an overview of our 
testing methodologies and their importance in delivering reliable products.

          </>
        }
        imgScr={Testings}
        altText='Testings'
      />
        <PointsWithHeading
        bgColor="#fff"
        title="Types of Testing"
        
        features={Types_testing}
        imageSrc={Application_testing}
        altText="Application_testing"
      />
          <PointsWithHeading
   
        title="Our Testing Methodologies"
        reverse={false}
        features={Testing_Methodologies}
        imageSrc={Methodologies}
        description={'We employ various testing methodologies tailored to our projects, including:'}
        altText="Methodologies"
      />
      
      <PointsComponent
        title="Testing Tools and Technologies"
          description="We utilize a range of industry-standard tools to enhance our testing processes, such as:"
        listItems={[
          "Selenium for automated web testing.",
          "JUnit for unit testing in Java applications.",
          "JMeter for performance testing.",
          "Postman for API testing.",
         
        ]}
        imageSrc={Tools_Technologies}
        altText="Tools_Technologies"
      />
         <PointsWithHeading
        
        title="Testing Process"
        reverse={false}
        features={Testing_Process}
        imageSrc={Testing_Processes}
        description={'Our testing process involves several key steps:'}
        altText="Testing_Processes"
      />
        <Image_ContentReusableComponent
        
        heading={"Continuous Testing and Integration"}
        paragraph={
          <>
           Continuous testing is an integral part of our DevOps approach, ensuring that 
software quality is maintained throughout the development lifecycle. By automating 
tests and integrating them into our continuous integration/continuous deployment 
(CI/CD) pipelines, we provide immediate feedback on code changes. This process 
allows us to identify and resolve issues early, significantly reducing time to market 
while enhancing overall software reliability and performance. Our commitment to 
continuous testing ensures that every release meets the highest quality standards.
          </>
        }
        bgColor='#fff'
        imgsrc={Integrations}
        altText='Integrations'
      />
    </div>
  )
}

export default Testing