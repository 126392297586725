import React from "react";
import HeaderSectionReusableComponent from "../../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import PointsWithHeading from "../../../component/ReusablaeComponent/PointsWithHeading";
import EasyChitsServices from "../../../../Assets/EasyChitsServices.jpg";
import Image_ContentReusableComponent from "../../../component/ReusablaeComponent/Image_ContentReusableComponent";
import PointsComponent from "../../../component/ReusablaeComponent/PointsComponent";
import DevelopmentServices from '../../../../Assets/DevelopmentService.png';
import DevelopmentProcess from '../../../../Assets/DevelopmentProcess.png';
import DevelopmentExpritise from '../../../../Assets/Our Development Expertise.png';
import ApplicationDevelopment from '../../../../Assets/ApplicationDevelopment.png';
import DataIntegration from '../../../../Assets/DataIntegration.png';
import Why_team from '../../../../Assets/Why_OurTeam.png'
const Development = () => {
  const Development_Process = [
    {
      strongText: "Requirements Gathering: ",
      text: "Outline how you collaborate with clients to understand their needs.",
    },
    {
      strongText: "Design and Prototyping: ",
      text: "Describe your approach to creating user-friendly designs and prototypes.",
    },
    {
      strongText: "Development and Testing:",
      text: "Detail your coding practices and testing methodologies to ensure quality.",
    },
    {
      strongText: "Deployment and Maintenance:",
      text: "Explain your process for deploying solutions and providing ongoing support.",
    },
  ];
  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading={"Overview of Development Services"}
        paragragh={
          <>
            At Kapil Technologies, we provide comprehensive development services
            tailored to meet the unique needs of our clients. Our skilled team
            specializes in software and application development, employing
            cutting-edge technologies to deliver robust and scalable solutions.
            With a focus on collaboration and innovation, we ensure that our
            projects not only meet industry standards but also drive significant
            business growth. Partner with us to transform your ideas into
            powerful digital solutions.
          </>
        }
        imgScr={DevelopmentServices}
        altText='DevelopmentServices'
      />
      <PointsWithHeading
        title="Our Development Process"
        bgColor="#fff"
        features={Development_Process}
        imageSrc={DevelopmentProcess}
        altText="DevelopmentProcess"
      />
      <Image_ContentReusableComponent
        
        reverse={false}
        heading={"Our Development Expertise"}
        paragraph={
          <>
            Our development team brings a wealth of experience across various
            domains, ensuring that we deliver solutions that are both effective
            and innovative. We pride ourselves on our ability to understand the
            unique challenges each client faces and provide tailored solutions
            that align with their strategic goals.
          </>
        }
        imgsrc={DevelopmentExpritise}
        altText='DevelopmentExpritise'
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading={"Application Development"}
        paragraph={
          <>
            Whether you need a web, mobile, or desktop application, our
            developers are equipped to bring your vision to life. We focus on
            creating user-friendly, high-performance applications that enhance
            user engagement and improve overall satisfaction. Our expertise
            spans various platforms, ensuring your application performs
            seamlessly across devices.
          </>
        }
        imgsrc={ApplicationDevelopment}
        altText='ApplicationDevelopment'
      />
      <Image_ContentReusableComponent
    
        reverse={false}
        heading={"Integration Services"}
        paragraph={
          <>
            In today’s interconnected world, seamless integration between
            systems is crucial. We provide integration services that connect
            your existing applications, databases, and third-party services,
            allowing for streamlined operations and enhanced data flow. Our goal
            is to create a cohesive ecosystem that improves efficiency and
            supports your business objectives.
          </>
        }
        imgsrc={DataIntegration}
        altText='DataIntegration'
      />
          <PointsComponent
        title="Why Choose Us?"
    
        listItems={[
          "Experienced team",
          "Custom solutions",
          "Agile methodologies",
          "Focus on innovation",
        
        ]}
        imageSrc={Why_team}
        altText="Why_team"
      />
    </div>
  );
};

export default Development;
