import React from 'react'

const ListOfLinkSection = ({title, ListofLinks,  listClass = ''}) => {
  return (
    <section className={`bg-[#efefef] py-10 `}>
    <div className="xl:mx-40 mx-3 md:mx-10 animate-up">
      <h2 className="text-center pb-3">{title}</h2>
      <div className="flex items-center text-center">
        <ul className={`inline ${listClass}`}>
          {ListofLinks.map((ListofLinks, index) => (
            <li
              key={index}
              className="inline-block mx-3 w-auto text-center mb-3"
            >
              <span className="text-bluecolor border-[#4e8ecb] bg-transparent font-normal inline-block w-auto h-auto py-[3.5px] px-5 align-middle leading-[30px] rounded-full border transition-all duration-200 ease-linear">
                {ListofLinks}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </section>
  )
}

export default ListOfLinkSection