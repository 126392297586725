import Odoo_softwaretool from "../../../Assets/Oddo_ErpSystem.png";
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeadingParagraphSection from "../../component/ReusablaeComponent/HeadingParagraphSection";
import Industrial_Manufacturing from '../../../Assets/Industrial Machinery.png';
import Factory_industries from '../../../Assets/Factory and Industry.png';
import Logistics from '../../../Assets/Logistics Optimization.png';
import Quality from '../../../Assets/Quality_control.png'

const IndustrialManufacturing = () => {
  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading="Comprehensive Industrial Manufacturing Solutions"
        paragragh={
          <>
            We offer full-service solutions for industrial manufacturing,
            providing cutting-edge technology and automation systems to enhance
            productivity, reduce costs, and improve product quality. From
            production line optimization to predictive maintenance, we help you
            achieve operational excellence.
          </>
        }
        imgScr={Industrial_Manufacturing}
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading="Smart Factory and Industry 4.0 Integration"
        paragraph={
          <>
            Unlock the potential of smart manufacturing with our Industry 4.0
            solutions. We enable factories to integrate intelligent systems, IoT
            devices, and real-time data analytics to streamline processes,
            improve decision-making, and boost overall efficiency.
          </>
        }
        imgsrc={Factory_industries}
      />
      <HeadingParagraphSection
        heading="Custom Equipment Design and Engineering"
        paragraph={
          <>
            Our expert team specializes in custom equipment design tailored to
            meet the specific needs of your manufacturing operations. Whether
            it’s automation machinery or specialized production tools, we
            deliver robust and efficient equipment that enhances your
            manufacturing capabilities.
          </>
        }
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading="Supply Chain and Logistics Optimization"
        paragraph={
          <>
            Optimize your industrial supply chain with our end-to-end solutions.
            We offer real-time monitoring, efficient inventory management, and
            logistics coordination that reduce bottlenecks and improve delivery
            timelines across your manufacturing network.
          </>
        }
        imgsrc={Logistics}
      />
      <Image_ContentReusableComponent
        heading="Quality Control and Compliance"
        paragraph={
          <>
            <p>We provide advanced quality control systems that help manufacturers
            adhere to the highest industry standards. From precision testing
            equipment to real-time data monitoring, our solutions ensure your
            products consistently meet compliance and quality requirements.
            </p>
            These headings and descriptions will help convey the range of
            services and solutions your company offers in the industrial
            manufacturing and equipment sector.
          </>
        }
        imgsrc={Quality}
      />
    </div>
  );
};

export default IndustrialManufacturing;
