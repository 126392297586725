import Finance_Services from "../../../../Assets/Finance_Services.png";
import infor_ERP from "../../../../Assets/infor_ERP.png";
import Home_digital_Explain from "../../../../Assets/home_digitalexplain.png";
import Image_ContentReusableComponent from "../../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeaderSectionReusableComponent from "../../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import Business_substaine from "../../../../Assets/Busines_Substaine.png";
import Content_GradientLine from "../../../component/ReusablaeComponent/Content_GradientLine";
import HeadingParagraphSection from "../../../component/ReusablaeComponent/HeadingParagraphSection";
import { useState,useEffect } from "react";

const Finsta = () => {
  
  useEffect(() => {
    // Open the new tab with the specified URL
    window.open('https://finsta.in', '_blank');

    // Redirect to the main page
    window.location.href = '/';
  }, []);

  const listItems = [
    {
      description:
        "Process Automation: Finsta automates mundane business processes and eliminates paperwork, introducing efficiency and minimizing human errors.",
    },
    {
      description:
        "e-KYC: Allows you to perform online KYC for your customers, reducing loan processing times.",
    },
    {
      description:
        "Smart MIS dashboards and Data Analytics: Helps you leverage informative and accurate insights for strategic decision-making.",
    },
    {
      description:
        "Dynamic Verification of Credit Scores: Lets you check borrowers’ creditworthiness online, in a real time environment.",
    },
    {
      description:
        "Fast-tracks Loan Approvals and Disbursals With Proper Schedules: Facilitates multi-stage loan approval and disbursal with seamless and process-driven adherence to schedules.",
    },
    {
      description:
        "Simplified Loan Servicing: Makes collection of loan repayments easy for your Associates with reliable and actionable data on mobile, hand-held devices.",
    },
    {
      description:
        "In-Depth Accounting and Auditing: Allows robust and seamless accounting and auditing.",
    },
    {
      description:
        "Data Security and Compliance: Prioritizes data security with compliance to latest standards of data encryption.",
    },
  ];

  const [formData, setFormData] = useState({
    fullname: "",
    company: "",
    workemail: "",
    country: "",
    countrycode: "",
    mobilenumber: "",
    nicheindustry: "",
   
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    // Validate the input field while typing
    validateField(name, value, type === "checkbox" ? checked : value);
  };

  // Field validation for live error checking
  const validateField = (name, value, checked) => {
    let error = "";

    switch (name) {
      case "fullname":
        if (!value) error = "Full Name is required";
        break;

      case "company":
        if (!value) error = "Company Name is required";
        break;

      case "workemail":
        if (!value) {
          error = "Work Email is required";
        } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          error = "Email address is invalid";
        }
        break;

      case "country":
        if (!value) error = "Country is required";
        break;

      case "countrycode":
        if (!value) error = "Country Code is required";
        break;

      case "mobilenumber":
        if (!value) {
          error = "Mobile Number is required";
        } else if (!/^\d+$/.test(value)) {
          error = "Mobile Number must contain only digits";
        } else if (value.length !== 10) {
          error = "Mobile Number must be exactly 10 digits";
        }
        break;

      case "nicheindustry":
        if (!value) error = "Niche Industry is required";
        break;

      case "about":
        if (!value) error = "Please tell us how we can help you";
        break;

      case "agreement":
        if (!checked) error = "You must accept the agreement";
        break;

      default:
        break;
    }

    setErrors((prevErrors) => {
      if (error) {
        return { ...prevErrors, [name]: error }; // Set error if validation fails
      } else {
        const { [name]: removedError, ...rest } = prevErrors; // Remove error if validation passes
        return rest;
      }
    });
  };

  // Validation for all fields on submit
  const validate = () => {
    let formErrors = {};

    // Full Name Validation
    if (!formData.fullname) formErrors.fullname = "Full Name is required";

    // Company Validation
    if (!formData.company) formErrors.company = "Company Name is required";

    // Work Email Validation
    if (!formData.workemail) {
      formErrors.workemail = "Work Email is required";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.workemail)) {
      formErrors.workemail = "Email address is invalid";
    }

    // Country Validation
    if (!formData.country) formErrors.country = "Country is required";

    // Country Code Validation
    if (!formData.countrycode)
      formErrors.countrycode = "Country Code is required";

    // Mobile Number Validation
    if (!formData.mobilenumber) {
      formErrors.mobilenumber = "Mobile Number is required";
    } else if (!/^\d+$/.test(formData.mobilenumber)) {
      formErrors.mobilenumber = "Mobile Number must contain only digits";
    } else if (formData.mobilenumber.length !== 10) {
      formErrors.mobilenumber = "Mobile Number must be exactly 10 digits";
    }

    // Niche Industry Validation
    if (!formData.nicheindustry)
      formErrors.nicheindustry = "Niche Industry is required";

    // About Validation
    if (!formData.about)
      formErrors.about = "Please tell us how we can help you";

    // Agreement Validation
    if (!formData.agreement)
      formErrors.agreement = "You must accept the agreement";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // Form submission logic
      console.log("Form data submitted:", formData);
    } else {
      console.log("Validation errors:", errors);
    }
  };

  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading={
          "Power Your Financial Services With Finsta - The Next Generation Fintech Software"
        }
        heading={
          "Give Your Financial Services a Shot in the Arm With This Top Class Product from Kapil IT - A Tech Powerhouse Among the Most Innovative Fintech Software Companies"
        }
        paragragh={
          <>
            Extend and manage loans, help your customers open savings accounts,
            empower them to manage their finances online, facilitate e-KYC, and
            enable faster fund clearances, all with the excellence of Finsta -
            the most advanced{" "}
            <span className="font-semibold">fintech software </span> with a 3600
            agility - a product of Kapil IT, a leading{" "}
            <span className="font-semibold"> fintech software company.</span>
          </>
        }
        imgScr={Finance_Services}
      />
      {/*A Comprehensive Fintech Software Built for a Multitude of
              Financial Institutions  */}

      <Image_ContentReusableComponent
        bgColor="#fff"
        heading={
          "A Comprehensive Fintech Software Built for a Multitude of Financial Institutions"
        }
        paragraph={
          <>
            Whether you are an NBFC, a Nidhi company, a Credit Cooperative
            Society, a Small Finance Bank, a Primary Agricultural Cooperative
            Society, a Regional Rural Bank, a District Credit Cooperative Bank,
            or a Microfinance Institution, Finsta lets you streamline all your
            business operations and achieve outstanding standards of management
            in customer experience.
            <span className="block mt-3">
              {" "}
              At the core of this product’s excellence is its superior loan
              management system that robustly supports the entire loan
              management lifecycle - from loan origination to its servicing.
            </span>{" "}
          </>
        }
        imgsrc={infor_ERP}
      />
      {/* Customized Fintech Solutions That Let You Operate With Top Flexibility  */}
      <HeadingParagraphSection
        heading={
          "Customized Fintech Solutions That Let You Operate With Top Flexibility"
        }
        paragraph={
          <>
            Team Finsta is a firm believer of the adage - One Size DOES NOT Fit
            All. Therefore, we are here to tailor effective solutions that work
            for your specific business needs. So specify your operational
            requirements to our experts and have us implement the perfect custom
            solutions for you on demand. With Finsta, you will expedite your
            service delivery timelines to your customers and achieve top-notch
            reputation with solutions that enable customer-centric approaches.
          </>
        }
      />

      {/* Organize Workflows, Make Your Business Sustainable With Power-packed Features */}

      <Content_GradientLine
        title=" Organize Workflows, Make Your Business Sustainable With Power-packed Features"
        subHeading=" Finsta Goes Beyond Just Letting You Deliver Various Modules Under Your Specific Financial Services. Its Superior Features Automate Mundane Processes and Help You Extract Maximum Productivity."
        paragraph={<>A walk through the software’s cutting-edge features:</>}
        listItems={listItems}
        imgSrc={Business_substaine}
      />

      {/*  A Robust Fintech Software That Caters to a Broad Spectrum of Financial Institutions */}
      <section className=" py-10 bg-[#efefef] ">
        <div className="xl:mx-40 mx-3 md:mx-6">
          <h2 className=" text-center  animate-up">
            A Robust Fintech Software That Caters to a Broad Spectrum of
            Financial Institutions
          </h2>
          <p className="mb-4  text-center  animate-up">
            We cater to the following financial institutions:
          </p>
          <div className="lg:px-36 animate-up">
            <div className="overflow-x-auto border border-black rounded-lg">
              <table className="min-w-full table-auto  shadow-md rounded-lg overflow-hidden border border-gray-300">
                <tbody>
                  <tr className="bg-gray-100">
                    <td className="px-6 py-4 border border-gray-300">
                      Non-Deposit taking Non-Banking Financial Company (NBFC-ND)
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      Producer companies
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      DCCB (District Credit Cooperative Bank)
                    </td>
                  </tr>
                  <tr className="bg-gray-100">
                    <td className="px-6 py-4 border border-gray-300">
                      Deposit-taking Non-Banking Financial Company [NBFC-D]
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      Credit Cooperative Societies / Mutually Aided Cooperative
                      Societies
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      RRB's (Regional Rural Banks)
                    </td>
                  </tr>
                  <tr className="bg-gray-100">
                    <td className="px-6 py-4 border border-gray-300">
                      Microfinance Institutions
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      Small Banks
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      Credit Unions, PACS (Primary Agricultural Credit
                      Societies)
                    </td>
                  </tr>
                  <tr className="bg-gray-100">
                    <td className="px-6 py-4 border border-gray-300">
                      Nidhi companies
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      Cooperative Banks
                    </td>
                    <td className="px-6 py-4 border border-gray-300"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white py-10 ">
        <div className="xl:mx-40 mx-3 md:mx-6">
          <h2 className="text-center">
            Be the Best in Your Segment With The Power of Finsta, Today.
          </h2>

          <form onSubmit={handleSubmit} className="">
            {/* Form Fields */}
            <div className="grid md:grid-cols-2 gap-6 mt-5">
              <div className="w-full">
                <input
                  type="text"
                  name="fullname"
                  id="name"
                  value={formData.fullname}
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  placeholder="Full Name"
                  onChange={handleChange}
                />

                <div className="h-3">
                  {errors.fullname && (
                    <span className="text-xs text-red ">{errors.fullname}</span>
                  )}
                </div>
              </div>
              <div className="w-full">
                <input
                  type="text"
                  name="company"
                  id="company"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  placeholder="Company Name"
                  value={formData.company}
                  onChange={handleChange}
                />

                <div className="h-3">
                  {errors.company && (
                    <span className="text-xs text-red ">{errors.company}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-6 mt-5">
              <div className="w-full">
                <input
                  type="email"
                  id="email"
                  name="workemail"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  value={formData.workemail}
                  placeholder="Work Email"
                  onChange={handleChange}
                />
                <div className="h-3">
                  {errors.workemail && (
                    <span className="text-xs text-red ">
                      {errors.workemail}
                    </span>
                  )}
                </div>
              </div>
              <div className="w-full">
                <select
                  id="country"
                  name="country"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  value={formData.country}
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    Country
                  </option>
                  <option>Canada</option>
                  <option>France</option>
                  <option>Germany</option>
                  {/* Add more countries as needed */}
                </select>
                <div className="h-3">
                  {errors.country && (
                    <span className="text-xs text-red ">{errors.country}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-6 mt-5">
              <div className="w-full">
                <select
                  id="countrycode"
                  name="countrycode"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  value={formData.countrycode}
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    Country Code
                  </option>
                  <option>435345</option>
                  <option>345345345</option>
                  <option>34534534543</option>
                  {/* Add more countries as needed */}
                </select>
                <div className="h-3">
                  {errors.countrycode && (
                    <span className="text-xs text-red ">
                      {errors.countrycode}
                    </span>
                  )}
                </div>
              </div>
              <div className="w-full">
                <input
                  type="number"
                  name="mobilenumber"
                  id="mobile-number"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  value={formData.mobilenumber}
                  placeholder="Mobile"
                  onChange={handleChange}
                />
                <div className="h-3">
                  {errors.mobilenumber && (
                    <span className="text-xs text-red ">
                      {errors.mobilenumber}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-6 mt-5 ">
              <div className="w-full mb-2">
                <input
                  type="text"
                  id="nicheindustry"
                  name="nicheindustry"
                  className="contact_form w-full p-3 rounded-md shadow-sm border"
                  placeholder="Niche Industry"
                  value={formData.nicheindustry}
                  onChange={handleChange}
                />
                <div className="">
                  {errors.nicheindustry && (
                    <span className="text-xs text-red ">
                      {errors.nicheindustry}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="border  bg-[#4e8ecb] rounded-md  px-4 text-[#fff] mb-2 "
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Finsta;
