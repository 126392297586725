import React, { useState } from "react";
import event_organization from "../../../Assets/event_profile.png";
import event_organization1 from "../../../Assets/event_profile1.png";
import event_organization2 from "../../../Assets/event-profile2.png";
import { useScrollAnimation } from "../../../utils/Utils";

const Events_inner = () => {
  const [activeLink, setActiveLink] = useState("learningsection");
  console.log("activelink", activeLink);
  const handleNavLinkClick = (link) => {
    setActiveLink(link);
  };

  useScrollAnimation();
  return (
    <div>
      <section className="event_main ">
        <h2 className="text-white"> Meet Kapil Technologies@ 2024 events</h2>
        <h3 className="text-white">
          September 2 – 12, 2024 |Kapil Project launch
        </h3>
      </section>
      {/* attending form */}
      <section className="py-10 bg-[#fff]">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10  xl:mx-40 mx-3 md:mx-6">
          <div className="col-span-1">
            <h1> Kapil Technologies</h1>
            <h2> September 2 – 12, 2024 </h2>
            <h3 className="mb-4"> Join Kapil Technologies event</h3>
            <p>
              Transform your business with Oracle Cloud applications, trust the
              experts. Kapil Technologies is delighted to return to Oracle CloudWorld.
            </p>
            <p>
              Kapil Technologies's Oracle Cloud Services are designed to help enterprises
              modernize, innovate, and transform the way they do their business
              in the always on, real-time, connected, digital world.
            </p>
            <p>
              Meet our experts to understand how you can leverage Cloud-powered
              innovation to modernize your core enterprise systems and adopt an
              outcome-based Cloud transformation to create industry defining
              experience.
            </p>
          </div>
          <div className="col-span-1 shadow-lg p-8 bg-[#fff] rounded-md lg:mx-16">
            <h2 className="text-darkblue text-2xl font-bold mb-2">
              Meet Our Experts at CloudWorld
            </h2>
            <h3 className="text-lg text-gray-700 mb-4">
              Schedule Your Meeting for CloudWorld’24
            </h3>
            <form>
              {/* Form Fields */}

              <div className="w-full ">
                <input
                  type="text"
                  name="fullname"
                  id="name"
                  className="contact_form w-full p-3 rounded-md shadow-sm border mb-5"
                  placeholder="Full Name"
                />
              </div>
              <div className="w-full">
                <input
                  type="tel"
                  name="number"
                  id="number"
                  className="contact_form w-full p-3 rounded-md shadow-sm border mb-5"
                  placeholder="Mobile Number"
                />
              </div>
              <div className="w-full">
                <input
                  type="email"
                  id="email"
                  name="workemail"
                  className="contact_form w-full p-3 rounded-md shadow-sm border mb-5"
                  placeholder="Work Email"
                />
              </div>
              <div className="w-full ">
                <input
                  type="text"
                  name="fullname"
                  id="name"
                  className="contact_form w-full p-3 rounded-md shadow-sm border mb-5"
                  placeholder="Full Name"
                />
              </div>
              <div className="flex items-center ">
                <input
                  id="agreement"
                  type="checkbox"
                  name="agreement"
                  className="w-4 h-4 text-blue-600 bg-gray-200 border-gray-300 rounded focus:ring-blue-500"
                />
                <label
                  htmlFor="agreement"
                  className="ml-2 text-sm font-medium text-gray-900"
                >
                  By submitting this form, you acknowledge that Kapil
                  Technologies may use your personal information for marketing
                  communications as outlined in its privacy policy.
                  <span className="text-red">*</span>
                </label>
              </div>

              <button
                type="submit"
                className="border mt-7 bg-[#4e8ecb] rounded-md py-2 px-4 text-[#fff] mb-2 "
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
      {/* subjects */}
      <section className="py-10 bg-[#efefef]">
        <div className=" xl:mx-40 mx-3 md:mx-6">
          <ul className="flex flex-wrap   font-medium text-center  ">
            <li
              onClick={() => handleNavLinkClick("learningsection")}
              className={`cursor-pointer w-full sm:w-1/6 font-[500]`}
            >
              <div className="flex  items-center">
                <span
                  className={`text-[14px] py-2 px-2 w-full ${
                    activeLink === "learningsection"
                      ? "border-x border-x-[#d1d5db] border-t border-t-[#d1d5db] text-orange-600 rounded-t-md bg-white text-bluecolor"
                      : "text-[#333]"
                  }`}
                >
                  LEARNING SECTION
                </span>
              </div>
            </li>

            <li
              onClick={() => handleNavLinkClick("theatersection")}
              className={`cursor-pointer w-full sm:w-1/6 font-[500]`}
            >
              <div className="flex  items-center">
                <span
                  className={`text-[14px] py-2 px-2 w-full ${
                    activeLink === "theatersection"
                      ? "border-x border-x-[#d1d5db] border-t border-t-[#d1d5db] text-orange-600 rounded-t-md bg-white text-bluecolor "
                      : "text-[#333]"
                  }`}
                >
                  THEATER SECTION [b556]
                </span>
              </div>
            </li>

            <li
              onClick={() => handleNavLinkClick("theatersections")}
              className={`cursor-pointer w-full sm:w-1/6 font-[500]`}
            >
              <div className="flex  items-center">
                <span
                  className={`text-[14px] py-2 px-2 w-full ${
                    activeLink === "theatersections"
                      ? "border-x border-x-[#d1d5db] border-t border-t-[#d1d5db] text-orange-600 rounded-t-md bg-white text-bluecolor"
                      : "text-[#333]"
                  }`}
                >
                  THEATER SECTION [bd556]
                </span>
              </div>
            </li>
          </ul>
        </div>

        {/* Office Address Content Based on Active Link */}
        {activeLink === "learningsection" && (
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-x-7 xl:mx-40 mx-3 md:mx-6 py-8 px-8 bg-white divide-x divide-[#cbd5e1]">
            <div className="col-span-1">
              <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2  gap-x-2 items-center mb-6">
                <img src={event_organization2} alt="event_organization2" />
                <div>
                  <h5 className="font-semibold">Krishna Muthuperumal</h5>
                  <p className="text-[#6b7280]">
                    Sr. Director – Enterprise Applications, Martin Marietta
                  </p>
                </div>
              </div>

              <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2  gap-x-2 items-center mb-6">
                <img src={event_organization2} alt="event_organization2" />
                <div>
                  <h5 className="font-semibold">Krishna Muthuperumal</h5>
                  <p className="text-[#6b7280]">
                    Sr. Director – Enterprise Applications, Martin Marietta
                  </p>
                </div>
              </div>
            </div>
            <div className="col-span-2 ps-7">
              <h3 className="desc">
                From Silos to Synergy: Oracle Sourcing Cloud and OIC Integration
                Success Story
              </h3>
              <h5 className="text-bluecolor mb-2">
                Wednesday, Sep 11 | 1:00 – 1:45 pm PDT | Bellini 2104, The
                Venetian, Level 2
              </h5>
              <p>
                At Martin Marietta, implementing Oracle Sourcing Cloud and
                Contracts Cloud using Oracle Integration Cloud (OIC) helped
                streamline operations, enhance business agility, and improve
                user experiences. Buyers were able to leverage negotiation best
                practices to drive strategic savings initiatives and achieve
                bottom-line results. More effective sourcing was achieved by
                combining the specialized skills of key buyers and adding
                stakeholders’ subject-matter experts by leveraging their
                expertise and gathering feedback via online collaboration.
                Implementing a single procurement platform of Oracle Cloud and
                OIC helped achieve lower TCO—and robust security and governance
                were additional benefits.
              </p>
            </div>
          </div>
        )}

        {activeLink === "theatersection" && (
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-x-7 xl:mx-40 mx-3 md:mx-6 py-8 px-8 bg-white divide-x divide-[#cbd5e1]">
            <div className="col-span-1">
              <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2  gap-x-2 items-center mb-6">
                <img src={event_organization2} alt="event_organization2" />
                <div>
                  <h5 className="font-semibold">Krishna Muthuperumal</h5>
                  <p>Sr. Director – Enterprise Applications, Martin Marietta</p>
                </div>
              </div>

              <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2  gap-x-2 items-center mb-6">
                <img src={event_organization2} alt="event_organization2" />
                <div>
                  <h5 className="font-semibold">Krishna Muthuperumal</h5>
                  <p>Sr. Director – Enterprise Applications, Martin Marietta</p>
                </div>
              </div>
            </div>
            <div className="col-span-2 ps-7">
              <h3 className="desc">
                From Silos to Synergy: Oracle Sourcing Cloud and OIC Integration
                Success Story
              </h3>
              <h5 className="text-bluecolor mb-2">
                Wednesday, Sep 11 | 1:00 – 1:45 pm PDT | Bellini 2104, The
                Venetian, Level 2
              </h5>
              <p>
                At Martin Marietta, implementing Oracle Sourcing Cloud and
                Contracts Cloud using Oracle Integration Cloud (OIC) helped
                streamline operations, enhance business agility, and improve
                user experiences. Buyers were able to leverage negotiation best
                practices to drive strategic savings initiatives and achieve
                bottom-line results. More effective sourcing was achieved by
                combining the specialized skills of key buyers and adding
                stakeholders’ subject-matter experts by leveraging their
                expertise and gathering feedback via online collaboration.
                Implementing a single procurement platform of Oracle Cloud and
                OIC helped achieve lower TCO—and robust security and governance
                were additional benefits.
              </p>
            </div>
          </div>
        )}

        {activeLink === "theatersections" && (
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-x-7 xl:mx-40 mx-3 md:mx-6 py-8 px-8 bg-white divide-x divide-[#cbd5e1]">
            <div className="col-span-1">
              <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2  gap-x-2 items-center mb-6">
                <img src={event_organization2} alt="event_organization2" />
                <div>
                  <h5 className="font-semibold">Krishna Muthuperumal</h5>
                  <p>Sr. Director – Enterprise Applications, Martin Marietta</p>
                </div>
              </div>

              <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2  gap-x-2 items-center mb-6">
                <img src={event_organization2} alt="event_organization2" />
                <div>
                  <h5 className="font-semibold">Krishna Muthuperumal</h5>
                  <p>Sr. Director – Enterprise Applications, Martin Marietta</p>
                </div>
              </div>
            </div>
            <div className="col-span-2 ps-7">
              <h3 className="desc">
                From Silos to Synergy: Oracle Sourcing Cloud and OIC Integration
                Success Story
              </h3>
              <h5 className="text-bluecolor mb-2">
                Wednesday, Sep 11 | 1:00 – 1:45 pm PDT | Bellini 2104, The
                Venetian, Level 2
              </h5>
              <p>
                At Martin Marietta, implementing Oracle Sourcing Cloud and
                Contracts Cloud using Oracle Integration Cloud (OIC) helped
                streamline operations, enhance business agility, and improve
                user experiences. Buyers were able to leverage negotiation best
                practices to drive strategic savings initiatives and achieve
                bottom-line results. More effective sourcing was achieved by
                combining the specialized skills of key buyers and adding
                stakeholders’ subject-matter experts by leveraging their
                expertise and gathering feedback via online collaboration.
                Implementing a single procurement platform of Oracle Cloud and
                OIC helped achieve lower TCO—and robust security and governance
                were additional benefits.
              </p>
            </div>
          </div>
        )}
      </section>

      {/* oraganizers */}
      <section className="py-10 bg-[#fff]">
        <div className="text-center pb-5">
          <h2>Planning on attending the Oracle Cloud World 2024?</h2>
          <h3> Schedule time with our experts.</h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-10  xl:mx-40 mx-3 md:mx-6">
          <div className="text-center text-[#4d4d4f] text-base">
            <img
              src={event_organization}
              alt="event_organization"
              className="mx-auto"
            />
            <h5 className=" py-2 font-semibold">Ravi Purohit</h5>
            <em>
              Sr. VP & Global Head - ERP Business,
              <br />
              Kapil Technologies
            </em>
          </div>
          <div className="text-center text-[#4d4d4f] text-base">
            <img
              src={event_organization1}
              alt="event_organization"
              className="mx-auto"
            />
            <h5 className=" py-2 font-semibold">Uday Kumar</h5>
            <em>
              VP and Global Business Head - Oracle Cloud & Enterprise Solutions
              Kapil Technologies
            </em>
          </div>
          <div className="text-center text-[#4d4d4f] text-base">
            <img
              src={event_organization2}
              alt="event_organization"
              className="mx-auto"
            />
            <h5 className=" py-2 font-semibold">Viral Doshi</h5>
            <em>AVP and Global Practice Head - Oracle JD Edwards, Kapil Technologies</em>
          </div>
          <div className="text-center text-[#4d4d4f] text-base">
            <img
              src={event_organization2}
              alt="event_organization"
              className="mx-auto"
            />
            <h5 className=" py-2 font-semibold">Ravi Purohit</h5>
            <em>Sr. VP & Global Head - ERP Business, Kapil Technologies</em>
          </div>
          <div className="text-center text-[#4d4d4f] text-base">
            <img
              src={event_organization1}
              alt="event_organization"
              className="mx-auto"
            />
            <h5 className=" py-2 font-semibold">Ravi Purohit</h5>
            <em>Sr. VP & Global Head - ERP Business, Kapil Technologies</em>
          </div>
          <div className="text-center text-[#4d4d4f] text-base">
            <img
              src={event_organization}
              alt="event_organization"
              className="mx-auto"
            />
            <h5 className=" py-2 font-semibold">Ravi Purohit</h5>
            <em>Sr. VP & Global Head - ERP Business, Kapil Technologies</em>
          </div>
          <div className="text-center text-[#4d4d4f] text-base">
            <img
              src={event_organization}
              alt="event_organization"
              className="mx-auto"
            />
            <h5 className=" py-2 font-semibold">Ravi Purohit</h5>
            <em>Sr. VP & Global Head - ERP Business, Kapil Technologies</em>
          </div>
          <div className="text-center text-[#4d4d4f] text-base">
            <img
              src={event_organization2}
              alt="event_organization"
              className="mx-auto"
            />
            <h5 className=" py-2 font-semibold">Ravi Purohit</h5>
            <em>Sr. VP & Global Head - ERP Business, Kapil Technologies</em>
          </div>
          <div className="text-center text-[#4d4d4f] text-base">
            <img
              src={event_organization1}
              alt="event_organization"
              className="mx-auto"
            />
            <h5 className=" py-2 font-semibold">Ravi Purohit</h5>
            <em>Sr. VP & Global Head - ERP Business, Kapil Technologies</em>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Events_inner;
