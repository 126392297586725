import React from "react";
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import PointsWithHeading from "../../component/ReusablaeComponent/PointsWithHeading";
import ApplicationManagements from "../../../Assets/Application_Management.png";
import Approch from "../../../Assets/Approch.png";
import Application_Monitoring from "../../../Assets/Application Monitoring.png";
import Cost_cutting from '../../../Assets/Cost_cutting.png';
import Tailored_Solutions from '../../../Assets/Tailored Solutions.png'

const ApplicationManagement = () => {
  const Key_services = [
    {
      strongText: "Application Monitoring & Performance Optimization ",
      text: "Continuous monitoring to detect and resolve issues before they impact operations.",
    },
    {
      strongText: "Security & Compliance",
      text: "Ensuring your applications meet regulatory requirements and are protected from threats.",
    },
    {
      strongText: "Application Upgrades & Patch Management",
      text: "Keeping your systems up-to-date with the latest features, security patches, and performance enhancements.",
    },
    {
      strongText: "Incident Management & Support",
      text: "Rapid issue resolution and root-cause analysis to prevent recurring problems.",
    },
  ];
  const Benefits_AM = [
    {
      strongText: "Increased System Uptime ",
      text: "Through proactive monitoring and issue resolution, we ensure uninterrupted operations.",
    },
    {
      strongText: "Enhanced User Experience",
      text: "Improved application performance results in better user productivity and satisfaction.",
    },
    {
      strongText: "Cost Savings",
      text: "Avoid expensive downtime and reduce the need for emergency fixes through consistent maintenance and upgrades.",
    },
    {
      strongText: "Scalability & Flexibility",
      text: "Adapt your systems to meet growing business demands with minimal disruption.",
    },
  ];

  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading={"Overview of Applications Management"}
        paragragh={
          <>
            Applications Management ensures the efficient operation,
            maintenance, and evolution of business applications. By aligning
            technology with business needs, we help organizations improve
            performance, reduce downtime, and enhance user experience.
          </>
        }
        imgScr={ApplicationManagements}
        altText="ApplicationManagements"
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading={"Our Approach"}
        paragraph={
          <>
            Our team takes a proactive approach to applications management by
            monitoring, updating, and optimizing your software systems. We focus
            on maximizing the value of your investments through strategic
            upgrades, process automation, and preventive maintenance.
          </>
        }
        imgsrc={Approch}
        altText="Approch"
      />
      <PointsWithHeading
        title="Key Services"
        reverse={false}
        features={Key_services}
        imageSrc={Application_Monitoring}
        altText="Application_Monitoring"
      />
      <PointsWithHeading
        bgColor="#fff"
        title="Benefits of Our Applications Management"
        features={Benefits_AM}
        imageSrc={Cost_cutting}
        altText="Cost_cutting"
      />
      <Image_ContentReusableComponent
        reverse={false}
        heading={"Tailored Solutions"}
        paragraph={
          <>
            Our Applications Management services are designed to meet the unique
            needs of your business. We assess your specific challenges and
            requirements, delivering customized strategies that enhance
            performance and drive efficiency. With flexible and scalable
            solutions, we ensure your applications evolve alongside your
            organization, maximizing your technology investments and enabling
            seamless growth.
          </>
        }
        imgsrc={Tailored_Solutions}
        altText='Tailored_Solutions'
      />
    </div>
  );
};

export default ApplicationManagement;
