import React from "react";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import PointsComponent from "../../component/ReusablaeComponent/PointsComponent";
import PointsWithHeading from "../../component/ReusablaeComponent/PointsWithHeading";
import ProductLifeCycle from '../../../Assets/Product_Life_Cycle.png'
import EmpowerBusiness from '../../../Assets/EmpowerBusiness.png'
import Communication from '../../../Assets/Communication.png'
import Benefits from '../../../Assets/Benefits.png';
import ecoFriendly from '../../../Assets/Eco-Friendly.png'

const PLM = () => {
  const features = [
    {
      strongText: "Collaboration and Communication:",
      text: "Seamlessly connect teams across R&D, engineering, manufacturing, and service departments.",
    },
    {
      strongText: " Version Control:",
      text: "Track and manage changes to product data, ensuring accuracy and consistency.",
    },
    {
      strongText: "Workflow Automation:",
      text: ": Streamline processes with configurable workflows for approvals, revisions, and tasks.",
    },
    {
      strongText: "Data Management:",
      text: " Maintain a single source of truth for all product-related information.",
    },
    {
      strongText: " Compliance Management:",
      text: "Ensure adherence to industry standards and regulations.",
    },
  ];
  const BenefitsPLM = [
    {
      strongText: "Faster Time-to-Market:",
      text: "Accelerate product development cycles with optimized processes and reduced bottlenecks.",
    },
    {
      strongText: "Improved Product Quality:",
      text: "Reduce errors and enhance design quality through better collaboration and data accuracy.",
    },
    {
      strongText: "Lower Costs: ",
      text: ": Minimize waste and inefficiencies, reducing production and  compliance costs.",
    },
    {
      strongText: "Enhanced Innovation:",
      text: " Foster innovation by connecting teams and encouraging cross-functional collaboration.",
    },
  ];
  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading={"Introduction to PLM"}
        paragragh={
          <>
            Product Lifecycle Management (PLM) is a comprehensive approach to
            managing the entire lifecycle of a product from its initial
            conception, through design and manufacturing, to service and
            disposal. PLM enables companies to innovate, collaborate, and drive
            efficiency throughout the product lifecycle.
          </>
        }
        imgScr={ProductLifeCycle}
        altText='ProductLifeCycle'
      />

      <PointsComponent
        title="Why Choose PLM?"
          description="PLM empowers businesses by:"
        listItems={[
          "Centralizing product data and processes",
          "Enhancing collaboration across departments",
          "Reducing time-to-market for new products",
          "Improving product quality and compliance",
          "Driving cost efficiencies",
        ]}
        imageSrc={EmpowerBusiness}
        altText="EmpowerBusiness"
      />
      <PointsWithHeading
        title="Key Features of Our PLM Solution"
        reverse={false}
        features={features}
        imageSrc={Communication}
        altText="Communication"
      />
      <PointsWithHeading
        bgColor="#fff"
        title="Benefits of Implementing PLM"
        features={BenefitsPLM}
        imageSrc={Benefits}
        altText="Benefits"
      />
      <Image_ContentReusableComponent
        reverse={false}
        heading={"PLM for Sustainability"}
        paragraph={
          <>
            Our PLM solution helps companies integrate sustainable practices
            throughout the product lifecycle. By optimizing material choices and
            reducing waste, businesses can minimize their environmental impact
            while improving efficiency. PLM supports eco-friendly product
            design, resource-efficient manufacturing, and sustainable supply
            chain management. It also enables lifecycle assessments (LCA) to
            evaluate the environmental impact of products and promotes a
            circular economy by designing for reuse and recycling. With built-in
            compliance tools, our PLM ensures adherence to global environmental
            regulations.
          </>
        }
        imgsrc={ecoFriendly}
        altText={ecoFriendly}
      />
    </div>
  );
};

export default PLM;
