import React from 'react'

const HeaderSectionReusableComponent = ({mainHeading , heading , paragragh , imgScr}) => {
  return (
    <section className="bg-[#efefef] ">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10  xl:mx-40 mx-3 md:mx-6 ">
          <div className="col-span-1 xl:my-auto  py-5 order-2 lg:order-1">
            <h1 className='text-[#e83232]' >
              {mainHeading}
            </h1>
            <h2>
            {heading}
            </h2>
            <p className="text-paracolor">
            {paragragh}
            </p>
          </div>
          <div className="col-span-1 flex items-center justify-center order-1 lg:order-2 ">
            <img
              src={imgScr}
              alt="imgScr"
              className=""
            />
          </div>
        </div>
      </section>
  )
}

export default HeaderSectionReusableComponent