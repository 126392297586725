import React, { useEffect, useState } from "react";

import { Outlet } from "react-router-dom";

import Navbar from "../../component/Navbar/Navbar";
import Footer from "../../component/Footer/Footer";
import { ScrollToTop } from "../../../utils/Utils";

const Layout = () => {

  let [isCLickClicked,setisCLickClicked] = useState(false)
  const linkClicked = () => {
    setisCLickClicked(!isCLickClicked)
  };

  return (
    <div>
      <ScrollToTop isCLickClicked={isCLickClicked} />
      <div className="app">
        <Navbar linkClicked = {linkClicked}  />
        <main className="bg-[#f8fafc]">
          <Outlet context={{ linkClicked }} />
        </main>
        <Footer linkClicked = {linkClicked}/>
      </div>
    </div>
  );
};

export default Layout;
