
import { Link } from "react-router-dom";
import Google_colud_services from "../../../Assets/Google_cloud_services.png";
import smaple2nd_img from '../../../Assets/Sameple2nd_img.png'
import sample3rd_img from '../../../Assets/Sample3rd_img.png'
import sample4th_img from '../../../Assets/sample4th_img.png'
import { useScrollAnimation } from "../../../utils/Utils";

const SampleTemp = () => {
    useScrollAnimation();
  return (
    <div>
            <section className="bg-[#efefef] ">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10  xl:mx-40 mx-3 md:mx-6 ">
          <div className="col-span-1 xl:my-auto  py-5 order-2 lg:order-1">
            <h1 className="">
            Systems integration
            </h1>
            <h2>
            Seamlessly connecting the end-to-end digital enterprise
            </h2>
            <p className="text-paracolor">
            Our systems integration services enable seamless and secure experiences for your customers and citizens, from the back office through to the front office.
            </p>
          </div>
          <div className="col-span-1 flex items-center justify-center order-1 lg:order-2 image_design">
            <img
              src={Google_colud_services}
              alt="Google_colud_services"
              className="w-full h-full"
            />
          </div>
        </div>
      </section>
      <section className="py-10 bg-[#fff]">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10 xl:mx-40 mx-3 md:mx-6">
          <img
            src={smaple2nd_img}
            alt="sample_img"
            className="w-full h-full animate-up"
          />
          <div className="col-span-1 xl:my-auto animate-up">
            <h2>
            A full-featured call-center solution
            </h2>
            <p className="text-paracolor ">
            Kapil Technologies, powered by Customer Advance, powers the full range of contact center processes, including customer contacts, call center operations and call routing and queuing, all to provide robust business office outsourcing for outstanding customer care.
            </p>
            <p className="text-paracolor ">
            Kapil Technologies, powered by Customer Advance, powers the full range of contact center processes, including customer contacts, call center operations and call routing and queuing, all to provide robust business office outsourcing for outstanding customer care.
            </p>
          </div>
        </div>
      </section>
      <section className="py-10 bg-[#efefef] ">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10  xl:mx-40 mx-3 md:mx-6">
          <div className="col-span-1 my-auto order-2 lg:order-1 animate-up">
            <h2>
              Realize a Fast-paced Digital Transformation for Your Enterprise
              with Our Holistic Consulting Services for Google Cloud Solutions
            </h2>
            <p className="text-paracolor">
              Empower your enterprise to achieve a fast-tracked transformation
              that’s futuristic yet sustainable for your business operations
              with our all-encompassing expertise of
              <span className="font-semibold"> Google Cloud solutions</span>.
              Our consultants have tremendous hold over industry trends and will
              tailor the most unparalleled and flexible cloud solutions for you
              after a deep dive into your business and its operational needs.
            </p>
          </div>
          <img
            src={sample3rd_img}
            alt="sample3rd_img"
            className="w-full h-full order-1 lg:order-2 animate-up"
          />
        </div>
      </section>
      <section className="py-10 bg-[#fff]">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10  xl:mx-40 mx-3 md:mx-6">
        <div className="col-span-1 rounded overflow-hidden shadow-lg bg-[#fff] card-container animate-up flex flex-col">
      <div className="imagehover relative">
        <img src={sample4th_img} alt="sample4th_img" className="w-full" />
        <div className="overlay absolute inset-0 bg-[#000] opacity-0 transition-opacity duration-300"></div>
      </div>
      <div className="px-6 py-4 flex-grow flex flex-col">
        <h3 className="pb-[12px] font-bold">
          Warehouse management and Supplier collaboration
        </h3>
        <Link
          className="hover-link transition-colors duration-300 flex-grow"
          to="casestudies"
        >
          <p>
            Edge solutions for Warehouse management and Supplier collaboration
           
          </p>
        </Link>
        <p className="mt-5 mb-2 flex items-center gap-1 group hover:text-darkblue">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-7 transition-transform duration-300 group-hover:translate-x-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </p>
      </div>
    </div>
    <div className="col-span-1 rounded overflow-hidden shadow-lg bg-[#fff] card-container animate-up flex flex-col">
      <div className="imagehover relative">
        <img src={sample4th_img} alt="sample4th_img" className="w-full" />
        <div className="overlay absolute inset-0 bg-[#000] opacity-0 transition-opacity duration-300"></div>
      </div>
      <div className="px-6 py-4 flex-grow flex flex-col">
        <h3 className="pb-[12px] font-bold">
          Warehouse management and Supplier collaboration
        </h3>
        <Link
          className="hover-link transition-colors duration-300 flex-grow"
          to="casestudies"
        >
          <p>
            Edge solutions for Warehouse management and Supplier collaboration
           
          </p>
        </Link>
        <p className="mt-5 mb-2 flex items-center gap-1 group hover:text-darkblue">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-7 transition-transform duration-300 group-hover:translate-x-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </p>
      </div>
    </div>
        </div>
      </section>
      
    </div>
  )
}

export default SampleTemp