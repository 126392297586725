import Aws_services from "../../../Assets/AWS Services.png";
import AWS_capabilities from "../../../Assets/AWS_capabilities.png";
import AWS_consultancy from "../../../Assets/AWS_consultancy.png";
import Aws_colud_computing from "../../../Assets/AWS_cloud_perfomance.png";
import Aws_security from "../../../Assets/Aws_security.png";
import AWS_Services_consultancy from "../../../Assets/Aws_Servicess.png";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import Content_GradientLine from "../../component/ReusablaeComponent/Content_GradientLine";
import ListOfLinkSection from "../../component/ReusablaeComponent/ListOfLinkSection";

const AWS = () => {
  const listItems = [
    {
      title: "Adaptable: ",
      description:
        " Our consultants help you unleash the power of AWS’ robust cloud infrastructure that efficiently manages data storage and services, conforms to modern security and compliance norms at optimal costs, and helps build a truly resilient enterprise.",
    },
    {
      title: "Innovative: ",
      description:
        "Your enterprise will get to overhaul its existing applications and build highly advanced AWS-based cloud-native applications with groundbreaking platforms such as Blockchain, Artificial Intelligence, Augmented Reality/Virtual Reality (AR/VR), Internet of Things (IoT).",
    },
    {
      title: "Smart:",
      description:
        "Leverage the effectiveness of precision-driven analytics across all modules of the AWS cloud platform and enable strategic decision-making abilities that lead to successful business outcomes.",
    },
  ];
  const ListofLinks =[
    "AI-driven Digital Compliance",
    "Cloud Migrations to AWS Platform",
    "Automation for Underwriting Services",
    "Cloud-based Asset Intelligence",
    "Hands-free Augmented Reality (AR) Solutions",
  ]

  return (
    <div>
      {/* Header */}
      <HeaderSectionReusableComponent
        mainHeading={
          "Make Your Enterprise Future-ready with Specialized AWS Services"
        }
        heading={
          "Engineer a Transformative Enterprise with the Power of AWS Cloud"
        }
        paragragh={
          <>
            Digitizing enterprise operations with technologies of tomorrow is an
            imperative for all forward-looking enterprises today.
            <span className="font-semibold"> AWS Cloud Services</span> (Amazon
            Web Services Cloud) incorporate a highly specialized and versatile
            cloud technology platform that help enterprises transform the speed,
            precision, and agility with which they operate. Kapil Technologies
            has the advanced expertise with AWS Cloud to enable your enterprise
            to fulfill those metrics. Enhance that transformation with our
            proficient capabilities covering AWS data migration, integration
            services, analytics, and cloud optimization.
          </>
        }
        imgScr={Aws_services}
      />

      {/*Enabling Sustainable Enterprises with Wide-ranging AWS Cloud Capabilities */}

      <Image_ContentReusableComponent
        bgColor="#fff"
        heading={
          "Enabling Sustainable Enterprises with Wide-ranging AWS Cloud Capabilities"
        }
        paragraph={
          <>
            Our extensive cloud capabilities free up your resources from the
            responsibility of dedicated cloud management. Our AWS Cloud Managed
            Services cover all aspects from implementation to maintenance,
            allowing your workforce single-mindedly focus on business
            performance. Complement this with our AWS Data Migration Services
            that equip you with scalable and cost-efficient storage solutions,
            cutting down your dependence on on-premise storage.
          </>
        }
        imgsrc={AWS_capabilities}
      />
      {/* We are a Top Tier AWS Consulting Services Provider */}

      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading={"We are a Top Tier AWS Consulting Services Provider"}
        paragraph={
          <>
            As an advanced provider of AWS Consulting Services, Kapil
            Technologies helps you blend exceptional cloud strategies with
            leading-edge AWS Cloud technology of the future.{" "}
            <span className="mt-4 block">
              Take advantage of our consultants’ excellence with AWS cloud
              technology to operate with enterprise solutions that are scalable
              and help you compete with maximum performance.
            </span>
          </>
        }
        imgsrc={AWS_Services_consultancy}
      />

      {/*Our Consulting Expertise Covers the Following AWS Solutions */}
      <ListOfLinkSection
        title="Our Consulting Expertise Covers the Following AWS Solutions"
        ListofLinks={ListofLinks}
      />
  
      {/*  How Our Consultants Help You Achieve*/}

      <Content_GradientLine
        title=" How Our Consultants Help You Achieve an Impactful Cloud Computing
              Performance"
        paragraph={
          <>
            Consultants at Kapil Technologies have tremendous knowledge and
            experience of AWS’ Cloud Platform. They collaborate with you to help
            build an enterprise that is:
          </>
        }
        listItems={listItems}
        imgSrc={Aws_colud_computing}
      />

      {/* Streamline Resources, Bootst productivity */}
      <section className=" py-10 bg-[#efefef]">
        <div className="xl:mx-40 mx-3 md:mx-6">
          <h2 className=" text-center  animate-up">
            Explore Our Broad-range of AWS Offerings
          </h2>
          <div className="lg:px-36 animate-up">
            {/* Uncomment the paragraph if needed */}
            {/* <p className="pb-4">
    In the age of automation, it is vital to channel your resources to
    more productive tasks. Kapil Technologies empowers you to
    accomplish that by helping you streamline your resources for more
    productive tasks while automating the mundane and repetitive ones.
    This enhances your productivity and helps you hold a competitive
    edge in the industry with efficient business operations.
  </p> */}
            <div className="overflow-x-auto border border-black rounded-lg">
              <table className="min-w-full table-auto  shadow-md rounded-lg overflow-hidden border border-gray-300">
                <thead className="bg-blue-500 text-black border border-black">
                  <tr className="border border-gray-300">
                    <th className="px-6 py-3 text-left border border-gray-300 font-semibold">
                      Data Analytics and AI/ML
                    </th>
                    <th className="px-6 py-3 text-left border border-gray-300 font-semibold">
                      Building and Upgradation of Applications
                    </th>
                    <th className="px-6 py-3 text-left border border-gray-300 font-semibold">
                      SAP-Focused AWS Solutions
                    </th>
                    <th className="px-6 py-3 text-left border border-gray-300 font-semibold">
                      AWS Migration Services
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-gray-100">
                    <td className="px-6 py-4 border border-gray-300">
                      Content Search
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      DevSecOps
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      Assessment of SAP Platforms
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      Application Migrations to AWS Platform
                    </td>
                  </tr>
                  <tr className="bg-gray-100">
                    <td className="px-6 py-4 border border-gray-300">
                      Analytics
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      IoT-based Solutions
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      Migrating SAP Applications to AWS
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      Cobol and RPG Migrations
                    </td>
                  </tr>
                  <tr className="bg-gray-100">
                    <td className="px-6 py-4 border border-gray-300">
                      Cognitive AI
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      Blockchain Solutions
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      Data Extraction from SAP Applications on AWS
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      Legacy Systems Migration to Cloud Platform
                    </td>
                  </tr>
                  <tr className="bg-gray-100">
                    <td className="px-6 py-4 border border-gray-300">
                      Data Ingestion
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      Native Cloud Solutions
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      Data Analytics from SAP Systems on AWS
                    </td>
                    <td className="px-6 py-4 border border-gray-300">
                      Datacenter Migration Services (On-premises, Cloud, and
                      Hybrid)
                    </td>
                  </tr>
                  <tr className="bg-gray-100">
                    <td className="px-6 py-4 border border-gray-300">
                      Data Transformation
                    </td>
                    <td className="px-6 py-4 border border-gray-300"></td>
                    <td className="px-6 py-4 border border-gray-300"></td>
                    <td className="px-6 py-4 border border-gray-300"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      {/* Collaborate with Our AWS Consultants in the Most Straightforward Manner */}

      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading={
          "Collaborate with Our AWS Consultants in the Most Straightforward Manner"
        }
        paragraph={
          <>
            Partnering with Kapil Technologies’ AWS consultants is a fairly
            straightforward process. Our consultants meet you on demand for a
            comprehensive analysis of your business objectives and subsequently
            formulate an immaculate strategy aligning with your vision and
            goals. Guiding you to rollout the solutions underlying the strategy
            and manage them efficiently follows in quick succession, enabling
            flawless realization of business targets.
          </>
        }
        imgsrc={AWS_consultancy}
      />

      {/* Operate with the Best Standards of Compliance and Security*/}

      <Image_ContentReusableComponent
        heading={"Operate with the Best Standards of Compliance and Security"}
        paragraph={
          <>
            Our certified and accomplished AWS cloud experts bring you the best
            standards of information security measures in the industry. Benefit
            from the most advanced AWS security architecture that our
            professionals help you implement, enjoy the best compliance levels
            with industry regulations, and build a robustly thriving enterprise
            of tomorrow.
          </>
        }
        imgsrc={Aws_security}
      />
    </div>
  );
};

export default AWS;
