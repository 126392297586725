
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeadingParagraphSection from "../../component/ReusablaeComponent/HeadingParagraphSection";
import Constructions from '../../../Assets/Construstion.png'
import ConstructionManagement from '../../../Assets/Construction Management.png';
import ResidentionalArea from '../../../Assets/ResidentionalArea.png'
import Health_Safety from '../../../Assets/Health_Safety.png' 

const Construction = () => {
  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading="Building the Future of Construction"
        paragragh={
          <>
            We provide innovative construction solutions that meet the demands
            of modern infrastructure projects. From residential developments to
            large-scale commercial construction, our expertise drives progress
            and efficiency.
          </>
        }
        imgScr={Constructions}
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading="End-to-End Construction Management"
        paragraph={
          <>
            Our comprehensive project management services cover every phase of
            construction, from planning and design to execution and delivery. We
            ensure that projects are completed on time, within budget, and to
            the highest quality standards.
          </>
        }
        imgsrc={ConstructionManagement}
      />
      <HeadingParagraphSection
        heading="Infrastructure Development"
        paragraph={
          <>
            With expertise in civil engineering and large-scale infrastructure,
            we deliver robust solutions for highways, bridges, tunnels, and
            public utilities. Our focus is on creating resilient infrastructure
            that supports economic growth and community development.
          </>
        }
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading="Residential and Commercial Projects"
        paragraph={
          <>
            From high-rise buildings to commercial complexes, our team delivers
            world-class construction services across a range of sectors. Whether
            it’s luxury residences or state-of-the-art office spaces, we bring
            expertise in architectural design and construction excellence.
          </>
        }
        imgsrc={ResidentionalArea}
      />
      <Image_ContentReusableComponent
      
        heading="Health & Safety Compliance"
        paragraph={
          <>
            We prioritize health and safety in every project we undertake. Our
            stringent safety protocols, coupled with regulatory compliance,
            ensure a safe working environment for all stakeholders involved in
            construction projects.
          </>
        }
        imgsrc={Health_Safety}
      />
    </div>
  );
};

export default Construction;
