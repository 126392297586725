import microsoft_transformation from "../../../Assets/microsoft_transformation.png";
import microsoft_services from "../../../Assets/Microsoft_services.png";
import microsoft_interaction from "../../../Assets/microsfot_interaction.png";
import micrsoft_tranfer from "../../../Assets/microsoft_transfer.png";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import MicrosoftService_Solutions from "../../../Assets/Microsoft_Servicess.png";
import Microsoft_consultacy from "../../../Assets/Microsoft_consultancy.png";
import Content_GradientLine from "../../component/ReusablaeComponent/Content_GradientLine";
import ListOfLinkSection from "../../component/ReusablaeComponent/ListOfLinkSection";
import HeadingParagraphSection from "../../component/ReusablaeComponent/HeadingParagraphSection";

const Microsoft = () => {
  const listItems = [
    {
      description:
        "Kapil Technologies’ certified Microsoft consultants assess your business requirements to the detail and tailor the most agile solutions suiting your goals.",
    },
    {
      description:
        "They are driven to accelerate your journey to value-realization by helping you automate and streamline all your business operations, from ERP to CRM.",
    },
    {
      description: `In coordination with our business analysts and technology experts, our consultants make it easy for you to accomplish a 360° digitalization of your enterprise.`,
    },
    {
      description:
        "With our consulting services, enterprises achieve a great degree of scalability and scalability andstrategicscalability andstrategicstrategic insights that enable transformative business and customer experiences.",
    },
    {
      description:
        "No matter how complicated your projects are, with our expertise, you will execute them all efficiently and with high quality for successful business results.",
    },
  ];

  const ListofLinks = [
    "Business Applications",
    "Data and Artificial Intelligence",
    "Microsoft Modern Workplace",
    "Surround ERP",
    "Business Applications",
    "Applications and Infrastructure",
  ];
  return (
    <div>
      {/* Header */}

      <HeaderSectionReusableComponent
        mainHeading={
          "Drive Your Enterprise Excellence With Microsoft’s Strategic Enterprise Services"
        }
        heading={
          "Grow Exponentially With Expert Microsoft Solutions That Help Your Business Rise Up the Value Chain"
        }
        paragragh={
          <>
            We are a strategic Microsoft Solutions partner that will power your
            enterprise’s steady growth across industrial segments. Our modern
            Microsoft-based solutions make you industry-ready by modernizing
            everything in your organization - whether it is the IT
            infrastructure, applications, and enterprise planning. Embrace the
            power of our agile solutions and become an organization that is
            highly adaptable, productive, and enduring.
          </>
        }
        imgScr={microsoft_services}
      />

      {/*Achieve Comprehensive Digital Transformation With
Groundbreaking Microsoft Services and Solutions*/}
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading={
          " Achieve Comprehensive Digital Transformation With Groundbreaking Microsoft Services and Solutions"
        }
        paragraph={
          <>
            Being a leading Microsoft alliance partner, we render a multitude of
            services and solutions from Microsoft’s technology ecosystem to help
            enterprises realize a wholesome digital transformation across the
            board. In the Microsoft technology stack, our expertise transcends
            several enterprise solutions viz., Office 365, Power apps,
            Sharepoint, Azure, Dynamics 365, Microsoft Teams, OneDrive for
            Business, and more.
          </>
        }
        imgsrc={MicrosoftService_Solutions}
      />
      {/* All Your Microsoft Enterprise Solutions on a Single
Platform*/}

      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading={"All Your Microsoft Enterprise Solutions on a Single Platform"}
        paragraph={
          <>
            Leverage all your Microsoft enterprise solutions from the
            convenience of a single platform. Be it ERP, CRM, communication, or
            document management tools, all these enterprise solutions are built
            on a versatile platform that allows your workforce and resources to
            operate in highly competitive and agile environments. All our
            offerings are robustly secure, whether they are on-premise,
            cloud-based, or even hybrid deployments. They are tailored and
            always flexible to operate as per our clients’ needs.
          </>
        }
        imgsrc={microsoft_transformation}
      />

      {/*Experience Our Broad-based Consulting Services for
        Microsoft Platforms */}

      <ListOfLinkSection
        title="Experience Our Broad-based Consulting Services for Microsoft Platforms"
        ListofLinks={ListofLinks}
      />
     
      {/*   What Our Consultants Bring to the Table*/}

      <Content_GradientLine
        title="What Our Consultants Bring to the Table"
        paragraph={<>Realize Value with Top-notch Industry Insights</>}
        listItems={listItems}
        imgSrc={Microsoft_consultacy}
      />

      {/*  Precision-driven Analytics That Make
        Decision-making Easier */}
         <HeadingParagraphSection
        heading={
          " Precision-driven Analytics That Make Decision-making Easier"
        }
        paragraph={
          <>
            Stay in control of your business with accurate insights from
              analytics that drive outcome-oriented decision-making for your
              business. With Power BI at your disposal, you will be able to keep
              a robust track of your enterprise’s data anywhere anytime. Our
              Power BI offering gives you a comprehensive view of your business
              metrics irrespective of whether your business data is available
              on-premise or through cloud-storage. You can also enhance your
              business outcomes via visualization of predictive results
              vis-a-vis industry trends, financials, global and regional
              economic outlooks, and markets with high potential.
          </>
        }
      />

      {/*Interact with Top Consultants That Make a Difference
to Your Business */}
      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading={
          "Interact with Top Consultants That Make a Difference to Your Business"
        }
        paragraph={
          <>
            Our top-tier consultants are vastly experienced and bring unmatched
            expertise to support you at every stage of your enterprise’s digital
            transformation. Whether you want to implement cutting-edge tech
            infrastructure or incorporate the latest Microsoft technology
            platforms you will seamlessly get to interact with our consultants
            every step of the way. They will always be available for you without
            any barriers and help you scale new business heights.
          </>
        }
        imgsrc={microsoft_interaction}
      />

      {/* Transform Your Today with Tomorrow’s Solutions */}

      <Image_ContentReusableComponent
        heading={"Transform Your Today with Tomorrow’s Solutions"}
        paragraph={
          <>
            Kapil Technologies is driven to equip your enterprise with
            groundbreaking IT and consulting services that will digitally
            transform your business and make it future-ready. Our accomplished
            professionals rely on time-tested strategies and deployment of
            futuristic, agile tech platforms to help you be at the forefront of
            productivity and operational excellence. Collaborate with our
            skilled teams today and be an industry leader.
          </>
        }
        imgsrc={micrsoft_tranfer}
      />
    </div>
  );
};

export default Microsoft;
