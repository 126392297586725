import React from 'react'

const HeadingParagraphSection = ({heading , paragraph, bgColor = '#efefef'}) => {
  return (
    <section className=" py-10 " style={{ backgroundColor: bgColor }}>
        <div className="xl:mx-40 mx-3 md:mx-6 animate-up">
          <h2 className="text-center">
           {heading}
          </h2>
          <div className="lg:px-20  ">
            <p className="pb-4 custom-center">
             {paragraph}
            </p>
          </div>
        </div>
      </section>
  )
}

export default HeadingParagraphSection