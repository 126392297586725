import React from "react";
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import Image_ContentReusableComponent from "../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeadingParagraphSection from "../../component/ReusablaeComponent/HeadingParagraphSection";
import AutomotiveExpertise from '../../../Assets/Automotive Expertise.png';
import Comprehensive from '../../../Assets/Comprehensive.png';
import SupplyChain from '../../../Assets/Supply Chain.png';
import AfterMarketService from '../../../Assets/Aftermarket Services.png'

const Automative = () => {
  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading={"Our Automotive Expertise"}
        paragragh={
          <>
            We bring deep industry knowledge and cutting-edge technology to meet
            the diverse needs of the automotive sector. Our expertise spans from
            design and engineering to production and supply chain management,
            ensuring that your business stays ahead in this fast-paced industry.
          </>
        }
        imgScr={AutomotiveExpertise}
      />
      <Image_ContentReusableComponent
        bgColor="#fff"
        heading="Comprehensive Automotive Solutions"
        paragraph={
          <>
            Our suite of automotive solutions covers every aspect of the vehicle
            lifecycle. Whether you're looking to streamline manufacturing,
            enhance product quality, or improve aftersales services, we provide
            the tools and support you need to  succeed.
          </>
        }
        imgsrc={Comprehensive}
      />
      <HeadingParagraphSection
        heading={"Innovation in Electric and Autonomous Vehicles"}
        paragraph={
          <>
            As the automotive industry shifts toward electric and autonomous
            vehicles, we help our clients innovate and adapt. From electric
            powertrain solutions to advanced driver assistance systems (ADAS),
            we are at the forefront of automotive technology.
          </>
        }
      />

      <Image_ContentReusableComponent
        bgColor="#fff"
        reverse={false}
        heading="Supply Chain & Logistics Optimization"
        paragraph={
          <>
            In the automotive world, efficiency is key. We optimize your supply
            chain and logistics to ensure smooth operations, reduce lead times,
            and improve overall productivity. Our solutions offer real-time data
            insights to help you make informed decisions.
          </>
        }
        imgsrc={SupplyChain}
      />
      <Image_ContentReusableComponent
      
        heading="Aftermarket Services & Support"
        paragraph={
          <>
            We extend our services beyond manufacturing, offering comprehensive
            aftermarket support. From parts management to customer service, we
            help automotive companies maintain strong relationships with their
            clients and optimize their aftermarket operations.
          </>
        }
        imgsrc={AfterMarketService}
      />
    </div>
  );
};

export default Automative;
