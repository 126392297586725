import { useEffect, useState } from 'react';

import { useLocation } from "react-router-dom";



 export const useScrollAnimation = (animationClass = 'animate-up')=>{
  
   useEffect(() => {
     const sections = document.querySelectorAll(`.${animationClass}`);

     const observer = new IntersectionObserver((entries) => {
     entries.forEach(entry => {
         if (entry.isIntersecting) {
           entry.target.classList.add('active');
        }
       });
   }, { threshold: 0.1 });

     sections.forEach(section => {
      observer.observe(section);
     });

     return () => {
       sections.forEach(section => {
         observer.unobserve(section);
       });
     };
   }, []);
}
export const ScrollToTop = ({isCLickClicked}) => {
  useEffect(() => {

    window.scrollTo(0, 0);
  }, [isCLickClicked]);
  return null;
};












//  const useScrollAnimationUp = (animationClass = 'animate-up') => {
//     useEffect(() => {
//       const sections = document.querySelectorAll(`.${animationClass}`);
    
//       const observer = new IntersectionObserver((entries) => {
//         entries.forEach(entry => {
//           if (entry.isIntersecting) {
//             entry.target.classList.add('active');
//           }
//         });
//       }, { threshold: 0.1 });
    
//       sections.forEach(section => {
//         observer.observe(section);
//       });
    
//       return () => {
//         sections.forEach(section => {
//           observer.unobserve(section);
//         });
//       };
//     }, [animationClass]); // Ensure this dependency array is correct
//   };
  
//   export default useScrollAnimationUp;
