import React from "react";
import leader1 from "../../../Assets/Leader1.jpg";
import leader2 from "../../../Assets/Leader2.jpg";
import leader3 from "../../../Assets/Leader3.jpg";
import leader4 from "../../../Assets/Leader4.jpg";
import { useScrollAnimation } from "../../../utils/Utils";

const Leadership = () => {
  useScrollAnimation();
  const members = [
    {
      img: leader1,
      name: "Jane Doe",
      designation: "Photographer",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
    },
    {
      img: leader2,
      name: "Jane Doe",
      designation: "Photographer",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
    },
    {
      img: leader3,
      name: "Jane Doe",
      designation: "Photographer",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
    },
    {
      img: leader4,
      name: "Jane Doe",
      designation: "Photographer",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
    },
    {
      img: leader1,
      name: "Jane Doe",
      designation: "Photographer",
      description: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
    }
  ];
  return (
    <div className="bg-[#efefef] py-10">
    <div className="container mx-auto px-4 sm:px-6 md:px-8 lg:px-12 xl:px-40">
      <h1 className="mt-5 font-semibold uppercase">
        Leadership
      </h1>
      <div className="flex items-center pb-5">
        <h2 className=" mr-4">
          Board of Directors
        </h2>
        <div className="flex-1 border-t border-black"></div>
      </div>
  
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {members.map((member, index) => (
          <div
            key={index}
            className="group relative h-72 md:h-80 lg:h-96 w-full [perspective:1000px]"
          >
            <div className="relative h-full w-full rounded-xl shadow-xl transition-transform duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
              {/* Front Side */}
              <div className="absolute inset-0 backface-hidden">
                <img
                  src={member.img}
                  alt={`Leader ${index + 1}`}
                  className="h-full w-full rounded-xl object-cover shadow-xl shadow-black/40"
                />
              </div>
              {/* Back Side */}
              <div className="absolute inset-0 h-full w-full rounded-xl bg-black/80 px-4 sm:px-6 md:px-8 lg:px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                <div className="flex min-h-full flex-col items-center justify-center">
                  <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-white">
                    {member.name}
                  </h1>
                  <p className="text-sm sm:text-base md:text-lg text-white">
                    {member.designation}
                  </p>
                  <p className="text-xs sm:text-sm md:text-base text-white">
                    {member.description}
                  </p>
                  <button className="mt-2 rounded-md bg-neutral-800 py-1 px-2 text-xs sm:text-sm hover:bg-neutral-900 text-white">
                    Read More
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
  
  
  );
};

export default Leadership;
