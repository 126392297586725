import React from "react";
import HeaderSectionReusableComponent from "../../component/ReusablaeComponent/HeaderSectionReusableComponent";
import PointsWithHeading from "../../component/ReusablaeComponent/PointsWithHeading";
import ECG from '../../../Assets/ESG.png'
import Environmental_Responsibility from '../../../Assets/Environmental Responsibility.png'
import Social_Responsibilityy from '../../../Assets/Social Responsibility.png'
import Governance_Frameworks from '../../../Assets/Governance Framework.png'
import Goals from '../../../Assets/ESG Goals & Metrics.png'
const ESG = () => {
  const environment_responsibility = [
    {
      strongText: "Sustainability Initiatives:",
      text: "Describe how your company minimizes its carbon footprint, reduces waste, and promotes renewable energy sources.",
    },
    {
      strongText: "Resource Efficiency:",
      text: "Highlight how your company is committed to efficient energy, water usage, and materials to ensure minimal environmental impact.",
    },
    {
      strongText: "Green Innovations:",
      text: "Discuss any innovative practices or technologies you employ to support sustainable development.",
    },
  ];
  const Social_Responsibility = [
    {
      strongText: "Diversity & Inclusion:",
      text: " Share how your company fosters a diverse workforce, promoting equality and inclusivity across all levels of the organization.",
    },
    {
      strongText: "Community Engagement:",
      text: " Explain your company’s involvement in local communities through charitable initiatives, volunteering, and social programs.",
    },
    {
      strongText: "Employee Well-being:",
      text: "Outline programs in place for health and safety, employee growth, and work-life balance.",
    },
    {
      strongText: "Customer and Stakeholder Trust:",
      text: "Show how your company builds trust through responsible business practices, ethical sourcing, and delivering quality products.",
    },
  ];
  const Governance_Framework = [
    {
      strongText: "Corporate Governance:",
      text: "Describe your company's board structure, leadership, and commitment to accountability and transparency.",
    },
    {
      strongText: "Ethical Practices:",
      text: ": Highlight the ethical policies and compliance mechanisms in place, such as anti-corruption, data privacy, and responsible sourcing.",
    },
    {
      strongText: "Risk Management:",
      text: " Discuss how your company manages risks related to ESG factors and ensures regulatory compliance across all operations.",
    },
  ];
  const Esg_Goals = [
    {
      strongText: "Sustainability Metrics:",
      text: "Provide measurable data on carbon emissions reduction, energy consumption, waste management, and water use.",
    },
    {
      strongText: "Social Impact Goals:",
      text: "Share specific objectives around employee diversity, community programs, and stakeholder satisfaction.",
    },
    {
      strongText: "Governance Standards:",
      text: "Define goals for enhancing corporate governance, ethical behavior, and transparency in decision-making.",
    },
  ];
  return (
    <div>
      <HeaderSectionReusableComponent
        mainHeading={"What is ESG?"}
        paragragh={
          <>
            Environmental, Social, and Governance (ESG) refers to the three
            central factors in measuring the sustainability and societal impact
            of a company. ESG criteria help to ensure that companies operate
            responsibly, focusing on long-term value for both stakeholders and
            the environment.
          </>
        }
        imgScr={ECG}
        altText='ECG'
      />
      <PointsWithHeading
        bgColor="#fff"
        title="Environmental Responsibility"
        
        features={environment_responsibility}
        imageSrc={Environmental_Responsibility}
        altText="Environmental_Responsibility"
      />
      <PointsWithHeading
        reverse={false}
        title="Social Responsibility"
        features={Social_Responsibility}
        imageSrc={Social_Responsibilityy}
        altText="Social_Responsibilityy"
      />
      <PointsWithHeading
        bgColor="#fff"
        title="Governance Framework"
        
        features={Governance_Framework}
        imageSrc={Governance_Frameworks}
        altText="Governance_Frameworks"
      />
      <PointsWithHeading
      reverse={false}
        title="ESG Goals & Metrics"
        features={Esg_Goals}
        imageSrc={Goals}
        altText="Goals"
      />
    </div>
  );
};

export default ESG;
