import casestudies from "../../../Assets/microsoft_transfer.png";

import counsultancy from "../../../Assets/IT_Servicesss.png";
import { useScrollAnimation } from "../../../utils/Utils";

const About_inner = () => {
    useScrollAnimation();
  return (
    <div>
    <section className="bg-[#efefef]">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 xl:mx-40 mx-3 md:mx-6">
        <div className="col-span-1 xl:my-auto my-5 mx-9 order-2 md:order-1">
          <h1 className="">
            Partnering with Beneva to bring its bold plan to life as a digital
            organization, powered by business agility
          </h1>
          <p className=" mb-7 ">5 min read</p>
        </div>
        <div className="col-span-1 flex items-center justify-center order-1 md:order-2 image_design ">
          <img
            src={casestudies}
            alt="casestudies"
            className="w-full h-full"
          />
        </div>
      </div>
    </section>
    <section className="  bg-[#fff] py-10 ">
        <div className="xl:mx-60 mx-3 md:mx-6">
      <h3 className="pb-6 animate-up">
        Beneva Insurance is pursuing a bold and ambitious plan to make its
        mark as an industry digital leader in today’s dynamic,
        hypercompetitive environment. To do this, Beneva has identified
        business agility as a key strategic lever to efficiently and flexibly
        connect strategy to execution. They have trusted Kapil Technologies to help them
        orchestrate change through a three-year, disciplined agile
        transformation program.
      </h3>
      <p className="pb-6 animate-up">
        The insurance industry's consolidation trend continues with global
        mergers and acquisitions, building on last year’s momentum and rising
        by 23 percent during the first half of 2022 . Digital transformations,
        new forms of risk, and an evolving demand for modern customer services
        and products are redefining the insurance industry and prompting
        companies to meet today’s reality with new business and operating
        models. Speed, flexibility, and agility have become critical for
        insurers to bring products to market faster than their competitors.
      </p>
      <h2 className="animate-up">Context – Leverage business agility as a key strategic lever</h2>
      <p className="pb-6 animate-up">
        The insurance industry's consolidation trend continues with global
        mergers and acquisitions, building on last year’s momentum and rising
        by 23 percent during the first half of 2022 . Digital transformations,
        new forms of risk, and an evolving demand for modern customer services
        and products are redefining the insurance industry and prompting
        companies to meet today’s reality with new business and operating
        models. Speed, flexibility, and agility have become critical for
        insurers to bring products to market faster than their competitors.
      </p>
      <p className="pb-6 animate-up">
        But evolving to enhance business agility can be very disruptive and
        comes with risks if not carried out carefully. To mitigate these
        risks, Beneva pursued an agile transformation strategy in line with
        their organizational context, conducting changes with an iterative and
        incremental approach, and maximizing learning as they go. It’s a
        complex task, especially when implementing an agile delivery and
        governance model across all business lines at once. The challenge is
        even bigger when transformation occurs while executing a massive
        merger program, creating a new culture and maintaining operational
        excellence in today’s pandemic environment.
      </p>
      <p className="pb-6 animate-up">
        Beneva chose Kapil Technologies, a long-time partner of both SSQ and La Capitale, as
        its strategic partner to ensure a disciplined, well-structured journey
        toward business agility. Seamlessly and flexibly connecting strategy
        to execution, while capitalizing on the full potential of their
        employees, will ultimately position Beneva to deliver the best in
        insurance products and services in today’s fast-changing industry.
      </p>
      <p className="pb-6 animate-up">
        Kapil Technologies's proven experience in the insurance industry, mergers, and
        integrations contexts, agile-lean organizational transformation, and
        the SAFe framework proved to be an ideal fit for Beneva’s highly
        ambitious plan.
      </p></div>
    </section>
 
     
      <section className="py-10 bg-[#efefef]">
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10 xl:mx-40 mx-3 md:mx-6">
        <img src={counsultancy} alt="Leadership" className="my-auto animate-up" />
        <div className="col-span-1 xl:my-auto animate-up">
          <h2 className="">
          Value added - Embedding agile leadership in the corporate culture
          </h2>
          <p >
          Today, more than 85 agile teams, grouped into 15 trains, orchestrate their work to continuously deliver value based on initiatives prioritized by the company's 6 portfolios.
          </p>
          <p >
          In addition to focusing on core business lines and IT engineering practices, we worked with Beneva to ensure that agile leadership was embedded in the new organization’s workplace culture. Making an agile mindset integral to employee and management onboarding and training is critical.
          </p>
          <p >
          Anticipating the cultural impact of business agility and engaging key executives and HR throughout the change management process helped Beneva’s leaders foster a positive, collaborative environment that is fundamental to success. With Kapil Technologies’s guidance, Beneva is positioned to become a modern, agile organization and is ready to accelerate its value delivery and optimize its governance and delivery model.
          </p>
        </div>
      </div>
    </section>
  </div>
  )
}

export default About_inner