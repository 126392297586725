import CaseStudies from "../../componentLayer/pages/CaseStudies";
import About_inner from "../../componentLayer/pages/insights/About_inner";
import AboutUs from "../../componentLayer/pages/insights/AboutUs";
import Blog from "../../componentLayer/pages/insights/Blog";
import Blog_inner from "../../componentLayer/pages/insights/Blog_inner";
import CustomerStories from "../../componentLayer/pages/insights/CustomerStories";
import Customerstoriesmore from "../../componentLayer/pages/insights/Customerstoriesmore";
import Events_inner from "../../componentLayer/pages/insights/Events_inner";
import Leadership from "../../componentLayer/pages/insights/Leadership";
import Webinar from "../../componentLayer/pages/insights/Webinar";

export const insightsRouter = [
  {
    path: "aboutus",
    children: [
      { index: true, element: <AboutUs /> },
      { path: "casestudies", element: <CaseStudies /> },
      { path: "explore_us", element: <About_inner /> },
    ],

  
  },
  {
    path: "leadership",
    element: <Leadership />,
  },
  {
    path: "customerstories",

    children: [
      { index: true, element: <CustomerStories /> },
      { path: "casestudies", element: <CaseStudies /> },
      { path: "customerstoriesmore", element: <Customerstoriesmore /> },
    ],
  },
 


  {
    path: "webinar",

    children: [
      { index: true, element: <Webinar /> },
      { path: "events", element: <Events_inner /> },
    ],
  },
  

  {
    path: "blog",

    children: [
      { index: true, element: <Blog /> },
      { path: "explore_blog", element: <Blog_inner /> },
    ],
  },
  
  
];
