import Automative from "../../componentLayer/pages/industries/Automative";
import Construction from "../../componentLayer/pages/industries/Construction";
import Energy from "../../componentLayer/pages/industries/Energy";
import Equipment from "../../componentLayer/pages/industries/Equipment";
import FoodAndBeverage from "../../componentLayer/pages/industries/FoodAndBeverage";
import HighTechAndElectronics from "../../componentLayer/pages/industries/HighTechAndElectronics";
import IndustrialManufacturing from "../../componentLayer/pages/industries/IndustrialManufacturing";
import LifeScience from "../../componentLayer/pages/industries/LifeScience";
import MachineryAndEquipment from "../../componentLayer/pages/industries/MachineryAndEquipment";
import Manufacturing from "../../componentLayer/pages/industries/Manufacturing";



export const industriesRouter = [
    {
        path : "automotive",
        element: <Automative />,
      },
      {
        path : "construction",
        element: <Construction />,
      },
      {
        path : "energy",
        element: <Energy />,
      },
      {
        path : "equipment",
        element: <Equipment />,
      },
      {
        path : "food_and_beverage",
        element: <FoodAndBeverage />,
      },
      {
        path : "industrial_macinery_and_equipment",
        element: <MachineryAndEquipment />,
      },
      {
        path : "industrial_manufacturing",
        element: <IndustrialManufacturing />,
      },
      {
        path : "life_sciences",
        element: <LifeScience />,
      },
      {
        path : "high_tech_and_electrinics",
        element: <HighTechAndElectronics />,
      },
    

     

  // {
  //   path:"contact",
  //   index: true,
  //   element: <Contact />,
  // },
 
 
];