
import { useScrollAnimation } from "../../../../utils/Utils";
import MESs from '../../../../Assets/MES.png';
import producity from '../../../../Assets/Productivity.png';
import MES_enhance from '../../../../Assets/MES_enhance.png';
import Mes_aws from '../../../../Assets/Mes_Aws.png';
import Image_ContentReusableComponent from "../../../component/ReusablaeComponent/Image_ContentReusableComponent";
import HeaderSectionReusableComponent from "../../../component/ReusablaeComponent/HeaderSectionReusableComponent";


const MES = () => {
  useScrollAnimation();
  const listItems = [
    {
      title: "Complex Implementation:",
      description:
        " MES requires detailed planning and cross-department coordination, with customization adding complexity.",
    },
    {
      title: "Data Integration:",
      description:
        "Seamless integration with systems like ERP and SCADA is challenging but necessary for real-time data consistency.",
    },
    {
      title: "Change Management:",
      description:
        "Employees need to adapt to new processes and technologies, which may require extensive training and change management strategies.",
    },
    {
      title: "Data Security:",
      description:
        "MES needs strong security measures to protect sensitive production data from cyber threats.",
    },
    
  ];
  const listItems2 = [
    {
      title: "Integration with IoT:",
      description:
        "MES systems increasingly integrate with Internet of Things (IoT) devices for realtime data collection and enhanced visibility across the production floor.",
    },
    {
      title: "Cloud-Based Solutions:",
      description:
        "Cloud-based MES solutions offer flexibility, scalability, and remote access, reducing the need for on-premises infrastructure.",
    },
    {
      title: "Advanced Analytics and AI:",
      description:
        "Incorporating advanced analytics and artificial intelligence (AI) for predictive maintenance, process optimization, and decisionmaking.",
    },
    {
      title: "Enhanced Mobile Capabilities:",
      description:
        "Mobile access and applications provide operators and managers with real-time insights and control from any location.",
    },
    
  ];
  return (
    <div>

<HeaderSectionReusableComponent
        mainHeading={
          "What is an MES?"
        }
        paragragh={
          <>
            An MES is a software solution used in manufacturing to monitor and
              control production processes on the shop floor. In manufacturing
              operations management, it acts as a bridge between an enterprise's
              planning and control systems, such as an Enterprise Resource
              Planning (ERP) system, and the actual manufacturing operations.
          </>
        }
        imgScr={MESs}
      />
 


      <Image_ContentReusableComponent
      bgColor="#fff"
        heading={"How MES Works?"}
        paragraph={
          <>
            A Manufacturing Execution System (MES) works by tracking and
              controlling the production process on the factory floor in real
              time. It collects data from machines and operators, monitors
              workflows, and manages resources to ensure efficient production.
              MES provides insights into performance, quality, and compliance,
              helping to optimize manufacturing operations and improve
              productivity.
          </>
        }
        imgsrc={producity}
      />
     
      <section className="py-10 bg-[#efefef] ">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10  xl:mx-40 mx-3 md:mx-6">
          <div className="col-span-1 my-auto order-2 lg:order-1 animate-up">
            <h2>Challenges for implementing MES systems</h2>
          
              <ul>
                {listItems.map((item, index) => (
                  <li
                    key={index}
                    className="list-none text-base leading-[1.25] mb-3.5 custom-justify"
                  >
                    <strong className="font-semibold text-md">
                      {item.title}
                    </strong>
                    &nbsp;
                    <span>{item.description}</span>
                  </li>
                ))}
              </ul>
          
          </div>
          <img
            src={MES_enhance}
            alt="MES_enhance"
            className="w-full h-full order-1 lg:order-2 animate-up"
          />
        </div>
      </section>
      <section className="py-10 bg-[#fff] ">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10  xl:mx-40 mx-3 md:mx-6">
        <img
            src={Mes_aws}
            alt="Mes_aws"
            className="w-full h-full  animate-up"
          />
          <div className="col-span-1 my-auto  animate-up">
            <h2>Trends in MES systems</h2>
          
              <ul>
                {listItems2.map((item, index) => (
                  <li
                    key={index}
                    className="list-none text-base leading-[1.25] mb-3.5 custom-justify"
                  >
                    <strong className="font-semibold text-md">
                      {item.title}
                    </strong>
                    &nbsp;
                    <span>{item.description}</span>
                  </li>
                ))}
              </ul>
          
          </div>
        
        </div>
      </section>
    
    </div>
  );
};

export default MES;
