
import React from 'react';
import rollout from '../../../Assets/SAP-page/Rollout.png'
import upgrade from '../../../Assets/SAP-page/upgrade.png'
import support from '../../../Assets/SAP-page/customer-service.png'
import technical from '../../../Assets/SAP-page/technical.png'
import functional from '../../../Assets/SAP-page/FunctionalService.png'
import BPC from '../../../Assets/SAP-page/BPCbtw.png'
import HCM from '../../../Assets/SAP-page/HCM.png'
import AMS from '../../../Assets/SAP-page/AMS.png'
import  HANA from '../../../Assets/SAP-page/HANA.png'

const SAPCards = () => {
  const features = [
    {
      icon: rollout,
      title: "Rollout",
      description: "We offer businesses a unified platform for all of their business operations through the implementation of SAP roll-out. We guarantee that business processes are standardised and data redundancy is reduced."
    },
    {
      icon: upgrade,
      title: "Upgrade",
      description: "With our SAP upgradation and migration services, we comprehend the criticality of situations and business issues in order to offer suitable solutions.."
    },
    {
      icon: support,
      title: "Support",
      description: "We provide comprehensive maintenance solutions and functional support services to facilitate digital innovation and excellence throughout the entirety of your SAP digital journey."
    },
    {
      icon:technical,
      title: "Technical Services",
      description: "We develop new capabilities, extend the features of your system, or customise your solution with enhancements and integrate your SAP system with third-party solutions. Become future-ready with us."
    },
    {
      icon: functional,
      title: "Functional Services",
      description: "We setup roles, new processes, and configure SAP modules and manage routine tasks like management dashboards and financial reporting, along with training new users on your business processes."
    },
    {
      icon:BPC,
      title: "SAP BPC/BW",
      description: "In order to strengthen organisations, a comprehensive BPC/BW solution will be of great assistance. We reduce the friction in operations by providing instruments for data warehousing, reporting & management."
      },
     {
      icon:HCM,
      title: "SAP HCM",
      description: "HCM will facilitate the streamlining of payroll and other human resource-related challenges for an organisation. Our SAP HCM expertise enables us to effectively optimise your organisation."
    },
    {
      icon: AMS,
      title: "SAP AMS",
      description: "We simplify the process of managing applications, accelerate the process of system stabilisation, efficiently manage complexities and minimise expenditures."
    },
    {
      icon:HANA,
      title: "SAP S/4 HANA",
      description: "Our expertly managed SAP S4 HANA services transform your ERP system for exceptional performance, all while ensuring your business operations continue smoothly."
    }
  ];

  return (
    <>
      {/* Header Section */}
     

      {/* Cards Section */}
      <div className="max-w-6xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center hover:shadow-2xl transition-all duration-300 transform hover:scale-105"
            >
              <div className="mb-6">
                <img src={feature.icon} alt={feature.title} className='h-20 mx-auto' />
              </div>
              <h3
                className={`text-2xl font-bold mb-4 ${
                  index % 2 === 0 ? "text-blue-600" : "text-green-600"
                }`}
              >
                {feature.title}
              </h3>
              <p className="text-gray-700 text-lg text-justify leading-relaxed">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SAPCards;
