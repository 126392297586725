import React, { useContext } from "react";

import { Outlet } from "react-router-dom";
import PanelLayout from "./PanelLayout";


const AppLayout = () => {

  return (
    <PanelLayout >
      <Outlet />
    </PanelLayout>
  );
};

export default AppLayout;
